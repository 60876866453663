<article class="pop-marketing-modal">
  <poplin-button
    id="referral-redemption-close-button"
    class="close-button"
    [showIcon]="true"
    iconSlot="icon-only"
    size="small"
    shape="round"
    color="inverse-monochrome"
    icon="close"
    (eventEmitter)="handleDismiss()"
  ></poplin-button>
  <header>
    <ng-container *ngIf="headerOne; else defaultHeaderImg">
      <ion-grid>
        <ion-row>
          <ion-col> </ion-col>
          <ion-col size="auto">
            <h2>{{ headerOne }}</h2>
          </ion-col>
          <ion-col> </ion-col>
        </ion-row>
        <ion-row *ngIf="headerTwo">
          <ion-col> </ion-col>
          <ion-col size="auto">
            <h2>{{ headerTwo }}</h2>
          </ion-col>
          <ion-col> </ion-col>
        </ion-row>
      </ion-grid>
    </ng-container>
  </header>
  <div class="modalContent" [innerHTML]="content"></div>
  <footer>
    <poplin-button
      id="referral-redemption-nevagation-button"
      [label]="buttonActionName"
      (eventEmitter)="handleActionClick()"
    ></poplin-button>
  </footer>
</article>

<ng-template #defaultHeaderImg>
  <img
    class="ion-float-left"
    alt="life not laundry"
    src="../../../assets/images/life-not-laundry.webp"
  />
</ng-template>
