import { CommonModule } from '@angular/common';
import { Component, inject, Input, OnInit } from '@angular/core';
import { ConfirmActionModalComponent } from '@components';
import {
  IonCol,
  IonContent,
  IonGrid,
  IonRow,
  ModalController,
} from '@ionic/angular/standalone';
import {
  ButtonComponent,
  HeaderComponent,
} from '@sudshare/custom-design-package';
import { breakpoints, calcBreakpoint, closeModalWithOptions } from '@utils';

@Component({
  selector: 'app-block-unblock-action-modal',
  standalone: true,
  imports: [
    CommonModule,
    IonContent,
    IonGrid,
    IonRow,
    IonCol,
    HeaderComponent,
    ButtonComponent,
  ],
  templateUrl: './block-unblock-action-modal.component.html',
  styleUrls: ['./block-unblock-action-modal.component.scss'],
})
export class BlockUnblockActionModalComponent implements OnInit {
  private _modalCtrl = inject(ModalController);

  @Input() laundryProName!: string;
  @Input() id!: string;
  @Input() isBlock!: boolean;

  public buttonLabel = '';

  ngOnInit(): void {
    this.buttonLabel = this.isBlock
      ? `Block ${this.laundryProName}`
      : `UnBlock ${this.laundryProName}`;
  }

  async onConfirm(): Promise<void> {
    let data: { confirm: boolean } | undefined = { confirm: true };
    let role: string | undefined = 'confirm';

    if (this.isBlock) {
      const breakPoint = calcBreakpoint(320);

      const confirmModal = await this._modalCtrl.create({
        component: ConfirmActionModalComponent,
        mode: 'ios',
        componentProps: {
          title: 'Confirm Block',
          content: `Are you sure you want to block ${
            this.laundryProName.split(' ')[0]
          }? You can unblock them at any time from this list.`,
          confirmButtonLabel: 'Confirm and Block',
          cancelButtonLabel: 'Cancel',
        },
        breakpoints: breakpoints,
        initialBreakpoint: breakPoint,
        handle: false,
      });
      confirmModal.present();

      ({ data, role } = await confirmModal.onDidDismiss());
    }
    closeModalWithOptions(this._modalCtrl, data, role);
  }

  onClose(): void {
    closeModalWithOptions(this._modalCtrl, { confirm: false }, 'close');
  }
}
