import { createSelector } from '@ngrx/store';
import { CustomerAccountDetails } from '@utils';
import { StoreState } from '../store.state';
import { UserState } from './user.reducer';

const userState = (state: StoreState) => state.user;

export const getDocumentId = createSelector(
  userState,
  (state: UserState) => state.docId
);

export const getDocumentData = createSelector(userState, (state: UserState) =>
  structuredClone(state.docData)
);

export const getPreferredLPs = createSelector(getDocumentData, (docData) =>
  docData?.PreferredSudsters && docData?.PreferredSudsters.length
    ? structuredClone(docData.PreferredSudsters)
    : []
);

export const getActiveDiscount = createSelector(
  userState,
  (state: UserState) => state.discount
);

export const getAccountDetails = createSelector(
  getDocumentId,
  getDocumentData,
  (docId, docData) => {
    return {
      userId: docId,
      firstName: docData?.FirstName,
      lastName: docData?.LastName,
      phoneNumber: docData?.Phone,
      email: docData?.ContactEmail,
      themePreference: docData?.ThemePreference,
      personalizedTracking: docData?.PersonalizedTracking,
      isCommercial: !!docData?.CommercialAccountCustomerID || false,
      orderCount: docData?.OrderCount,
      senior: docData?.Senior,
      deviceTracking: docData?.DeviceTracking,
      firstOrder: docData?.OrderDeliveredCount
        ? docData.OrderDeliveredCount === 0
        : true,
      isBlocked: docData?.Blocked,
    } as CustomerAccountDetails;
  }
);
