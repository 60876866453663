import { Injectable, NgZone, inject } from '@angular/core';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { App, URLOpenListenerEvent } from '@capacitor/app';
import { environment } from '@environments/environment';
import { Actions, ofType } from '@ngrx/effects';
import {
  NavigationService,
  ReferralService,
  StorageService,
  StoreService,
} from '@services';
import { UserActionTypes } from '@store';
import { LOCAL_STORAGE_ITEMS, QueryParams } from '@utils';
import { AFConstants, AppsFlyer } from 'appsflyer-capacitor-plugin';
import {
  Subject,
  debounceTime,
  filter,
  map,
  merge,
  switchMap,
  take,
  tap,
  timer,
} from 'rxjs';

const NAVIGATE_AFTER_DELAY = 2000;
const TRIGGER_IMMEADIATE_NAVIGATION_TIMER = 10;

@Injectable({
  providedIn: 'root',
})
export class DeepLinkService {
  private _zone = inject(NgZone);
  private _referralService = inject(ReferralService);
  private _storageService = inject<StorageService>(StorageService);
  private _storeService = inject(StoreService);
  private _router = inject(Router);
  private _navService = inject(NavigationService);
  private _actions = inject(Actions);

  private navigationSubject = new Subject<{
    route: string;
    queryParams: QueryParams;
    replaceUrl: boolean;
  }>();
  private navigationAllowGate = false;

  constructor() {
    // this pipe is used to prevent concurrent navigation requests, the creates losing the navigation request
    // specifically created for handling deeplink url navigation when app is fully closed
    this.navigationSubject
      .pipe(
        tap(() => {
          this.navigationAllowGate = true;
        }),
        switchMap((navRequest) =>
          merge(
            this._router.events.pipe(
              filter(
                (event) =>
                  this.navigationAllowGate &&
                  (event instanceof NavigationEnd ||
                    event instanceof NavigationStart)
              )
            ),
            timer(TRIGGER_IMMEADIATE_NAVIGATION_TIMER)
          ).pipe(
            debounceTime(NAVIGATE_AFTER_DELAY),
            tap(() => {
              this.navigationAllowGate = false;
            }),
            map(() => navRequest)
          )
        )
      )
      .subscribe((navRequest) => {
        this._navService.forwardWithOptions(
          navRequest.route,
          {
            animationDirection: 'forward',
          },
          navRequest.queryParams,
          {
            onSameUrlNavigation: 'reload',
            replaceUrl: navRequest.replaceUrl,
          }
        );
      });
  }

  init(): void {
    App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
      this._zone.run(() => {
        this.handleUrl(event.url, false);
      });
    });

    AppsFlyer.addListener(AFConstants.OAOA_CALLBACK, (event) => {
      if (event.callbackName === AFConstants.onAppOpenAttribution) {
        const reconstructedUrl = `https://${event.data.host}/${
          event.data.deep_link_value.startsWith('/')
            ? event.data.deep_link_value.substring(1)
            : event.data.deep_link_value
        }`;
        this.sessionCheckNavigation(reconstructedUrl);
      }
    });

    AppsFlyer.addListener(AFConstants.CONVERSION_CALLBACK, (event) => {
      if (event.callbackName === AFConstants.onConversionDataSuccess) {
        if (event.data.af_status === 'Non-organic') {
          if (event.data.is_first_launch === true) {
            const reconstructedUrl = `https://${event.data.host}/${
              event.data.deep_link_value.startsWith('/')
                ? event.data.deep_link_value.substring(1)
                : event.data.deep_link_value
            }`;
            this.sessionCheckNavigation(reconstructedUrl);
          }
        }
      }
    });

    AppsFlyer.addListener(AFConstants.UDL_CALLBACK, (res) => {
      if (res.status !== 'FOUND') {
        return;
      }

      const deepLinkData = res.deepLink;
      if (deepLinkData?.deep_link_value) {
        let reconstructedUrl = '';
        const deepLinkValue = deepLinkData.deep_link_value;
        reconstructedUrl = `https://${res.deepLink.host}/${
          deepLinkValue.startsWith('/')
            ? deepLinkValue.substring(1)
            : deepLinkValue
        }`;
        this.sessionCheckNavigation(reconstructedUrl);
      } else if (
        deepLinkData?.link?.startsWith(
          `${environment.externalLinks.customUriSchema}:`
        )
      ) {
        this.sessionCheckNavigation(deepLinkData.link);
      }
    });
  }

  private sessionCheckNavigation(deepLinkUrl: string): void {
    this._storeService
      .getAccountDetails()
      .pipe(take(1))
      .subscribe(async (user) => {
        if (user.userId) {
          this.handleUrl(deepLinkUrl, false);
          return;
        } else {
          // wait until the user completes login to navigate to the deep link
          this._actions
            .pipe(ofType(UserActionTypes.SubscribeSuccess), take(1))
            .subscribe((userState) => {
              if (userState.payload?.docId) {
                this.handleUrl(deepLinkUrl, true);
              }
            });
        }
      });
  }

  public async handleUrl(
    url: string,
    queueNavigationRequest: boolean
  ): Promise<void> {
    const openUrl = new URL(url);
    const params = openUrl.searchParams;

    const queryParams: QueryParams = {};

    params.forEach((value, key) => {
      queryParams[key] = value;
    });

    const routePaths = openUrl.pathname.split('/').filter((path) => path);
    // to support custom uri scheme poplin://page links
    if (openUrl.protocol === `${environment.externalLinks.customUriSchema}:`) {
      routePaths.push(openUrl.host);
    }

    if (routePaths?.length > 0) {
      // due to lazy loading modules, we can only check for the first level route
      const hasLevel1Route =
        this._router.config.findIndex((route) => route.path === routePaths[0]) >
        -1;
      if (hasLevel1Route) {
        let path = openUrl.pathname || routePaths[0];
        path = path.replace(/\/\//, '/');

        const navigationRequest = {
          route: path,
          queryParams: queryParams,
          replaceUrl: this._router.url === path,
        };

        if (queueNavigationRequest) {
          this.navigationSubject.next(navigationRequest);
          return;
        }

        this._navService.forwardWithOptions(
          navigationRequest.route,
          {
            animationDirection: 'forward',
          },
          navigationRequest.queryParams,
          {
            onSameUrlNavigation: 'reload',
            replaceUrl: navigationRequest.replaceUrl,
          }
        );
        return;
      }
    }

    if (Object.keys(queryParams).length === 0) {
      return;
    }

    await this._storageService.setItem(LOCAL_STORAGE_ITEMS.webLink, url);
    await this._storageService.setItem(
      LOCAL_STORAGE_ITEMS.marketing,
      JSON.stringify(queryParams)
    );

    if (queryParams['referralId']) {
      this._storeService
        .getAccountDetails()
        .pipe(take(1))
        .subscribe(async (user) => {
          if (!user?.userId) {
            // prepare for redemption during login flow.
            await this._storageService.setItem(
              LOCAL_STORAGE_ITEMS.referralId,
              queryParams['referralId']
            );
            return;
          }
          this._referralService.redeemReferral(queryParams['referralId']);
        });
    }
  }
}
