import { createSelector } from '@ngrx/store';
import { StoreState } from '../store.state';
import { AuthState } from './auth.reducer';

const authState = (state: StoreState) => state.auth;

export const getAuthUser = createSelector(
  authState,
  (state: AuthState) => state.user
);

export const getAuthUserId = createSelector(
  getAuthUser,
  (user) => user?.uid || ''
);

export const getAuthIdToken = createSelector(getAuthUser, (user) =>
  user?.getIdToken()
);

export const getAuthEmail = createSelector(getAuthUser, (user) => user?.email);

export const getAuthName = createSelector(
  getAuthUser,
  (user) => user?.displayName
);
