import { Action, createReducer, on } from '@ngrx/store';
import { CustomerAPI } from '@sudshare/custom-node-package';
import { StoreStatusOptions, STORE_STATUS } from '@utils';
import { GetPastOrders } from './past-orders.actions';

export interface PastOrdersState {
  pastOrders: CustomerAPI.Response.SimpleOrderHistory[] | null;
  error: string | null;
  status: STORE_STATUS;
}

const initialState: PastOrdersState = {
  pastOrders: null,
  error: null,
  status: StoreStatusOptions.PENDING,
};

const pastOrdersReducer = createReducer(
  initialState,

  on(GetPastOrders, (state, { payload }) => ({
    ...state,
    status: StoreStatusOptions.SUCCESS,
    pastOrders: payload.pastOrders,
  }))
);

export function PastOrdersReducer(
  state: PastOrdersState | undefined,
  action: Action
): PastOrdersState {
  return pastOrdersReducer(state, action);
}
