import { PreloadingStrategy, Route } from '@angular/router';
import { Observable, catchError, of } from 'rxjs';

/**
 * Custom pre-loading strategy, only for specified components
 * ```
 * data: {
 *  preload: true,
 * },
 * ```
 */
export class SelectiveLoadingStrategy implements PreloadingStrategy {
  preload(route: Route, load: () => Observable<any>): Observable<any> {
    if (route.data && route.data['preload']) {
      return load().pipe(
        catchError((error) => {
          throw error;
        })
      );
    }
    return of(null);
  }
}
