import { createAction, props } from '@ngrx/store';
import { AuthState } from './auth.reducer';

export enum AuthActionTypes {
  CheckEmail = '[Auth] Check Email',
  LoginEmail = '[Auth] Login Email',
  VerifyAccountWeb = '[Auth] Verify Account Web',
  VerifyAccountMobile = '[Auth] Verify Account Mobile',
  ConfirmPhone = '[Auth] Confirm Phone',
  LinkPhone = '[Auth] Link Phone',
  LoginAdmin = '[Auth] Login Admin',
  ConfirmLinkPhone = '[Auth] Confirm Link Phone',
  LoginGoogleWeb = '[Auth] Login Google Web',
  LoginGoogleMobile = '[Auth] Login Google Mobile',
  LoginSuccess = '[Auth] Login Success',
  LoginPending = '[Auth] Login Pending',
  LoginFailure = '[Auth] Login Failure',
  LoggedIn = '[Auth] Logged In',
  Logout = '[Auth] Logout',
  UpdateAuthUser = '[Auth] Update User',
}

export const CheckEmail = createAction(
  AuthActionTypes.CheckEmail,
  props<{ email: string }>()
);

export const VerifyAccountWeb = createAction(
  AuthActionTypes.VerifyAccountWeb,
  props<{ phoneNumber: string; isResend: boolean }>()
);
export const VerifyAccountMobile = createAction(
  AuthActionTypes.VerifyAccountMobile,
  props<{ verifyId: string; phoneNumber: string }>()
);

export const LinkPhone = createAction(
  AuthActionTypes.LinkPhone,
  props<{ phoneNumber: string }>()
);
export const ConfirmLinkPhone = createAction(
  AuthActionTypes.ConfirmLinkPhone,
  props<{ confirmationCode: number }>()
);
export const ConfirmPhone = createAction(
  AuthActionTypes.ConfirmPhone,
  props<{ verifyId: string; confirmationCode: string }>()
);

export const LoginEmail = createAction(
  AuthActionTypes.LoginEmail,
  props<{ email: string; password: string }>()
);
export const LoginGoogleWeb = createAction(AuthActionTypes.LoginGoogleWeb);
export const LoginGoogleMobile = createAction(
  AuthActionTypes.LoginGoogleMobile
);
export const LoginAdmin = createAction(
  AuthActionTypes.LoginAdmin,
  props<{ token: string }>()
);

export const LoginSuccess = createAction(
  AuthActionTypes.LoginSuccess,
  props<{ payload: AuthState }>()
);
export const LoginPending = createAction(
  AuthActionTypes.LoginPending,
  props<{ payload: AuthState }>()
);
export const LoginFailure = createAction(
  AuthActionTypes.LoginFailure,
  props<{ payload: AuthState }>()
);

export const LoggedIn = createAction(
  AuthActionTypes.LoggedIn,
  props<{ payload: AuthState }>()
);

export const UpdateAuthUser = createAction(
  AuthActionTypes.UpdateAuthUser,
  props<{ payload: AuthState }>()
);

export const Logout = createAction(AuthActionTypes.Logout);
