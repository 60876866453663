import {
  AbstractControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
} from '@angular/forms';
import { of, timer } from 'rxjs';
import { debounce, distinctUntilChanged } from 'rxjs/operators';

// Password Breakdown
// ^(?=.*[0-9]): Asserts that at least one digit is present. (Number)
// (?=.*[a-z]): Asserts that at least one lowercase letter is present.
// (?=.*[A-Z]): Asserts that at least one uppercase letter is present.
// (?=.*[*.!@$%^&(){}\[\]:;<>,.?/~_+\-=|]): Asserts that at least one special character is present.
// .{8,32}$: Ensures the total length of the string is between 8 and 32 characters.

// Regex
export const CustomRegex = {
  EMAIL: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
  PHONE: /^\(\d{3}\) \d{3}-\d{4}$/,
  LETTERS_ONLY: /^[a-zA-Z ]*$/,
  PASSWORD:
    /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[*.!@$%^&(){}[\]:;<>,.?/~_+\-=|]).{8,32}$/,
  FULL_ADDRESS: /^s*S+(?:s+S+){2}/,
  COUPON_CODES: /\S+/,
  NUMBERS_ONLY: /^\d+$/,
} as const;

// Validation Helpers
function requiredValidator(control: AbstractControl): ValidationErrors | null {
  return control.value ? null : { message: 'This field is required' };
}

function minLengthValidator(minLength: number): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    return control.value && control.value.length >= minLength
      ? null
      : {
          message: `Minimum length of ${minLength} characters required`,
        };
  };
}

function maxLengthValidator(maxLength: number): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    return control.value && control.value.length <= maxLength
      ? null
      : {
          message: `Maximum length of ${maxLength} characters exceeded`,
        };
  };
}

function emailValidator(control: AbstractControl): ValidationErrors | null {
  const regex = CustomRegex.EMAIL;
  return regex.test(control.value) ? null : { message: 'Invalid email format' };
}

function lettersValidator(control: AbstractControl): ValidationErrors | null {
  const regex = CustomRegex.LETTERS_ONLY;
  return regex.test(control.value) ? null : { message: 'Invalid value' };
}

function passwordValidator(control: AbstractControl): ValidationErrors | null {
  const regex = CustomRegex.PASSWORD;
  return regex.test(control.value)
    ? null
    : { message: 'Must be a stronger password' };
}

function couponValidator(control: AbstractControl): ValidationErrors | null {
  const regex = CustomRegex.COUPON_CODES;
  return regex.test(control.value) ? null : { message: 'Invalid coupon code' };
}

function numberValidator(control: AbstractControl): ValidationErrors | null {
  const regex = CustomRegex.NUMBERS_ONLY;
  return regex.test(control.value) ? null : { message: 'Invalid phone code' };
}

function phoneNumberValidator(
  control: AbstractControl
): ValidationErrors | null {
  const regex = CustomRegex.PHONE;
  return regex.test(control.value)
    ? null
    : { message: 'Invalid phone number format' };
}

// Validation Function
export const customFormValidation = (
  form: FormGroup,
  validations: { [key: string]: string },
  delay = 0
): void => {
  Object.keys(form.controls).forEach((formNameKey) => {
    const control = form.get(formNameKey);

    if (control) {
      control.valueChanges
        .pipe(
          debounce(() => (delay > 0 ? timer(delay) : of({}))),
          distinctUntilChanged()
        )
        .subscribe({
          next: () => {
            const errors = control.errors;

            if (errors) {
              for (const [key, value] of Object.entries(errors)) {
                switch (key) {
                  case 'required':
                    validations[formNameKey] =
                      requiredValidator(control)?.['message'];
                    return;
                  case 'minlength':
                    validations[formNameKey] = minLengthValidator(
                      value.requiredLength
                    )(control)?.['message'];
                    return;
                  case 'maxlength':
                    validations[formNameKey] = maxLengthValidator(
                      value.requiredLength
                    )(control)?.['message'];
                    return;
                  case 'pattern':
                    switch (formNameKey) {
                      case 'EmailInput':
                        validations[formNameKey] =
                          emailValidator(control)?.['message'];
                        return;
                      case 'PhoneNumberInput':
                        validations[formNameKey] =
                          phoneNumberValidator(control)?.['message'];
                        return;
                      case 'PasswordInput':
                        validations[formNameKey] =
                          passwordValidator(control)?.['message'];
                        return;
                      case 'CouponInput':
                        validations[formNameKey] =
                          couponValidator(control)?.['message'];
                        return;
                      case 'VerificationCode':
                        validations[formNameKey] =
                          numberValidator(control)?.['message'];
                        return;
                      default:
                        validations[formNameKey] =
                          lettersValidator(control)?.['message'];
                        return;
                    }
                }
              }
            } else {
              validations[formNameKey] = '';
            }

            return validations;
          },
        });
    }
  });
};
