import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import {
  getAnalytics,
  initializeAnalytics,
  provideAnalytics,
} from '@angular/fire/analytics';
import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
import {
  debugErrorMap,
  getAuth,
  indexedDBLocalPersistence,
  initializeAuth,
  prodErrorMap,
  provideAuth,
} from '@angular/fire/auth';
import {
  getFirestore,
  initializeFirestore,
  persistentLocalCache,
  provideFirestore,
} from '@angular/fire/firestore';
import {
  getPerformance,
  initializePerformance,
  providePerformance,
} from '@angular/fire/performance';
import { Capacitor } from '@capacitor/core';
import { environment } from '@environments/environment';
import { getApp } from 'firebase/app';

@NgModule({
  imports: [
    CommonModule,
    provideFirebaseApp(() => initializeApp(environment.firebase)),
    provideAuth(() => {
      if (Capacitor.isNativePlatform()) {
        return initializeAuth(getApp(), {
          persistence: indexedDBLocalPersistence,
          errorMap: environment.production ? prodErrorMap : debugErrorMap,
        });
      } else {
        return getAuth();
      }
    }),
    provideFirestore(() => {
      if (Capacitor.isNativePlatform()) {
        return initializeFirestore(getApp(), {
          localCache: persistentLocalCache(),
        });
      } else {
        return getFirestore();
      }
    }),
    provideAnalytics(() => {
      if (Capacitor.isNativePlatform()) {
        return initializeAnalytics(getApp());
      } else {
        return getAnalytics();
      }
    }),
    providePerformance(() => {
      if (Capacitor.isNativePlatform()) {
        return initializePerformance(getApp(), {
          dataCollectionEnabled: false,
          instrumentationEnabled: true,
        });
      } else {
        return getPerformance();
      }
    }),
  ],
})
export class FirebaseModule {}
