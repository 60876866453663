import { InjectionToken } from '@angular/core';
import { environment } from '@environments/environment';
import Rollbar from 'rollbar';

const rollbarConfig: Rollbar.Configuration = {
  accessToken: environment.rollbar.accessToken,
  captureUncaught: true,
  captureUnhandledRejections: true,
  captureEmail: true,
  captureIp: true,
  captureUsername: true,
  codeVersion: '4.3.4',
  ignoredMessages: ['Script error.'],
  payload: {
    environment: environment.production ? 'production' : 'development',
    client: {
      javascript: {
        code_version: environment.version,
        source_map_enabled: true,
        guess_uncaught_frames: true,
      },
    },
    server: {
      root: 'webpack:///./',
    },
  },
};

export const RollbarService = new InjectionToken<Rollbar>('rollbar');

export function rollbarFactory(): Rollbar {
  return new Rollbar(rollbarConfig);
}
