import { Action, createReducer, on } from '@ngrx/store';
import { CustomerAPI, PartialNested } from '@sudshare/custom-node-package';
import { STORE_STATUS, StoreStatusOptions } from '@utils';
import {
  AddressAdded,
  BagSizeAdded,
  CommercialAccountSet,
  CouponAdded,
  CoverageAdded,
  CreditAdded,
  DeliveryAdded,
  FeesAdded,
  NewOrderPaid,
  OversizedItemsAdded,
  PickupAdded,
  PlaceOrder,
  PreferencesAdded,
  PreferredLPAdded,
  PriceInformationAdded,
  ProfileDataAdded,
  ProtectionAccepted,
  RepeatOrder,
  ResetOrder,
  SurgePriceSet,
} from './new-orders.actions';

export interface NewOrderState {
  orderData: PartialNested<CustomerAPI.Request.NewOrder>;
  surgePrice: number;
  isCommercial: boolean;
  error: string | null;
  status: STORE_STATUS;
  protectionAccepted: boolean;
}

const initialState: NewOrderState = {
  orderData: {
    order: {
      orderId: '',
      orderProfileId: '',
      orderProfileName: 'Home',
    },
    pickup: {
      pickupAddress: {
        full: '',
        line1: '',
        line2: '',
        city: '',
        state: '',
        zipCode: '',
        country: '',
      },
      pickupLocation: '',
      pickupInstructions: '',
      pickupTimezone: '',
      pickupGeo: {
        lat: 0,
        long: 0,
      },
    },
    delivery: {
      deliveryType: '',
    },
    laundry: {
      laundryDetergent: '',
      laundryOptions: {
        delicate: false,
        hangDry: false,
        useHanger: false,
        additionalInstructions: '',
      },
    },
    bags: {
      small: 0,
      regular: 0,
      large: 0,
    },
    additional: {
      oversizedItems: 0,
      couponCode: '',
      couponValue: 0,
      credits: 0,
      surgeAmount: 0,
      trustAndSafetyFee: 0,
      repeatOrder: false,
      priceSchedule: {
        expressRate: 0,
        expressMinimum: 0,
        standardRate: 0,
        standardMinimum: 0,
      },
    },
    preferred: {
      preferredLp: [],
      preferredLpNames: [],
      preferredPickup: 'asap',
    },
    coverage: {
      basic: false,
      premium: false,
      premiumPlus: false,
    },
  },
  surgePrice: 0,
  isCommercial: false,
  error: null,
  status: StoreStatusOptions.PENDING,
  protectionAccepted: false,
};

const newOrderReducer = createReducer(
  initialState,

  on(ProfileDataAdded, (state, { payload }) => ({
    ...state,
    status: StoreStatusOptions.PROFILE_NAME,
    orderData: {
      ...state.orderData,
      order: {
        ...state.orderData.order,
        orderProfileId: payload.order?.orderProfileId,
        orderProfileName: payload.order?.orderProfileName,
      },
    },
  })),

  on(AddressAdded, (state, { payload }) => ({
    ...state,
    status: StoreStatusOptions.ADDRESS,
    orderData: {
      ...state.orderData,
      pickup: {
        ...state.orderData.pickup,
        ...payload.pickup,
      },
    },
  })),

  on(CoverageAdded, (state, { payload }) => ({
    ...state,
    status: StoreStatusOptions.COVERAGE,
    orderData: {
      ...state.orderData,
      coverage: { ...state.orderData.coverage, ...payload.coverage },
    },
  })),

  on(DeliveryAdded, (state, { payload }) => ({
    ...state,
    status: StoreStatusOptions.DELIVERY,
    orderData: {
      ...state.orderData,
      delivery: { ...state.orderData.delivery, ...payload.delivery },
    },
  })),

  on(PreferencesAdded, (state, { payload }) => ({
    ...state,
    status: StoreStatusOptions.PREFERENCES,
    orderData: {
      ...state.orderData,
      laundry: { ...state.orderData.laundry, ...payload.laundry },
    },
  })),

  on(BagSizeAdded, (state, { payload }) => ({
    ...state,
    status: StoreStatusOptions.LOAD_SIZE,
    orderData: {
      ...state.orderData,
      bags: { ...state.orderData.bags, ...payload.bags },
    },
  })),

  on(PickupAdded, (state, { payload }) => ({
    ...state,
    status: StoreStatusOptions.PICKUP,
    orderData: {
      ...state.orderData,
      pickup: {
        ...state.orderData.pickup,
        ...payload.pickup,
      },
    },
  })),

  on(OversizedItemsAdded, (state, { payload }) => ({
    ...state,
    status: StoreStatusOptions.OVERSIZED_ITEMS,
    orderData: {
      ...state.orderData,
      additional: {
        ...state.orderData.additional,
        oversizedItems: payload.additional?.oversizedItems,
      },
    },
  })),

  on(CreditAdded, (state, { payload }) => ({
    ...state,
    status: StoreStatusOptions.CREDIT,
    orderData: {
      ...state.orderData,
      additional: {
        ...state.orderData.additional,
        credits: payload.additional?.credits,
      },
    },
  })),

  on(CouponAdded, (state, { payload }) => ({
    ...state,
    status: StoreStatusOptions.COUPON,
    orderData: {
      ...state.orderData,
      additional: {
        ...state.orderData.additional,
        couponCode: payload.additional?.couponCode,
        couponValue: payload.additional?.couponValue,
        credits:
          (state.orderData.additional?.credits || 0) +
          (payload.additional?.couponValue || 0),
      },
    },
  })),

  on(FeesAdded, (state, { payload }) => ({
    ...state,
    status: StoreStatusOptions.FEES,
    orderData: {
      ...state.orderData,
      additional: {
        ...state.orderData.additional,
        trustAndSafetyFee: payload.additional?.trustAndSafetyFee,
      },
    },
  })),

  on(PriceInformationAdded, (state, { payload }) => ({
    ...state,
    status: StoreStatusOptions.PRICE_INFORMATION,
    orderData: {
      ...state.orderData,
      additional: {
        ...state.orderData.additional,
        priceSchedule: {
          expressRate: payload.additional?.priceSchedule?.expressRate,
          expressMinimum: payload.additional?.priceSchedule?.expressMinimum,
          standardRate: payload.additional?.priceSchedule?.standardRate,
          standardMinimum: payload.additional?.priceSchedule?.standardMinimum,
        },
      },
    },
  })),

  on(RepeatOrder, (state, { payload }) => ({
    ...state,
    status: StoreStatusOptions.REPEAT_ORDER,
    orderData: {
      ...state.orderData,
      additional: {
        ...state.orderData.additional,
        repeatOrder: payload.additional?.repeatOrder,
      },
    },
  })),

  on(SurgePriceSet, (state, { payload }) => ({
    ...state,
    surgePrice: payload.surgeAmount,
    orderData: {
      ...state.orderData,
      additional: {
        ...state.orderData.additional,
        surgeAmount: payload.surgeAmount,
      },
    },
  })),

  on(CommercialAccountSet, (state, { payload }) => ({
    ...state,
    isCommercial: payload,
  })),

  on(PreferredLPAdded, (state, { payload }) => ({
    ...state,
    status: StoreStatusOptions.PREFERRED_LP,
    orderData: {
      ...state.orderData,
      preferred: payload,
    },
  })),

  on(ProtectionAccepted, (state, { payload }) => ({
    ...state,
    protectionAccepted: payload,
  })),

  on(PlaceOrder, (state) => ({
    ...state,
    status: StoreStatusOptions.READY,
  })),

  on(NewOrderPaid, (state) => ({
    ...state,
    status: StoreStatusOptions.NEW_ORDER_PAID,
  })),

  on(ResetOrder, () => ({
    ...initialState,
  }))
);

export function NewOrderReducer(
  state: NewOrderState | undefined,
  action: Action
): NewOrderState {
  return newOrderReducer(state, action);
}
