import { CommonModule } from '@angular/common';
import { Component, inject, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
  IonCol,
  IonContent,
  IonGrid,
  IonRadioGroup,
  IonRow,
  ModalController,
} from '@ionic/angular/standalone';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ApiService, DialogService, NavigationService } from '@services';
import {
  ButtonComponent,
  HeaderComponent,
  RadioButtonModule,
  TextareaModule,
} from '@sudshare/custom-design-package';
import {
  CancelReason,
  CancelReasonContent,
  NEW_ORDER,
  NotificationContent,
} from '@utils';

@UntilDestroy()
@Component({
  selector: 'app-cancel-order',
  standalone: true,
  imports: [
    CommonModule,
    IonContent,
    IonGrid,
    IonRow,
    IonCol,
    HeaderComponent,
    ButtonComponent,
    RadioButtonModule,
    IonRadioGroup,
    TextareaModule,
  ],
  templateUrl: './cancel-order.component.html',
  styleUrls: ['./cancel-order.component.scss'],
})
export class CancelOrderComponent implements OnInit {
  private _modalCtrl = inject(ModalController);
  private _apiService = inject(ApiService);
  private _navService = inject(NavigationService);
  private _dialogService = inject(DialogService);

  @Input() orderId!: string;
  @Input() orderStatus!: number;
  @Input() initialBreakpoint!: number;

  cancelOrderForm: FormGroup = new FormGroup({
    type: new FormControl(null, [
      Validators.required,
      Validators.min(0),
      Validators.max(5),
    ]),
    reason: new FormControl(null, [
      Validators.required,
      Validators.minLength(1),
    ]),
    otherReason: new FormControl(''),
    source: new FormControl('Customer'),
  });
  cancelReasons: CancelReason[] = [
    {
      type: 5,
      reason: "My Laundry Pro wasn't available",
    },
    {
      type: 4,
      reason: 'Order not accepted in time',
    },
    {
      type: 3,
      reason: "Couldn't meet my requests",
    },
    {
      type: 2,
      reason: 'Cost concerns',
    },
    {
      type: 1,
      reason: 'Need to adjust my order details',
    },
    {
      type: 0,
      reason: 'Other',
    },
  ];

  previousReasonValue = '';

  ngOnInit(): void {
    const reason = this.cancelOrderForm.get('reason');

    if (reason) {
      reason.valueChanges.pipe(untilDestroyed(this)).subscribe({
        next: (value) => {
          this.handleBreakpointChange(value);
        },
      });
    }
  }

  handleBreakpointChange(value: string): void {
    if (
      value === 'Other' ||
      (this.previousReasonValue === 'Other' && value !== 'Other')
    ) {
      const modal = this._modalCtrl.getTop();
      if (modal) {
        modal.then((modalInstance) => {
          if (modalInstance) {
            modalInstance.getCurrentBreakpoint().then((currentBreakpoint) => {
              const roundedBreakpoint = Number(currentBreakpoint?.toFixed(1));
              if (
                roundedBreakpoint === this.initialBreakpoint &&
                value === 'Other'
              ) {
                modalInstance.setCurrentBreakpoint(
                  Number((this.initialBreakpoint + 0.2).toFixed(1))
                );
              } else if (
                roundedBreakpoint ===
                  Number((this.initialBreakpoint + 0.2).toFixed(1)) &&
                this.previousReasonValue === 'Other' &&
                value !== 'Other'
              ) {
                modalInstance.setCurrentBreakpoint(this.initialBreakpoint);
              }
              this.previousReasonValue = value;
            });
          }
        });
      }
    } else {
      this.previousReasonValue = value;
    }
  }

  cancelOrder(): void {
    const reason = this.cancelOrderForm.get('reason');

    const matchingCancelReason = this.cancelReasons.find(
      (cancelReason) => cancelReason.reason === reason?.value
    );

    if (matchingCancelReason) {
      const matchingType = matchingCancelReason.type;
      this.cancelOrderForm.get('type')?.setValue(matchingType);
    }
    if (this.cancelOrderForm.valid) {
      if (this.orderStatus === 1 || this.orderStatus === 1.5) {
        const cancelReason: CancelReasonContent = {
          type: this.cancelOrderForm.get('type')?.value,
          reason: this.cancelOrderForm.get('reason')?.value,
          source: this.cancelOrderForm.get('source')?.value,
        };
        if (this.cancelOrderForm.get('type')?.value === 0) {
          cancelReason['otherReason'] =
            this.cancelOrderForm.get('otherReason')?.value;
        }

        this._apiService
          .delete(
            NEW_ORDER,
            {
              orderId: this.orderId,
              cancelReason: cancelReason,
            },
            true,
            'Canceling order'
          )
          .pipe(untilDestroyed(this))
          .subscribe({
            next: (res) => {
              if (res.statusCode === 204) {
                this.showSuccessNotification();
                this.closeModal();
                this._navService.forwardWithOptions('/laundry', {
                  orderCanceled: true,
                });
              }
            },
          });
      }
    }
  }

  closeModal(): void {
    this._modalCtrl.dismiss();
  }

  trackReasons(index: number, cancelReason: CancelReason): string {
    return `${cancelReason.type}-${index}`;
  }

  showSuccessNotification(): void {
    const notificationToast: NotificationContent = {
      showHeading: false,
      text: 'Your order has been canceled.',
      notificationType: 'success',
      showNotificationIcon: false,
      placement: 'toast',
      showButtonCTA: false,
      showLinkCTA: false,
    };
    this._dialogService.showCustomToast(notificationToast, 5000);
  }
}
