<!-- Laundry Preferences Form -->
<form
  id="laundry-preferences-form"
  class="laundry-preferences-form basic-layout-frame small"
  [formGroup]="laundryPreferencesForm"
>
  <!--Title and Subtitle-->
  <ion-row class="intro-text">
    <ion-col size="12">
      <h2>Laundry Care</h2>
    </ion-col>
  </ion-row>

  <!--Select Detergent-->
  <ion-row>
    <ion-col size="12">
      <poplin-select
        [hasLabel]="true"
        [label]="'Select Detergent'"
        label="{{ label }}"
        [options]="selectOptions"
        (eventEmitter)="handleSelect($event)"
        [selected]="detergent ? detergent : ''"
        [border]="
          attemptedContinue && selectedDetergent === '' ? 'error' : 'default'
        "
        [showError]="attemptedContinue && selectedDetergent === ''"
        [error]="
          attemptedContinue && selectedDetergent === ''
            ? 'Select a detergent'
            : ''
        "
      ></poplin-select>
    </ion-col>
  </ion-row>

  <!-- Checkbox List -->
  <ion-row>
    <ion-col size="12" class="checkbox-list">
      <ion-row class="checkbox-list">
        <ion-col size="12">
          <h6 class="checkbox-list__header">Check all that apply.</h6>
        </ion-col>
      </ion-row>

      <!--Delicates Checkbox-->
      <ion-row class="opening-checkbox preference-row">
        <ion-col size="12">
          <poplin-checkbox
            aria-label="delicates"
            ngDefaultControl
            class="preference-checkbox"
            [label]="'Delicate cycle'"
            (eventEmitter)="handleChecked($event, 'delicate-checked')"
            [parentForm]="laundryPreferencesForm"
            formControlName="delicates"
          ></poplin-checkbox>
          <div
            class="expanding-area preference-description-row"
            *ngIf="delicates"
          >
            <span class="checkbox-text-description">
              {{ delicateSubLabel }}
            </span>
          </div>
        </ion-col>
      </ion-row>

      <!--Hang-Dry Checkbox-->
      <ion-row class="opening-checkbox preference-row" *ngIf="!isExpressOrder">
        <ion-col size="12">
          <poplin-checkbox
            aria-label="hang-dry"
            ngDefaultControl
            class="preference-checkbox"
            [label]="'Hang-dry'"
            [isChecked]="hangDry"
            (eventEmitter)="handleChecked($event, 'hang-dry-checked')"
            [parentForm]="laundryPreferencesForm"
            formControlName="hangDry"
          ></poplin-checkbox>
          <div
            class="expanding-area preference-description-row"
            *ngIf="hangDry"
          >
            <span class="checkbox-text-description">
              {{ hangDrySubLabel }}
            </span>
          </div>
        </ion-col>
      </ion-row>

      <!--Hangers Checkbox-->
      <ion-row class="opening-checkbox preference-row">
        <ion-col size="12">
          <poplin-checkbox
            aria-label="hangers"
            ngDefaultControl
            class="preference-checkbox"
            [label]="'Return items on hangers'"
            [isChecked]="hangers"
            (eventEmitter)="handleChecked($event, 'hangers-checked')"
            [parentForm]="laundryPreferencesForm"
            formControlName="hangers"
          ></poplin-checkbox>
          <div
            class="expanding-area preference-description-row"
            *ngIf="hangers"
          >
            <span class="checkbox-text-description">
              {{ withHangersSubLabel }}
            </span>
          </div>
        </ion-col>
      </ion-row>

      <!--Additional Instructions Checkbox-->
      <ion-row class="opening-checkbox preference-row">
        <ion-col size="12">
          <div class="guided-checkbox">
            <poplin-checkbox
              aria-label="instructions"
              ngDefaultControl
              class="preference-checkbox"
              [label]="'Additional Requests'"
              [subLabel]="additionalInstructionsSubLabel"
              [isChecked]="hasInstructions"
              (eventEmitter)="handleChecked($event, 'instructions-checked')"
              [parentForm]="laundryPreferencesForm"
              formControlName="hasInstructions"
            >
            </poplin-checkbox>
            <poplin-button
              ngDefaultControl
              id="instructions-button"
              class="help-button"
              [label]="'Button Text'"
              [showIcon]="true"
              [iconSlot]="'icon-only'"
              [size]="'small'"
              color="monochrome"
              [fill]="'clear'"
              [shape]="'round'"
              [icon]="'help_circle_custom'"
            ></poplin-button>
          </div>
          <div
            class="expanding-area preference-description-row"
            *ngIf="hasInstructions"
          >
            <poplin-textarea
              ngDefaultControl
              id="additional-instructions"
              label=""
              placeholder="NOTE: Keep your order simple to ensure it’s accepted quickly!"
              [value]="instructions"
              [parentForm]="laundryPreferencesForm"
              formControlName="instructions"
              (eventEmitter)="handleTextAreaEvent($event)"
              [maxLength]="250"
            >
            </poplin-textarea>
          </div>
        </ion-col>
      </ion-row>
    </ion-col>
  </ion-row>
</form>

<!--Additional Instructions Modal-->
<ion-modal
  #modal
  class="instructions-modal"
  trigger="instructions-button"
  animated="true"
  [initialBreakpoint]="0.25"
  [breakpoints]="[0, 0.25, 0.5]"
>
  <ng-template>
    <ion-grid>
      <ion-row class="modal-title-row">
        <ion-col size="10">
          <span>Additional Requests</span>
        </ion-col>
        <ion-col size="2" class="close-icon">
          <poplin-icon name="close" (click)="closeModal()"></poplin-icon>
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col size="12" class="modal-text">
          <span
            >Laundry Pros provide a wash-dry-fold service. <br /><br />
            They do not look for stains, treat stains, or use any stain removal
            products.<br /><br />
            They do not offer additional services like ironing, lint removal,
            shoe cleaning, hand washing, or dry cleaning.<br /><br />
            If you request specific products other than detergent, please leave
            them out for pickup.
          </span>
        </ion-col>
      </ion-row>
    </ion-grid>
  </ng-template>
</ion-modal>
