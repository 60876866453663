import { createAction, props } from '@ngrx/store';
import {
  CustomerProfileWithId,
  PartialNested,
} from '@sudshare/custom-node-package';

export enum ProfilesStateActionTypes {
  ProfileIdAdded = '[Profiles] ProfileId Added',
  ProfileNameAdded = '[Profiles] Name Added',
  ProfileAddressAdded = '[Profiles] Address Added',
  ProfilePickupAdded = '[Profiles] Pickup Added',
  ProfileLaundryCareAdded = '[Profiles] Laundry Care Added',
  CreateProfile = '[Profiles] Create Profile',
  ResetProfile = '[Profiles] Reset Profile',
  ProfileUpdated = '[Profiles] Update Profile',
  ProfileDuplicated = '[Profiles] Duplicate Profile',
  ProfileDeleted = '[Profiles] Delete Profile',
}

export const ProfileIdAdded = createAction(
  ProfilesStateActionTypes.ProfileIdAdded,
  props<{ payload: PartialNested<CustomerProfileWithId> }>()
);
export const ProfileNameAdded = createAction(
  ProfilesStateActionTypes.ProfileNameAdded,
  props<{ payload: PartialNested<CustomerProfileWithId> }>()
);

export const ProfileAddressAdded = createAction(
  ProfilesStateActionTypes.ProfileAddressAdded,
  props<{ payload: PartialNested<CustomerProfileWithId> }>()
);

export const ProfilePickupAdded = createAction(
  ProfilesStateActionTypes.ProfilePickupAdded,
  props<{ payload: PartialNested<CustomerProfileWithId> }>()
);

export const ProfileLaundryCareAdded = createAction(
  ProfilesStateActionTypes.ProfileLaundryCareAdded,
  props<{ payload: PartialNested<CustomerProfileWithId> }>()
);

export const CreateProfile = createAction(
  ProfilesStateActionTypes.CreateProfile
);

export const UpdateProfile = createAction(
  ProfilesStateActionTypes.ProfileUpdated
);

export const DuplicateProfile = createAction(
  ProfilesStateActionTypes.ProfileDuplicated
);

export const DeleteProfile = createAction(
  ProfilesStateActionTypes.ProfileDeleted,
  props<{ payload: PartialNested<CustomerProfileWithId> }>()
);

export const ResetProfile = createAction(ProfilesStateActionTypes.ResetProfile);
