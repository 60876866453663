import { inject, Injectable } from '@angular/core';
import { LoadingController } from '@ionic/angular';
import {
  AnalyticsService,
  DatabaseService,
  LoggingService,
  NavigationService,
  StatsigService,
  StoreService,
} from '@services';
import { OrderDocument } from '@sudshare/custom-node-package';
import {
  addOrderDataToStore,
  AppRoutes,
  loadingOptions,
  NewOrderRoutes,
} from '@utils';
import { filter, map, switchMap, take } from 'rxjs';
import { PREFERRED_LP_ENDPOINT } from '../../utils/constants';
import { ApiService } from '../api/api.service';

const LOG_TAG = '[RepeatOrderService]';

@Injectable({
  providedIn: 'root',
})
export class RepeatOrderService {
  private _logger = inject(LoggingService);
  private _store = inject(StoreService);
  private _dbService = inject(DatabaseService);
  private _statsigService = inject(StatsigService);
  private _loadingCtrl = inject(LoadingController);
  private _analytics = inject(AnalyticsService);
  private _navService = inject(NavigationService);
  private _apiService = inject(ApiService);

  async buildRepeatOrderData(
    internalId: string,
    placedTime: Date,
    repeatOrder: string
  ): Promise<void> {
    const loading = await this._loadingCtrl.create(
      loadingOptions('Getting order details...')
    );
    await loading.present();

    this._dbService
      .subscribeToOrderDoc(internalId)
      .pipe(
        take(1),
        filter(
          (orderData: OrderDocument | null): orderData is OrderDocument =>
            orderData !== null
        ),
        switchMap((orderData: OrderDocument) => {
          return this._store.getUserDocumentData().pipe(
            take(1),
            switchMap(() =>
              this._apiService
                .post(
                  PREFERRED_LP_ENDPOINT,
                  { orderId: internalId },
                  { showLoading: false }
                )
                .pipe(
                  take(1),
                  map((response) => ({ orderData, response }))
                )
            )
          );
        })
      )
      .subscribe({
        next: async ({ orderData, response }) => {
          orderData.PreferredSudsters = response.data.preferred;

          await addOrderDataToStore(
            orderData,
            this._store,
            this._statsigService
          );
          loading.dismiss();

          this._analytics.createTrackEvent(repeatOrder, {
            page: AppRoutes.PAST_ORDERS,
          });

          // Determine navigation route
          const navigateTo = (route: string, extras: object) => {
            this._navService.forwardWithOptions(route, extras);
          };

          const navigationExtras = {
            placedTime: placedTime,
            ...(orderData.PreferredSudsters.length > 0 && {
              isFromReview: true,
            }),
          };

          if (
            !orderData.PreferredSudsters ||
            orderData.PreferredSudsters.length === 0
          ) {
            this._store
              .getLaundryPros()
              .pipe(take(1))
              .subscribe((data) => {
                const preferredLaundryPros =
                  data.laundryProData?.preferred ?? [];

                if (preferredLaundryPros.length === 0) {
                  navigateTo(NewOrderRoutes.REVIEW_ORDER, navigationExtras);
                } else {
                  navigateTo(NewOrderRoutes.PREFERRED_LP, {
                    ...navigationExtras,
                    isFromReview: true,
                  });
                }
              });
          } else {
            navigateTo(NewOrderRoutes.PREFERRED_LP, navigationExtras);
          }
        },
        error: (err) => {
          this._logger.logWarning(
            LOG_TAG,
            'Failed to get order data or user document data: ' + err.message
          );
          loading.dismiss();
        },
      });
  }
}
