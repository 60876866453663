import { Injectable } from '@angular/core';
import { Capacitor } from '@capacitor/core';
import { environment } from '@environments/environment';
import Statsig, { DynamicConfig, Layer, StatsigUser } from 'statsig-js';

@Injectable({
  providedIn: 'root',
})
export class StatsigService {
  async init(customerId: string, signUpTime: string): Promise<void> {
    const PRODUCTION = 'Prod';
    const NON_PROD = 'NonProd';
    await Statsig.initialize(
      environment.statsig.clientKey,
      {
        userID: customerId,
        appVersion: environment.version,
        custom: {
          platformType: Capacitor.getPlatform(),
          userType: 'Customer',
          signUpTimestamp: signUpTime,
        },
      },
      { environment: { tier: environment.production ? PRODUCTION : NON_PROD } }
    );
  }

  getGate(name: string): boolean {
    return Statsig.checkGate(name);
  }

  getConfig(name: string): DynamicConfig {
    return Statsig.getConfig(name);
  }

  getLayer(name: string): Layer {
    return Statsig.getLayer(name);
  }

  getExperiment(name: string): DynamicConfig {
    return Statsig.getExperiment(name);
  }

  log(
    eventName: string,
    eventValue: string,
    metadata?: Record<string, string>
  ): void {
    return Statsig.logEvent(eventName, eventValue, metadata);
  }

  shutdown(): void {
    return Statsig.shutdown();
  }

  async updateUser(data: StatsigUser): Promise<void> {
    await Statsig.updateUser(data);
  }
}
