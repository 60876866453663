import { inject, Injectable } from '@angular/core';
import {
  Auth,
  ConfirmationResult,
  confirmPasswordReset,
  fetchSignInMethodsForEmail,
  getRedirectResult,
  GoogleAuthProvider,
  linkWithPhoneNumber,
  onAuthStateChanged,
  PhoneAuthProvider,
  RecaptchaVerifier,
  sendPasswordResetEmail,
  signInWithCredential,
  signInWithCustomToken,
  signInWithEmailAndPassword,
  signInWithPhoneNumber,
  signInWithRedirect,
  signOut,
  updateEmail,
  updatePassword,
  user,
  User,
  UserCredential,
} from '@angular/fire/auth';
import {
  FirebaseAuthentication,
  PhoneCodeSentEvent,
} from '@capacitor-firebase/authentication';
import { isNewGoogleAccount } from '@utils';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private _linkPhone!: ConfirmationResult;
  private _firebaseAuth = inject(Auth);
  user$ = user(this._firebaseAuth);

  async isLoggedIn(): Promise<User | null> {
    return new Promise((resolve) => {
      if (this._firebaseAuth) {
        onAuthStateChanged(this._firebaseAuth, (user) => {
          resolve(user && !isNewGoogleAccount(user) ? user : null);
        });
      } else {
        resolve(null);
      }
    });
  }
  async signOut(): Promise<void> {
    return signOut(this._firebaseAuth);
  }

  async getToken(forceReload = false): Promise<string | null> {
    try {
      let token = null;
      const user = this._firebaseAuth.currentUser;
      if (user) {
        // getIdToken() retrieves the latest token and refreshes it if necessary
        token = await user.getIdToken(forceReload);
      }
      return token;
    } catch (error) {
      console.error('Error fetching token', error);
      throw error;
    }
  }

  async refreshUser(): Promise<void> {
    if (this._firebaseAuth.currentUser) {
      await this._firebaseAuth.currentUser.reload();
    }
  }

  async getUser(): Promise<User | null> {
    return this._firebaseAuth.currentUser;
  }

  async emailLogin(email: string, password: string): Promise<UserCredential> {
    return signInWithEmailAndPassword(this._firebaseAuth, email, password);
  }

  async updateEmail(user: User, newEmail: string): Promise<void> {
    return updateEmail(user, newEmail);
  }

  async updatePassword(user: User, newPassword: string): Promise<void> {
    return updatePassword(user, newPassword);
  }

  async verifyAccountWeb(phoneNumber: string): Promise<ConfirmationResult> {
    this.authCaptcha().clear();
    return signInWithPhoneNumber(
      this._firebaseAuth,
      phoneNumber,
      this.authCaptcha()
    );
  }

  verifyAccountMobile(
    phoneNumber: string,
    isResendAttempt: boolean
  ): Promise<PhoneCodeSentEvent> {
    return new Promise<PhoneCodeSentEvent>((resolve) => {
      FirebaseAuthentication.removeAllListeners();
      // register for phone code sent event before sending the sms
      FirebaseAuthentication.addListener('phoneCodeSent', (event) => {
        resolve(event);
      });

      FirebaseAuthentication.signInWithPhoneNumber({
        phoneNumber: phoneNumber,
        resendCode: isResendAttempt,
      });
    });
  }

  async phoneVerifyCode(
    verifyId: string,
    verifyCode: string
  ): Promise<UserCredential> {
    this.authCaptcha().clear();
    const phoneAuth = PhoneAuthProvider.credential(verifyId, verifyCode);

    return signInWithCredential(this._firebaseAuth, phoneAuth);
  }

  async resetPassword(email: string): Promise<void> {
    return sendPasswordResetEmail(this._firebaseAuth, email);
  }

  async confirmResetPassword(email: string, code: string): Promise<void> {
    return await confirmPasswordReset(this._firebaseAuth, email, code);
  }

  async googleWeb(): Promise<never> {
    return signInWithRedirect(this._firebaseAuth, this.googleProvider());
  }

  async googleWebFinish(): Promise<UserCredential | null> {
    return getRedirectResult(this._firebaseAuth);
  }

  async googleMobile(): Promise<UserCredential> {
    const nativeResult = await FirebaseAuthentication.signInWithGoogle();
    const webCred = GoogleAuthProvider.credential(
      nativeResult.credential?.idToken
    );

    return signInWithCredential(this._firebaseAuth, webCred);
  }

  async checkEmail(email: string): Promise<string[]> {
    return fetchSignInMethodsForEmail(this._firebaseAuth, email);
  }

  async linkPhoneNumber(user: User, phoneNumber: string): Promise<void> {
    this._linkPhone = await linkWithPhoneNumber(
      user,
      phoneNumber,
      this.authCaptcha()
    );

    return;
  }

  async confirmPhoneLink(verifyCode: string): Promise<UserCredential> {
    this.authCaptcha().clear();
    return this._linkPhone.confirm(verifyCode);
  }

  async adminLogin(token: string): Promise<UserCredential> {
    return signInWithCustomToken(this._firebaseAuth, token);
  }

  private authCaptcha(): RecaptchaVerifier {
    return new RecaptchaVerifier(this._firebaseAuth, 'recaptcha-container', {
      size: 'invisible',
      callback: () => {
        return;
      },
    });
  }

  private googleProvider(): GoogleAuthProvider {
    return new GoogleAuthProvider();
  }
}
