import { CommonModule } from '@angular/common';
import {
  Component,
  CUSTOM_ELEMENTS_SCHEMA,
  inject,
  OnInit,
} from '@angular/core';
import { Browser } from '@capacitor/browser';
import { environment } from '@environments/environment';
import {
  IonCol,
  IonContent,
  IonGrid,
  IonRow,
  ModalController,
} from '@ionic/angular/standalone';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {
  AuthService,
  NavigationService,
  StoreService,
  ZendeskService,
} from '@services';
import {
  HeaderComponent,
  IconComponent,
  ItemClickEvent,
  ItemComponent,
  ListComponent,
} from '@sudshare/custom-design-package';
import { closeModal, getAppVersion } from '@utils';
import { take } from 'rxjs';

enum HelpType {
  HelpChat = 'help chat',
  HelpCenter = 'help center',
  SeniorSupport = 'senior support',
}

@UntilDestroy()
@Component({
  selector: 'app-help-modal',
  standalone: true,
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [
    CommonModule,
    HeaderComponent,
    IconComponent,
    ItemComponent,
    ListComponent,
    IonContent,
    IonGrid,
    IonRow,
    IonCol,
  ],
  templateUrl: './help-modal.component.html',
  styleUrls: ['./help-modal.component.scss'],
})
export class HelpModalComponent implements OnInit {
  private _modalCtrl = inject(ModalController);
  private _navService = inject(NavigationService);
  private _authService = inject(AuthService);
  private _zendeskService = inject(ZendeskService);
  private _store = inject(StoreService);

  showChat = false;
  isSenior = false;
  version = getAppVersion();

  ngOnInit(): void {
    this._authService.isLoggedIn().then((user) => {
      return user ? (this.showChat = true) : null;
    });
    this._store
      .getAccountDetails()
      .pipe(untilDestroyed(this), take(1))
      .subscribe((details) => {
        this.isSenior = details.senior;
      });
  }

  get helpType(): typeof HelpType {
    return HelpType;
  }

  closeModal(): Promise<boolean> {
    return closeModal(this._modalCtrl);
  }

  handleButtonClick(buttonType: string): void {
    this._navService.forward(buttonType);
    this.closeModal();
  }

  handleEvent(event: ItemClickEvent, type: HelpType): void {
    if (event.itemClicked) {
      switch (type) {
        case this.helpType.HelpCenter: {
          Browser.open({
            url: `${environment.zendesk.customerLink}`,
          });
          return;
        }
        case this.helpType.HelpChat: {
          if (environment.production) {
            this._zendeskService.show();
          } else {
            window.alert('Unavailable in non-prod');
          }
          return;
        }
        case this.helpType.SeniorSupport:
          {
            window.open('tel:12402261515');
          }
          return;
      }
    }
  }
}
