import { Injector, Type, inject } from '@angular/core';
import { Observable, defer } from 'rxjs';

/**
 * For use with lazy loading services, NOT ALL services are meant to be lazy loaded
 */
export const lazyService = <T>(
  loader: () => Promise<Type<T>>
): Observable<T> => {
  const _injector = inject(Injector);

  return defer(async () => {
    const service = await loader();
    return _injector.get(service);
  });
};
