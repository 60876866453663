import { environment } from '../../environments/environment';

export async function trackEvent(payload: any): Promise<void> {
  console.log(`Track Event ${payload}`);
  const GENERAL_API_URL = `${environment.apiPath}/general/v1`;
  try {
    await fetch(`${GENERAL_API_URL}/analytics/track-event`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-api-key': `${environment.apiToken}`,
      },
      body: JSON.stringify(payload),
    });
    console.log('Event tracked Successfully');
  } catch (error) {
    console.error('Error tracking event:', error);
  }
}
