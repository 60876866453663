import { Injectable } from '@angular/core';
import { Share, ShareOptions, ShareResult } from '@capacitor/share';

@Injectable({
  providedIn: 'root',
})
export class ShareService {
  async shareMenu(options: ShareOptions): Promise<ShareResult> {
    return await Share.share(options);
  }
}
