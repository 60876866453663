import { Injectable, inject } from '@angular/core';
import { Router, RoutesRecognized } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { filter, pairwise } from 'rxjs';

@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class RouterTrackingService {
  private _router = inject(Router);

  private previous = '';
  private current = '';

  get previousRoute(): string {
    return this.previous;
  }

  get currentRoute(): string {
    return this.current;
  }

  init(): void {
    this._router.events
      .pipe(
        untilDestroyed(this),
        filter((evt: any) => evt instanceof RoutesRecognized),
        pairwise()
      )
      .subscribe({
        next: (events: RoutesRecognized[]) => {
          this.previous = this.removeQueryParams(events[0].urlAfterRedirects);
          this.current = this.removeQueryParams(events[1].urlAfterRedirects);
        },
      });
  }

  public removeQueryParams(route: string): string {
    return route.split('?')[0];
  }
}
