import { inject, Injectable } from '@angular/core';
import { Smartlook } from '@awesome-cordova-plugins/smartlook/ngx';
import { Capacitor } from '@capacitor/core';
import { environment } from '@environments/environment';

@Injectable({
  providedIn: 'root',
})
export class SmartLookMobileService {
  private _smartLook = inject(Smartlook);

  init(): void {
    return this._smartLook.setProjectKey({
      key: environment.smartlook.mobileKey,
    });
  }

  userSetup(userId: string, fullName: string, userEmail: string): void {
    this._smartLook.setUserIdentifier({ identifier: userId });
    this._smartLook.setUserName({ name: fullName });
    this._smartLook.setUserEmail({ email: userEmail });
    this._smartLook.setUserProperty({
      propertyName: 'platformType',
      value: Capacitor.getPlatform(),
    });

    return;
  }

  start(): void {
    return this._smartLook.start();
  }

  stop(): void {
    return this._smartLook.stop();
  }
}
