/* eslint-disable @typescript-eslint/no-unused-vars */

import { inject, Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivateFn,
  RouterStateSnapshot,
} from '@angular/router';
import { Capacitor } from '@capacitor/core';
import { NavigationService } from '@services';
import { getQueryParams } from './get-query-params';

@Injectable({
  providedIn: 'root',
})
class WebGuard {
  private _navService = inject(NavigationService);

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    const platform = Capacitor.getPlatform();
    if (platform === 'web') {
      this._navService.forwardWithOptions('auth', {}, getQueryParams());
      return false;
    } else {
      return true;
    }
  }
}

export const WebAppGuard: CanActivateFn = (
  next: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
): boolean => {
  return inject(WebGuard).canActivate(next, state);
};
