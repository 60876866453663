import { Injectable } from '@angular/core';
import {
  GetResult,
  Preferences,
  PreferencesPlugin,
} from '@capacitor/preferences';

@Injectable({
  providedIn: 'root',
})
export class StorageService {
  constructor() {
    Preferences.configure({
      group: 'Poplin',
    });
  }

  setObject(keyName: string, valueData: object): Promise<void> {
    return this.getStorage().set({
      key: keyName,
      value: JSON.stringify({ valueData }),
    });
  }

  setItem(keyName: string, valueData: string): Promise<void> {
    return this.getStorage().set({ key: keyName, value: valueData });
  }

  async getObject(keyName: string): Promise<any> {
    const res = await this.getStorage().get({ key: keyName });
    return JSON.parse(res?.value || '');
  }

  getItem(keyName: string): Promise<GetResult> {
    return this.getStorage().get({ key: keyName });
  }

  removeItem(keyName: string): Promise<void> {
    return this.getStorage().remove({ key: keyName });
  }

  clearStorage(): Promise<void> {
    return this.getStorage().clear();
  }

  private getStorage(): PreferencesPlugin {
    return Preferences;
  }
}
