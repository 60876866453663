<ion-content>
  <ion-row class="stripe-row-main">
    <ion-col size="12" class="stripe-col-main">
      <form [formGroup]="paymentElementForm">
        <div class="stripe-element-wrapper">
          <ngx-stripe-elements
            *ngIf="clientSecret"
            [stripe]="stripe"
            [elementsOptions]="elementsOptions"
          >
            <ngx-stripe-payment
              class="stripe-modal"
              [options]="paymentElementOptions"
            ></ngx-stripe-payment>
          </ngx-stripe-elements>
        </div>
        <poplin-button
          id="stripe-pay-button"
          [label]="buttonLabel"
          (click)="pay()"
        ></poplin-button>
      </form>
    </ion-col>
  </ion-row>
</ion-content>
