import { createAction, props } from '@ngrx/store';
import { ProfileState } from './profile.reducer';

export enum ProfileActionTypes {
  ProfileSubscribeSuccess = '[User Profile] Subscribe Success',
  ProfileSubscribePending = '[User Profile] Subscribe Pending',
  ProfileSubscribeFailure = '[User Profile] Subscribe Failure',
  ProfileAdded = '[User Profile] New Profile Added',
}

export const ProfileSubscribeSuccess = createAction(
  ProfileActionTypes.ProfileSubscribeSuccess,
  props<{ payload: ProfileState }>()
);
export const ProfileSubscribePending = createAction(
  ProfileActionTypes.ProfileSubscribePending,
  props<{ payload: ProfileState }>()
);
export const ProfileSubscribeFailure = createAction(
  ProfileActionTypes.ProfileSubscribeFailure,
  props<{ payload: ProfileState }>()
);
export const ProfileAdded = createAction(
  ProfileActionTypes.ProfileAdded,
  props<{ payload: ProfileState }>()
);
