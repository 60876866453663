import { Action, createReducer, on } from '@ngrx/store';
import { CustomerDocument, PartialNested } from '@sudshare/custom-node-package';
import { STORE_STATUS, StoreStatusOptions } from '@utils';
import {
  AccountDisabled,
  DiscountUpdated,
  SubscribeFailure,
  SubscribeSuccess,
  UserDiscount,
} from './user.actions';

export interface UserState {
  docId: string | null;
  docData: PartialNested<CustomerDocument> | null;
  error: string | null;
  status: STORE_STATUS;
  discount: UserDiscount | null;
}

const initialState: UserState = {
  docId: null,
  docData: null,
  error: null,
  status: StoreStatusOptions.PENDING,
  discount: null,
};

const userReducer = createReducer(
  initialState,

  on(SubscribeSuccess, (state, { payload }) => ({
    ...state,
    status: StoreStatusOptions.SUCCESS,
    docId: payload.docId,
    docData: payload.docData,
  })),

  on(SubscribeFailure, (state) => ({
    ...state,
    status: StoreStatusOptions.ERROR,
  })),

  on(AccountDisabled, (state, { payload }) => ({
    ...state,
    status: StoreStatusOptions.ERROR,
    docData: payload.docData,
  })),

  on(DiscountUpdated, (state, { payload }) => ({
    ...state,
    discount: payload.discount,
  }))
);

export function UserReducer(
  state: UserState | undefined,
  action: Action
): UserState {
  return userReducer(state, action);
}
