import { LoadingOptions } from '@ionic/angular/standalone';
import { createAction, props } from '@ngrx/store';

export enum LoadingActionTypes {
  LoadingStarted = '[Loading] Started',
  LoadingFinished = '[Loading] Finished ',
}

export const LoadingStarted = createAction(
  LoadingActionTypes.LoadingStarted,
  props<{ payload: LoadingOptions }>()
);
export const LoadingFinished = createAction(LoadingActionTypes.LoadingFinished);
