<poplin-header
  [showClose]="true"
  (eventEmitter)="onClose()"
  [title]="title"
  class="block-header"
></poplin-header>
<ion-content class="drawer-modal">
  <ion-grid class="grid-padding">
    <ion-row>
      <ion-col size="12">
        <p
          *ngIf="!isFeedbackModal && !isEstimatedCostModal"
          class="blocked-content"
          [innerHTML]="body"
        ></p>
        <p
          *ngIf="isFeedbackModal && selectedRating === 5"
          class="blocked-content"
        >
          We added {{ feedBackModalLP }} to your list of
          <a (click)="navigateToPreferredLaundryPros()"
            >Preferred Laundry Pros</a
          >. We look forward to serving you again soon!
        </p>
        <p
          *ngIf="isFeedbackModal && selectedRating === 4"
          class="blocked-content"
        >
          We appreciate your support and look forward to serving you again soon!
          Meanwhile, please
          <a (click)="clickRatePoplin()">rate Poplin in the app store</a> or
          <a (click)="clickFourStarReferral()">refer Poplin</a> to someone you
          know.
        </p>
        <p
          *ngIf="isFeedbackModal && selectedRating <= 3"
          class="blocked-content"
        >
          We're sorry your Poplin experience didn't meet your expectations.
          We've blocked {{ feedBackModalLP }} from seeing or accepting any
          future orders (they will NOT be notified of this block).<br /><br />
          We forwarded your feedback to our investigation team who will follow
          up with you shortly.
        </p>
        <ng-container *ngIf="isEstimatedCostModal" class="blocked-content">
          <ion-grid>
            <ion-row>
              <ion-col size="12" class="modal-text estimated-cost-modal">
                <div class="cost-breakdown">
                  <p class="cost-description">
                    Below is as estimated cost based on your order details. Your
                    actual cost is based on the weight of your laundry.
                  </p>
                  <div class="cost-breakdown-info">
                    <div class="main-line-items">
                      <p *ngIf="oversizedItems === 0">
                        {{ bags }} {{ bags > 1 ? "bags" : "bag" }} (${{
                          pricePerPound.toFixed(2)
                        }}/pound)
                      </p>
                      <p *ngIf="oversizedItems >= 1">
                        {{ bags }} bags (${{ pricePerPound.toFixed(2) }}/pound)
                        + {{ oversizedItems }} oversized item{{
                          oversizedItems > 1 ? "s" : ""
                        }}
                      </p>
                      <p *ngIf="!minimumPriceApplied">
                        Estimated weight {{ estimatedWeight }} pounds
                        <strong>{{ estimatedWeightPricing | currency }}</strong>
                      </p>

                      <p *ngIf="minimumPriceApplied">
                        Minimum order amount ({{
                          deliveryType === "SameDay"
                            ? "Express Delivery"
                            : "Standard Delivery"
                        }}) <br />
                        <strong>{{ minimumPrice | currency }}</strong>
                      </p>
                      <p *ngIf="oversizedItems > 0">
                        {{ oversizedItems }} oversized item{{
                          oversizedItems > 1 ? "s" : ""
                        }}
                        <strong>{{ oversizedItemPricing | currency }}</strong>
                      </p>
                      <p *ngIf="coveragePricing > 0">
                        Poplin Protection Plan ({{ coverage }})
                        <strong>{{ coveragePricing | currency }}</strong>
                      </p>
                      <p *ngIf="coverage === 'Basic'">
                        Poplin Protection Plan ({{ coverage }})
                        <strong>FREE</strong>
                      </p>
                      <p *ngIf="surgePrice > 0">
                        Surge Pricing
                        <strong>{{ surgePrice / 100 | currency }}</strong>
                      </p>
                      <p>
                        Subtotal <strong>{{ orderSubTotal | currency }}</strong>
                      </p>
                      <p *ngIf="discountPercentageAmount">
                        {{ discountPercentage }}% Discount applied
                        <strong
                          >- {{ discountPercentageAmount | currency }}</strong
                        >
                      </p>
                      <p
                        *ngIf="discountPercentageAmount"
                        class="credits-details"
                      >
                        <span>{{ discountCreditsDetails }}</span>
                      </p>

                      <p *ngIf="trustAndSafetyFee > 0">
                        <a
                          href="https://poplin.zendesk.com/hc/en-us/articles/24436347827995-Potential-Fees"
                          target="_blank"
                          >Trust and safety fee</a
                        >
                        <strong>${{ trustAndSafetyFeeString }}</strong>
                      </p>
                      <p>
                        Estimated tax <strong>{{ orderTax | currency }}</strong>
                      </p>
                      <p
                        *ngIf="
                          accountCreditsApplied &&
                          accountCreditsApplied !== '0.00'
                        "
                      >
                        Account Credits applied
                        <strong
                          >- {{ accountCreditsApplied | currency }}</strong
                        >
                      </p>
                      <p *ngIf="discountCreditsAmount">
                        Discount Credits applied
                        <strong
                          >- {{ discountCreditsAmount | currency }}</strong
                        >
                      </p>
                      <p *ngIf="discountCreditsAmount" class="credits-details">
                        <span>{{ discountCreditsDetails }}</span>
                      </p>
                      <p class="estimated-cost">
                        Estimated cost
                        <strong
                          ><span
                            *ngIf="orderTotal !== orderCustomerCharge"
                            class="total-before-discount"
                            >{{ orderTotal | currency }}</span
                          >{{ orderCustomerCharge | currency }}</strong
                        >
                      </p>
                    </div>
                    <p class="recommended-tip">
                      Recommended tip
                      <strong>{{ recommendedTip | currency }}</strong>
                    </p>
                    <p class="preauth-line" *ngIf="showPreAuthLine">
                      Your credit/debit card will be temporarily pre-authorized
                      for <strong>{{ orderPreAuth | currency }}</strong
                      >.
                      <a (click)="clickOpenPreAuthorizationModal()"
                        >Learn more about pre-authorization.</a
                      >
                    </p>
                  </div>
                  <poplin-notification
                    *ngIf="isCommercial"
                    [notificationHeading]="''"
                    [text]="
                      'This order will be added to your monthly invoice statement.'
                    "
                    [notificationIcon]="'info'"
                    [placement]="'inline'"
                    [color]="'info'"
                    [showLinkCTA]="false"
                  ></poplin-notification>
                </div>
              </ion-col>
            </ion-row>
          </ion-grid>
        </ng-container>
        <poplin-button
          id="drawer-modal-continue-button"
          *ngIf="showButton"
          iconColor="--viridian-800"
          [label]="buttonLabel"
          (eventEmitter)="onConfirm()"
        ></poplin-button>
        <poplin-button
          id="drawer-modal-close-button"
          *ngIf="showSecondButton"
          class="second-button"
          fill="outline"
          [label]="secondButtonLabel"
          (eventEmitter)="onClose()"
        ></poplin-button>

        <ng-container *ngIf="showCheckboxes">
          <form [formGroup]="formGroup" class="checkboxes">
            <ion-row
              *ngFor="
                let title of checkboxTitles;
                index as i;
                trackBy: trackCheckboxTitles
              "
            >
              <ion-col size="12">
                <poplin-checkbox
                  aria-label="drawer-checkbox"
                  id="{{ 'drawer-checkbox-' + (i + 1) }}"
                  ngDefaultControl
                  [parentForm]="formGroup"
                  formControlName="{{ i.toString() }}"
                  [label]="title"
                ></poplin-checkbox>
              </ion-col>
            </ion-row>
          </form>
          <ion-row>
            <ion-col size="12">
              <p class="warning" [innerHTML]="warning"></p>
            </ion-col>
          </ion-row>
          <ion-row>
            <ion-col size="12">
              <poplin-button
                id="drawer-modal-continue-button"
                fill="solid"
                label="Continue"
                [showIcon]="true"
                icon="arrow_right_custom"
                [isDisabled]="!formGroup.valid"
                (eventEmitter)="onConfirm()"
              ></poplin-button>
            </ion-col>
          </ion-row>
        </ng-container>
        <ng-container *ngIf="showCard">
          <poplin-item
            id="refer-item"
            [fill]="'solid'"
            [size]="'default'"
            [title]="cardTitle"
            [showTitleIcon]="true"
            [titleIcon]="cardIcon"
            [titleIconColor]="cardIconColor"
            [showTrailingIcon]="true"
            [showDescription]="true"
            [description]="cardBody"
            [size]="'small'"
            class="card"
            [isTappable]="true"
            (eventEmitter)="navigateToReferralsWithPreferredLaundryPro()"
          ></poplin-item>
        </ng-container>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>
