import { inject, Injectable } from '@angular/core';
import { User } from '@angular/fire/auth';
import { Store } from '@ngrx/store';
import {
  AddressAdded,
  ArrangePreferredList,
  AuthState,
  BagSizeAdded,
  CancelPaymentIntent,
  CheckEmail,
  CloseAccount,
  CommercialAccountSet,
  ConfirmLinkPhone,
  ConfirmPhone,
  CouponAdded,
  CoverageAdded,
  CreateProfile,
  CreditAdded,
  DeleteProfile,
  DeliveryAdded,
  DeviceInfo,
  DiscountUpdated,
  DocUpdate,
  DuplicateProfile,
  FeesAdded,
  getAccountDetails,
  getActiveDiscount,
  getActiveOrderById,
  getActiveOrders,
  getAuthEmail,
  getAuthName,
  getAuthUser,
  getAuthUserId,
  getCommercialAccount,
  getCredits,
  getDeviceId,
  getDocumentData,
  getDocumentId,
  getLaundryPros,
  getNewOrderData,
  getNewOrderStatus,
  getNewProfileData,
  getOrderAddress,
  getOrderMessageById,
  getOrderMessages,
  getOrderPreferences,
  getOrderPreferred,
  getPastOrders,
  getPreferredLPs,
  getProfiles,
  getProtectionAccepted,
  getSurgePriceData,
  LaundryProBlocked,
  LaundryProState,
  LaundryProUnblocked,
  LinkPhone,
  LoadingFinished,
  LoadingStarted,
  LoggedIn,
  LoginAdmin,
  LoginEmail,
  LoginGoogleMobile,
  LoginGoogleWeb,
  Logout,
  MessageData,
  NewOrderPaid,
  OversizedItemsAdded,
  PastOrdersState,
  PickupAdded,
  PlaceOrder,
  PreferencesAdded,
  PreferredLPAdded,
  PriceInformationAdded,
  ProfileAddressAdded,
  ProfileDataAdded,
  ProfileIdAdded,
  ProfileLaundryCareAdded,
  ProfileNameAdded,
  ProfilePickupAdded,
  ProtectionAccepted,
  RateAndTipOrder,
  RemoveTransientOrder,
  RepeatOrder,
  ResetOrder,
  ResetProfile,
  StoreState,
  SurgePriceSet,
  UpdateAuthUser,
  UpdatePreferredAndBlocked,
  UpdateProfile,
  UserDiscount,
  VerifyAccountMobile,
  VerifyAccountWeb,
} from '@store';
import {
  CustomerAPI,
  CustomerDocument,
  CustomerProfileWithId,
  OutstandingOrdersWithId,
  PartialNested,
} from '@sudshare/custom-node-package';
import { CustomerAccountDetails, STORE_STATUS } from '@utils';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class StoreService {
  private _store = inject(Store<StoreState>);

  getAuthInfo(): Observable<User | null> {
    return this._store.select(getAuthUser);
  }

  getUserId(): Observable<string> {
    return this._store.select(getAuthUserId);
  }

  getUserAuthEmail(): Observable<string | null | undefined> {
    return this._store.select(getAuthEmail);
  }

  getUserAuthName(): Observable<string | null | undefined> {
    return this._store.select(getAuthName);
  }

  getPreferredLPs(): Observable<(string | undefined)[]> {
    return this._store.select(getPreferredLPs);
  }

  getAccountDetails(): Observable<CustomerAccountDetails> {
    return this._store.select(getAccountDetails);
  }

  getDeviceInfo(): Observable<string> {
    return this._store.select(getDeviceId);
  }

  getUserDocumentId(): Observable<string | null> {
    return this._store.select(getDocumentId);
  }

  getUserDocumentData(): Observable<PartialNested<CustomerDocument> | null> {
    return this._store.select(getDocumentData);
  }

  getOutstandingOrders(): Observable<{
    orderData: PartialNested<OutstandingOrdersWithId>[] | null;
    status: STORE_STATUS;
  }> {
    return this._store.select(getActiveOrders);
  }

  getOutstandingOrderById(
    orderId: string
  ): Observable<PartialNested<OutstandingOrdersWithId>[] | undefined> {
    return this._store.select(getActiveOrderById(orderId));
  }

  getUserProfiles(): Observable<PartialNested<CustomerProfileWithId>[] | null> {
    return this._store.select(getProfiles);
  }

  getOrderMessages(): Observable<MessageData[] | null> {
    return this._store.select(getOrderMessages);
  }

  getOrderMessagesById(orderId: string): Observable<MessageData[] | undefined> {
    return this._store.select(getOrderMessageById(orderId));
  }

  getOrderAddress(): Observable<
    | PartialNested<{
        pickupAddress: {
          full: string;
          line1: string;
          line2: string;
          city: string;
          state: string;
          zipCode: string;
          country: string;
        };
        pickupLocation: string;
        pickupInstructions: string;
        pickupTimezone: string;
        pickupGeo: {
          lat: number;
          long: number;
        };
      }>
    | undefined
  > {
    return this._store.select(getOrderAddress);
  }

  getOrderPreferences(): Observable<
    | PartialNested<{
        laundryDetergent: string;
        laundryOptions: {
          delicate: boolean;
          hangDry: boolean;
          useHanger: boolean;
          additionalInstructions: string;
        };
      }>
    | undefined
  > {
    return this._store.select(getOrderPreferences);
  }

  getOrderPreferred(): Observable<
    | PartialNested<{
        preferredLp: string[];
        preferredPickup: string;
      }>
    | undefined
  > {
    return this._store.select(getOrderPreferred);
  }

  createDeviceInfo(): void {
    return this._store.dispatch(DeviceInfo());
  }

  emailLogin(email: string, password: string): void {
    return this._store.dispatch(LoginEmail({ email, password }));
  }

  checkLoginOptions(email: string): void {
    return this._store.dispatch(CheckEmail({ email }));
  }

  verifyAccountWeb(phoneNumber: string, isResend: boolean): void {
    return this._store.dispatch(VerifyAccountWeb({ phoneNumber, isResend }));
  }

  verifyAccountMobile(verifyId: string, phoneNumber: string): void {
    return this._store.dispatch(VerifyAccountMobile({ verifyId, phoneNumber }));
  }

  updateUser(payload: AuthState): void {
    return this._store.dispatch(
      UpdateAuthUser({
        payload: {
          user: Object.freeze({ ...payload.user } as User),
          error: payload.error,
          status: payload.status,
        },
      })
    );
  }

  linkPhone(phoneNumber: string): void {
    return this._store.dispatch(LinkPhone({ phoneNumber }));
  }

  confirmLinkPhone(confirmationCode: number): void {
    return this._store.dispatch(ConfirmLinkPhone({ confirmationCode }));
  }

  confirmPhoneLogin(verifyId: string, confirmationCode: string): void {
    return this._store.dispatch(ConfirmPhone({ verifyId, confirmationCode }));
  }

  adminUserLogin(token: string): void {
    return this._store.dispatch(LoginAdmin({ token }));
  }

  googleWebLogin(): void {
    return this._store.dispatch(LoginGoogleWeb());
  }

  googleMobileLogin(): void {
    return this._store.dispatch(LoginGoogleMobile());
  }

  isLoggedIn(user: User): void {
    return this._store.dispatch(
      LoggedIn({
        payload: {
          status: 'success',
          user: Object.freeze({ ...user }),
          error: null,
        },
      })
    );
  }

  presentLoading(msg: string): void {
    return this._store.dispatch(
      LoadingStarted({
        payload: {
          message: msg,
          spinner: 'circular',
          cssClass: 'poplin-loading',
          mode: 'ios',
          keyboardClose: true,
        },
      })
    );
  }

  dismissLoading(): void {
    return this._store.dispatch(LoadingFinished());
  }

  logout(): void {
    return this._store.dispatch(Logout());
  }

  updateCustomerDoc(
    doc: PartialNested<CustomerDocument>,
    showLoading: boolean
  ): void {
    return this._store.dispatch(
      DocUpdate({ payload: { doc: structuredClone(doc), showLoading } })
    );
  }

  getActiveCampaignCoupon(): Observable<UserDiscount | null> {
    return this._store.select(getActiveDiscount);
  }

  updateCampaignActiveCoupon(couponDetails: UserDiscount): void {
    return this._store.dispatch(
      DiscountUpdated({
        payload: {
          discount: couponDetails,
        },
      })
    );
  }

  closeAccount(showLoading: boolean): void {
    return this._store.dispatch(CloseAccount({ payload: { showLoading } }));
  }

  addProfileData(payload: PartialNested<CustomerAPI.Request.NewOrder>): void {
    return this._store.dispatch(ProfileDataAdded({ payload }));
  }

  addAddress(payload: PartialNested<CustomerAPI.Request.NewOrder>): void {
    return this._store.dispatch(
      AddressAdded({ payload: Object.freeze(structuredClone(payload)) })
    );
  }

  addCoverage(payload: PartialNested<CustomerAPI.Request.NewOrder>): void {
    return this._store.dispatch(
      CoverageAdded({ payload: Object.freeze(structuredClone(payload)) })
    );
  }

  addDeliverySpeed(payload: PartialNested<CustomerAPI.Request.NewOrder>): void {
    return this._store.dispatch(
      DeliveryAdded({ payload: Object.freeze(structuredClone(payload)) })
    );
  }

  addOversizedItems(
    payload: PartialNested<CustomerAPI.Request.NewOrder>
  ): void {
    return this._store.dispatch(
      OversizedItemsAdded({ payload: Object.freeze(structuredClone(payload)) })
    );
  }

  addPreferences(payload: PartialNested<CustomerAPI.Request.NewOrder>): void {
    return this._store.dispatch(
      PreferencesAdded({ payload: Object.freeze(structuredClone(payload)) })
    );
  }

  addBagSize(payload: PartialNested<CustomerAPI.Request.NewOrder>): void {
    return this._store.dispatch(
      BagSizeAdded({ payload: Object.freeze(structuredClone(payload)) })
    );
  }

  addPickupLocation(
    payload: PartialNested<CustomerAPI.Request.NewOrder>
  ): void {
    return this._store.dispatch(
      PickupAdded({ payload: Object.freeze(structuredClone(payload)) })
    );
  }

  addCredit(payload: PartialNested<CustomerAPI.Request.NewOrder>): void {
    return this._store.dispatch(
      CreditAdded({ payload: Object.freeze(structuredClone(payload)) })
    );
  }

  addCoupon(payload: PartialNested<CustomerAPI.Request.NewOrder>): void {
    return this._store.dispatch(
      CouponAdded({ payload: Object.freeze(structuredClone(payload)) })
    );
  }

  addSurgePrice(payload: CustomerAPI.Response.CustomerSurge): void {
    return this._store.dispatch(SurgePriceSet({ payload }));
  }

  addCommercialAccount(payload: boolean): void {
    return this._store.dispatch(CommercialAccountSet({ payload }));
  }

  addProtectionAccepted(payload: boolean): void {
    return this._store.dispatch(ProtectionAccepted({ payload }));
  }

  getProtectionAccepted(): Observable<boolean> {
    return this._store.select(getProtectionAccepted);
  }

  getNewOrderData(): Observable<
    Partial<PartialNested<CustomerAPI.Request.NewOrder>>
  > {
    return this._store.select(getNewOrderData);
  }

  getNewOrderStatus(): Observable<string> {
    return this._store.select(getNewOrderStatus);
  }

  getSurgePriceData(): Observable<number> {
    return this._store.select(getSurgePriceData);
  }

  getCommercialAccount(): Observable<boolean> {
    return this._store.select(getCommercialAccount);
  }

  getCredits(): Observable<number> {
    return this._store.select(getCredits);
  }

  placeOrder(): void {
    return this._store.dispatch(PlaceOrder());
  }

  resetOrder(): void {
    return this._store.dispatch(ResetOrder());
  }

  removeTransientOrder(): void {
    return this._store.dispatch(RemoveTransientOrder());
  }

  cancelPaymentIntent(): void {
    return this._store.dispatch(CancelPaymentIntent());
  }

  newOrderPaid(): void {
    return this._store.dispatch(NewOrderPaid());
  }

  addPreferredLps(
    payload: PartialNested<{
      preferredLp: string[];
      preferredLpNames: string[];
      preferredPickup: string;
    }>
  ): void {
    return this._store.dispatch(
      PreferredLPAdded({ payload: structuredClone(payload) })
    );
  }

  addProfileId(payload: PartialNested<CustomerProfileWithId>): void {
    return this._store.dispatch(ProfileIdAdded({ payload }));
  }

  addProfileName(payload: PartialNested<CustomerProfileWithId>): void {
    return this._store.dispatch(ProfileNameAdded({ payload }));
  }

  addProfileAddress(payload: PartialNested<CustomerProfileWithId>): void {
    return this._store.dispatch(ProfileAddressAdded({ payload }));
  }

  addProfilePickup(payload: PartialNested<CustomerProfileWithId>): void {
    return this._store.dispatch(ProfilePickupAdded({ payload }));
  }

  addProfileLaundryCare(payload: PartialNested<CustomerProfileWithId>): void {
    return this._store.dispatch(ProfileLaundryCareAdded({ payload }));
  }

  getNewProfileData(): Observable<PartialNested<CustomerProfileWithId>> {
    return this._store.select(getNewProfileData);
  }

  createNewProfile(): void {
    return this._store.dispatch(CreateProfile());
  }

  resetNewProfile(): void {
    return this._store.dispatch(ResetProfile());
  }

  updateProfile(): void {
    return this._store.dispatch(UpdateProfile());
  }

  duplicateProfile(): void {
    return this._store.dispatch(DuplicateProfile());
  }

  deleteProfile(payload: PartialNested<CustomerProfileWithId>): void {
    return this._store.dispatch(DeleteProfile({ payload }));
  }

  getLaundryPros(): Observable<LaundryProState> {
    return this._store.select(getLaundryPros);
  }

  getPastOrders(): Observable<PastOrdersState> {
    return this._store.select(getPastOrders);
  }

  updateLPList(): void {
    return this._store.dispatch(UpdatePreferredAndBlocked());
  }

  unblockLaundryPro(payload: CustomerAPI.Data.LaundryPro): void {
    return this._store.dispatch(LaundryProUnblocked({ payload }));
  }

  blockLaundryPro(payload: CustomerAPI.Data.LaundryPro): void {
    return this._store.dispatch(LaundryProBlocked({ payload }));
  }

  arrangePreferredList(payload: CustomerAPI.Data.LaundryPro[]): void {
    return this._store.dispatch(ArrangePreferredList({ payload }));
  }

  addTrustAndSafetyFee(
    payload: PartialNested<CustomerAPI.Request.NewOrder>
  ): void {
    return this._store.dispatch(FeesAdded({ payload }));
  }

  addRepeatOrder(payload: PartialNested<CustomerAPI.Request.NewOrder>): void {
    return this._store.dispatch(RepeatOrder({ payload }));
  }

  addPriceInformation(
    payload: PartialNested<CustomerAPI.Request.NewOrder>
  ): void {
    return this._store.dispatch(PriceInformationAdded({ payload }));
  }

  reloadLaundryPros(): void {
    return this._store.dispatch(RateAndTipOrder());
  }
}
