<ion-router-outlet></ion-router-outlet>
<app-customer-footer type="nav">
  <ul class="nav-footer-tabs">
    <li
      *ngFor="let navItem of navItems; trackBy: trackNavItems"
      [ngClass]="{
        active: navItem.isActive,
        'referrals-tab': navItem.label === 'Referrals'
      }"
    >
      <a (click)="navigateTo($event, navItem.route)">
        <span
          ><poplin-icon
            name="{{ navItem.icon }}"
            color="--footer-nav-icon-color"
            [size]="32"
          ></poplin-icon
        ></span>
        <span>{{ navItem.label }}</span>
      </a>
    </li>
  </ul>
</app-customer-footer>
