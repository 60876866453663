import { inject, Injectable } from '@angular/core';
import { Capacitor } from '@capacitor/core';
import { environment } from '@environments/environment';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Segment } from '@sudshare/capacitor-segment';
import { SegmentEventType } from '@sudshare/custom-node-package';
import { take } from 'rxjs';
import { LoggingService } from '../logging/logging.service';
import { StoreService } from '../store/store.service';

const LOG_TAG = '[AnalyticsService]';

@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class AnalyticsService {
  private _logger = inject(LoggingService);
  private _store = inject(StoreService);

  init(): void {
    let segmentKey: string;
    switch (Capacitor.getPlatform()) {
      case 'android':
        segmentKey = environment.segment.andKey;
        break;
      case 'ios':
        segmentKey = environment.segment.iosKey;
        break;
      default:
        segmentKey = environment.segment.webKey;
        break;
    }

    Segment.initialize({
      key: segmentKey,
      trackLifecycle: true,
      trackDeepLinks: true,
      // Skipping for IOS as it is already initialized in AppDelegate
      skipSetup: Capacitor.getPlatform() === 'ios',
    }).catch((e) => {
      this._logger.logWarning(LOG_TAG, e.message);
    });
  }

  public identifyUser(
    userId: string,
    eventData: Record<string, unknown>
  ): Promise<void> {
    return Segment.identify({
      userId,
      traits: {
        userId: userId,
        platform: Capacitor.getPlatform(),
        appVersion: environment.version,
        ...eventData,
      },
    });
  }

  public trackEvent(
    userId: string,
    eventName: SegmentEventType | string,
    eventData: Record<string, unknown>
  ): Promise<void> {
    return Segment.track({
      eventName: eventName,
      properties: {
        userId: userId,
        platform: Capacitor.getPlatform(),
        appVersion: environment.version,
        ...eventData,
      },
    });
  }

  public createTrackEvent(
    eventName: SegmentEventType | string,
    data?: Record<string, unknown>,
    ids?: string[]
  ): void {
    this._store
      .getAccountDetails()
      .pipe(untilDestroyed(this), take(1))
      .subscribe(async (user) => {
        if (user) {
          const customIds = ids ? ids : [];
          const eventPayload = {
            userType: 'Customer',
            email: user.email,
            timestamp: Math.floor(+new Date()),
            trackingEnabled: user.deviceTracking,
            statsigCustomIDs: ['customerId', user.userId, ...customIds],
            ...data,
          };
          this.trackEvent(user.userId, eventName, eventPayload);
        }
      })
      .unsubscribe();
  }
}
