<div class="cancel-container">
  <p>Why do you want to cancel your order?</p>
  <ion-radio-group>
    <poplin-radio-button
      *ngFor="let option of cancelReasons; trackBy: trackReasons"
      [parentForm]="cancelOrderForm"
      formControlName="reason"
      ngDefaultControl
      [label]="option.reason"
      [value]="option.reason"
    ></poplin-radio-button>
  </ion-radio-group>
  <poplin-textarea
    *ngIf="cancelOrderForm.value.reason === 'Other'"
    formControlName="otherReason"
    [parentForm]="cancelOrderForm"
    value=""
    label="Enter your reason here"
    placeholder=""
  ></poplin-textarea>
  <div class="button-container">
    <poplin-button
      id="cancel-order-button"
      label="Confirm and cancel order"
      [isDisabled]="
        !this.cancelOrderForm.value.reason ||
        (this.cancelOrderForm.value.reason === 'Other' &&
          !this.cancelOrderForm.value.otherReason)
      "
      (click)="cancelOrder()"
    ></poplin-button>
    <poplin-button
      id="cancel-order-close-button"
      label="Back"
      fill="outline"
      (click)="closeModal()"
    ></poplin-button>
  </div>
</div>
