import { inject, Injectable } from '@angular/core';
import { NavigationExtras } from '@angular/router';
import { NavController } from '@ionic/angular/standalone';

@Injectable({
  providedIn: 'root',
})
export class NavigationService {
  private _navCtrl = inject(NavController);

  // Navigation Functions
  navRoot = (
    route: string,
    options?: Record<string, any>
  ): Promise<boolean> => {
    return this._navCtrl.navigateRoot(route, {
      animated: options?.['animated'],
      animationDirection: options?.['direction'],
    });
  };

  navRootWithOptions = (
    route: string,
    data: Record<string, any>
  ): Promise<boolean> => {
    const options: NavigationExtras = {
      state: data,
    };
    return this._navCtrl.navigateRoot(route, options);
  };

  forward = (route: string | any[]): Promise<boolean> => {
    const finalRoute = Array.isArray(route) ? route : [route];

    return this._navCtrl.navigateForward(finalRoute);
  };

  forwardWithOptions = (
    route: string | any[],
    data: Record<string, any>,
    queryParams?: Record<string, any>,
    navigationExtras: NavigationExtras = {}
  ): Promise<boolean> => {
    const finalRoute = Array.isArray(route) ? route : [route];
    const options: NavigationExtras = {
      state: data,
      ...navigationExtras,
    };

    if (queryParams) {
      options.queryParams = queryParams;
    }

    return this._navCtrl.navigateForward(finalRoute, options);
  };

  back = (): void => {
    this._navCtrl.back();
  };

  backWithOptions = (
    route: string | any[],
    data: Record<string, any>
  ): Promise<boolean> => {
    const finalRoute = Array.isArray(route) ? route : [route];
    const options: NavigationExtras = {
      state: data,
    };

    return this._navCtrl.navigateBack(finalRoute, options);
  };
}
