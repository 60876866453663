import { ErrorHandler, inject, Injectable, Injector } from '@angular/core';
import * as Rollbar from 'rollbar';
import { DialogService } from '../dialog/dialog.service';
import { LoggingService } from '../logging/logging.service';
import { RollbarService } from '../rollbar/rollbar.service';
import { BaseError } from './base-error';

const LOG_TAG = '[ErrorHandlingService]';

@Injectable({
  providedIn: 'root',
})
export class ErrorHandlingService implements ErrorHandler {
  private _injector = inject(Injector);
  private _logger = inject(LoggingService);
  private _rollbar = inject(RollbarService);
  private isModalVisible = false;

  handleError(error: unknown): void {
    this.handle(error);
  }

  private async handle(error: unknown): Promise<void> {
    try {
      if (this.isModalVisible) {
        this._logger.logError(
          LOG_TAG,
          'Dismissed error while another error modal is shown:',
          error
        );

        return;
      }

      this.handleErrorAlert(error);
    } catch (errorHandlerError) {
      this.isModalVisible = false;
      this._logger.logError(LOG_TAG, 'Internal Exception:', errorHandlerError);
      this._rollbar.error(errorHandlerError as Rollbar.LogArgument);
    }
  }

  private handleErrorAlert(error: any): void {
    let message =
      'A note has been sent to our team to investigate. Please carry on!';

    const isKnownError =
      (error instanceof Error && 'rejection' in error) ||
      (error instanceof BaseError && error.name !== 'UNKNOWN_ERROR');

    if (isKnownError) {
      message = (error as any).rejection?.message || error.message;
      this.showErrorAlert(message);
    } else {
      this.showErrorAlert(message);
    }

    this._logger.logError(
      LOG_TAG,
      `[${error.name}] Error: ${error.message}; stack: ${error.stack}; status: ${error.statusCode}`
    );
    this._rollbar.error(error);
  }

  private showErrorAlert(message: string): void {
    this.isModalVisible = true;
    const dialogService: DialogService =
      this._injector.get<DialogService>(DialogService);

    dialogService.showCustomToast({
      showHeading: true,
      notificationHeading: 'Oops! Something went wrong.',
      text: message,
      showLinkCTA: true,
      linkCTAText: 'Dismiss',
      notificationType: 'error',
      showNotificationIcon: true,
      notificationIcon: 'error_filled',
    });
  }
}
