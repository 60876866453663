import { Action, createReducer, on } from '@ngrx/store';
import { CustomerAPI } from '@sudshare/custom-node-package';
import { StoreStatusOptions, STORE_STATUS } from '@utils';
import {
  ArrangePreferredList,
  GetLaundryPros,
  LaundryProBlocked,
  LaundryProUnblocked,
} from './laundry-pro.actions';

export interface LaundryProState {
  laundryProData: CustomerAPI.Response.PreferredAndBlockedLaundryPros | null;
  error: string | null;
  status: STORE_STATUS;
}

const initialState: LaundryProState = {
  laundryProData: {
    preferred: [
      {
        id: '',
        name: '',
        ratingOfLastOrder: 0,
        order: 0,
        lastCompleted: '',
      },
    ],
    blocked: [
      {
        id: '',
        name: '',
        ratingOfLastOrder: 0,
        order: 0,
        lastCompleted: '',
      },
    ],
  },
  error: null,
  status: StoreStatusOptions.PENDING,
};

const laundryProReducer = createReducer(
  initialState,

  on(GetLaundryPros, (state, { payload }) => ({
    ...state,
    status: StoreStatusOptions.SUCCESS,
    laundryProData: payload.laundryProData,
  })),

  on(ArrangePreferredList, (state, { payload }) => ({
    ...state,
    laundryProData: {
      preferred: payload,
      blocked: [...(<[]>state.laundryProData?.blocked)],
    },
  })),

  on(LaundryProUnblocked, (state, { payload }) => ({
    ...state,
    laundryProData: {
      preferred: [...(<[]>state.laundryProData?.preferred), payload],
      blocked: [
        ...(<[]>(
          state.laundryProData?.blocked?.filter((lp) => lp.id !== payload.id)
        )),
      ],
    },
  })),

  on(LaundryProBlocked, (state, { payload }) => ({
    ...state,
    laundryProData: {
      blocked: [...(<[]>state.laundryProData?.blocked), payload],
      preferred: [
        ...(<[]>(
          state.laundryProData?.preferred?.filter((lp) => lp.id !== payload.id)
        )),
      ],
    },
  }))
);

export function LaundryProReducer(
  state: LaundryProState | undefined,
  action: Action
): LaundryProState {
  return laundryProReducer(state, action);
}
