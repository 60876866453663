import { DatePipe } from '@angular/common';
import { inject, Injectable } from '@angular/core';
import {
  convertToDollars,
  CustomerPricing,
  formatCurrency,
  OrderDocumentWithId,
  OrderStatus,
  OutstandingOrdersWithId,
  PartialNested,
} from '@sudshare/custom-node-package';
import { formatTimes, FormatTimestampOptions, OrderStatusCard } from '@utils';
import { formatDistanceToNow } from 'date-fns';

export enum OrderStatusButtonRoutes {
  MESSAGES = '/messaging',
  PAST_ORDERS = '/past-orders',
  RATE_TIP = '/rate-tip',
  DETAILS = '/order-details',
  ADJUST_BILLING = '/past-orders',
}

@Injectable({
  providedIn: 'root',
})
export class OrderDetailsService {
  private _datePipe = inject(DatePipe);

  generateCard(
    order: OutstandingOrdersWithId | OrderDocumentWithId,
    orderDoc: boolean,
    detailsPage?: boolean
  ): OrderStatusCard {
    let card: OrderStatusCard = {} as OrderStatusCard;
    card.orderId = order.OrderId ?? order.Id;
    card.orderUUID = order.Id;
    card.orderStatusNumber = order.OrderStatusNumber;

    let orderDateTime;
    let orderDeliveryDateTime;
    if (orderDoc) {
      order = order as OrderDocumentWithId;
      if (order.SudsterName) {
        card.laundryProName = order.SudsterName;
      }
      orderDateTime = new Date(order.UnixTimeStamp);
      if (card.orderStatusNumber === OrderStatus.OrderDelivered) {
        orderDeliveryDateTime = new Date(
          order.StatusHistoryInfo.Delivered.UnixTime
        );
      } else {
        orderDeliveryDateTime = new Date(order.DeliveryDeadline);
      }
    } else {
      order = order as OutstandingOrdersWithId;
      if (order.SudsterFirstName) {
        card.laundryProName = order.SudsterFirstName;
      }
      orderDateTime = new Date(order.OrderPlacedTimestamp);
      orderDeliveryDateTime = new Date(order.DeliveryDeadline);
    }
    let orderDate = '';
    if (orderDoc) {
      order = order as OrderDocumentWithId;

      orderDate = formatTimes(
        orderDateTime,
        order.Timezone,
        FormatTimestampOptions.PLACED
      );
    } else {
      order = order as OutstandingOrdersWithId;
      orderDate = formatTimes(
        orderDateTime,
        order.OrderTimezone,
        FormatTimestampOptions.PLACED
      );
    }
    let deliveryTimestamp = '';
    if (card.orderStatusNumber === OrderStatus.OrderDelivered) {
      order = order as OrderDocumentWithId;
      const timeAgo = formatDistanceToNow(orderDeliveryDateTime, {
        addSuffix: true,
      });
      const hour = formatTimes(
        orderDeliveryDateTime,
        order.Timezone,
        FormatTimestampOptions.TIME_AGO
      );
      deliveryTimestamp = `${timeAgo}, ${hour}`;
    } else {
      order = order as OutstandingOrdersWithId;
      deliveryTimestamp = formatTimes(
        orderDeliveryDateTime,
        order.OrderTimezone,
        FormatTimestampOptions.DAY_OF_WEEK
      );
    }
    if (orderDoc) {
      this.buildRatedOrder(
        card,
        order as OrderDocumentWithId,
        orderDate,
        deliveryTimestamp
      );
    } else {
      switch (card.orderStatusNumber) {
        case OrderStatus.OrderPlaced:
          card = this.buildPlacedOrder(
            order,
            card,
            orderDate,
            deliveryTimestamp,
            orderDoc,
            detailsPage
          );
          break;
        case OrderStatus.OrderDelivered:
          card = this.buildCompletedOrder(card, order.Id);
          break;
        default:
          card = this.buildInProgressOrder(
            order,
            card,
            orderDate,
            deliveryTimestamp,
            orderDoc,
            detailsPage
          );
          break;
      }
    }

    return card;
  }

  private buildPlacedOrder(
    order: OutstandingOrdersWithId | OrderDocumentWithId,
    card: OrderStatusCard,
    orderDate: string,
    deliveryTimestamp: string,
    orderDoc: boolean,
    detailsPage?: boolean
  ): OrderStatusCard {
    card.tag = 'order placed';
    card.title = 'Searching for a Laundry Pro';
    card.orderState = 'placed';
    card.hideBeads = true;
    card.detailHeading = `Order #${card.orderId}`;
    card.details = [
      {
        symbol: 'ticket_custom',
        text: `<strong>Ordered</strong> ${orderDate}`,
      },
      {
        symbol: 'directions_car',
        text: `<strong>Delivery</strong> by ${deliveryTimestamp}`,
      },
    ];
    if (!detailsPage) {
      card.footerButtons = [
        {
          color: 'monochrome',
          label: 'More Details',
          fill: 'outline',
          id: 'primary-footer-button--default',
        },
      ];

      card.primaryButtonRoute = OrderStatusButtonRoutes.DETAILS;
    }

    let placedDiff;
    if (orderDoc) {
      order = order as OrderDocumentWithId;
      placedDiff = Math.floor(
        Math.abs(
          new Date().getTime() - new Date(order.UnixTimeStamp).getTime()
        ) / 60000
      );
    } else {
      order = order as OutstandingOrdersWithId;
      placedDiff = Math.floor(
        Math.abs(
          new Date().getTime() - new Date(order.OrderPlacedTimestamp).getTime()
        ) / 60000
      );
    }

    if (placedDiff >= 60) {
      card.specialMessage = {
        text: 'Thank you for your patience. It can sometimes take up to an hour for a Laundry Pro to accept your order.',
        dismissable: false,
      };
    } else {
      card.specialMessage = {
        text: 'Your Laundry Pro will message you soon (usually within 10-60 minutes) with an estimated pickup time.',
        dismissable: false,
      };
    }

    card.primaryButtonRoute = OrderStatusButtonRoutes.DETAILS;

    return card;
  }

  private buildInProgressOrder(
    order: PartialNested<OutstandingOrdersWithId>,
    card: OrderStatusCard,
    orderDate: string,
    deliveryTimestamp: string,
    orderDoc: boolean,
    detailsPage?: boolean
  ): OrderStatusCard {
    card.tag = 'In-Progress';
    card.detailHeading = `Order #${card.orderId}`;
    card.hideBeads = false;
    card.showRatingPrompt = false;
    card.details = [
      {
        symbol: 'ticket_custom',
        text: `<strong>Ordered</strong> ${orderDate}`,
      },
      {
        symbol: 'directions_car',
        text: `<strong>Delivery</strong> by ${deliveryTimestamp}`,
      },
      {
        symbol: 'person',
        text: `<strong>Laundry Pro</strong> ${order.SudsterFirstName}`,
      },
    ];
    if (detailsPage) {
      card.footerButtons = [
        {
          color: 'primary',
          label: 'Message',
          fill: 'solid',
          showIcon: true,
          icon: 'comment_custom',
          id: 'primary-footer-button--default',
        },
      ];
    } else {
      card.footerButtons = [
        {
          color: 'monochrome',
          label: 'Details',
          fill: 'clear',
          id: 'secondary-footer-button--default',
        },
        {
          color: 'primary',
          label: 'Message',
          fill: 'solid',
          showIcon: true,
          icon: 'comment_custom',
          id: 'primary-footer-button--default',
        },
      ];
    }
    if (!orderDoc) {
      if (order.Messages?.MessageBadge && order.Messages.MessageBadge > 0) {
        if (detailsPage) {
          card.footerButtons[0].label = `${order.Messages.MessageBadge} New ${
            order.Messages.MessageBadge > 1 ? 'Messages' : 'Message'
          }`;
        } else {
          card.footerButtons[1].label = `${order.Messages.MessageBadge} New  ${
            order.Messages.MessageBadge > 1 ? 'Messages' : 'Message'
          }`;
        }
      }
    }

    card.primaryButtonRoute = OrderStatusButtonRoutes.MESSAGES;
    card.secondaryButtonRoute = `${OrderStatusButtonRoutes.DETAILS}/${order.Id}`;

    if (card.orderStatusNumber === OrderStatus.OrderAccepted) {
      card.title = `${order.SudsterFirstName} will pick up your order soon`;
      card.orderState = 'accepted';
      card.wizardState = 1;
    } else if (card.orderStatusNumber === OrderStatus.OrderPickedUp) {
      card.title = `${order.SudsterFirstName} picked up your laundry`;
      card.orderState = 'picked-up';
      card.wizardState = 2;
    } else if (card.orderStatusNumber === OrderStatus.OrderWashed) {
      card.title = `${order.SudsterFirstName} is washing, drying, and folding your laundry`;
      card.orderState = 'washing';
      card.wizardState = 2;
    } else if (card.orderStatusNumber === OrderStatus.OrderWeighed) {
      card.title = 'Your laundry is packed and ready for delivery';
      card.orderState = 'finished';
      card.wizardState = 3;
    }

    if (order.PaymentDeclined && card.footerButtons) {
      card.footerButtons[1] = {
        color: 'primary',
        label: 'Resolve',
        fill: 'solid',
        id: 'primary-footer-button--default',
      };
      card.orderState = 'alert';
      card.specialMessage = {
        icon: 'info_filled',
        iconColor: '--bead-color-alert',
        text: 'Your payment was declined. This must be resolved before we can deliver.',
        dismissable: false,
      };
      card.primaryButtonRoute = '/laundry';
    }
    card.secondaryButtonRoute = `${OrderStatusButtonRoutes.DETAILS}/${order.Id}`;

    return card;
  }

  private buildCompletedOrder(
    card: OrderStatusCard,
    orderId: string
  ): OrderStatusCard {
    card.tag = 'complete';
    card.title = 'Your laundry was delivered';
    card.orderState = 'complete';
    card.hideBeads = true;
    card.showRatingPrompt = true;
    card.specialMessage = null;
    card.footerButtons = [
      {
        color: 'monochrome',
        label: 'Details',
        fill: 'clear',
        id: 'secondary-footer-button--default',
      },
      {
        color: 'primary',
        label: 'Leave a Rating',
        fill: 'solid',
        id: 'primary-footer-button--default',
      },
    ];
    card.primaryButtonRoute = OrderStatusButtonRoutes.RATE_TIP;
    card.secondaryButtonRoute = `${OrderStatusButtonRoutes.DETAILS}/${orderId}`;
    return card;
  }

  private buildRatedOrder(
    card: OrderStatusCard,
    order: OrderDocumentWithId,
    orderDate: string,
    deliveryDate: string
  ) {
    card.tag = `ORDER #${card.orderId}`;
    card.hideBeads = true;
    card.historyState = true;
    card.showRatingPrompt = false;
    card.laundryProName = order.SudsterName;
    card.rating = order.Rating.Number as 1 | 2 | 3 | 4 | 5;
    card.title = this.formatDeliveryDate(
      order.StatusHistoryInfo.Delivered.UnixTime
    );

    card.orderState = 'complete';
    card.specialMessage = null;
    const defaultPricing = order.SameDayService
      ? CustomerPricing.sameDayRate
      : CustomerPricing.nextDayRate;
    const pricePerPound = order.PriceSchedule
      ? order.SameDayService
        ? order.PriceSchedule?.ExpressRate
        : order.PriceSchedule?.StandardRate
      : defaultPricing;

    card.details = [
      {
        symbol: 'ticket_custom',
        text: `<strong>Ordered</strong> ${orderDate}`,
      },
      {
        symbol: 'directions_car',
        text: `<strong>Delivered</strong> ${deliveryDate}`,
      },
      {
        symbol: 'person',
        text: `<strong>Laundry Pro</strong> ${order.SudsterName}`,
      },
      {
        symbol: 'scale',
        text: `<strong>Weight</strong> ${order.StatusHistoryInfo.Done.TotalWeight}lbs ($${pricePerPound}/pound)`,
      },
      {
        symbol: 'dollar_custom',
        text: `<strong>Price</strong> $${formatCurrency(
          convertToDollars(order.CardCharged)
        )} ${
          order.TipProfit
            ? `(+$${formatCurrency(convertToDollars(order.TipProfit))} tip)`
            : ''
        }`,
      },
    ];

    if (!card.rating) {
      card.showRatingPrompt = true;
      card.footerButtons = [
        {
          color: 'primary',
          label: 'Leave a Rating',
          fill: 'solid',
          id: 'primary-footer-button--default',
        },
      ];
      card.primaryButtonRoute = OrderStatusButtonRoutes.RATE_TIP;
    }

    return card;
  }

  private formatDeliveryDate(time: number): string {
    return this._datePipe.transform(new Date(time), 'MMM d, y') || '';
  }
}
