import { Injectable, Injector, inject } from '@angular/core';
import { LoadingOptions } from '@ionic/angular/standalone';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { DialogService } from '@services';
import { Observable, concatMap } from 'rxjs';
import { LoadingActionTypes } from './loading.actions';

@Injectable()
export class LoadingEffects {
  private _actions = inject(Actions);
  private _injector = inject(Injector);

  activeLoadingQueue!: HTMLIonLoadingElement;

  showLoading$ = createEffect(
    (): Observable<void> => {
      return this._actions.pipe(
        ofType(LoadingActionTypes.LoadingStarted),
        concatMap(async ({ payload }) => {
          this.activeLoadingQueue = await this.showLoadingModal(payload);
        })
      );
    },
    { dispatch: false }
  );

  finishLoading$ = createEffect(
    (): Observable<void> => {
      return this._actions.pipe(
        ofType(LoadingActionTypes.LoadingFinished),
        concatMap(async () => {
          this.activeLoadingQueue.dismiss();
        })
      );
    },
    { dispatch: false }
  );

  private async showLoadingModal(
    opts: LoadingOptions
  ): Promise<HTMLIonLoadingElement> {
    const dialogService: DialogService =
      this._injector.get<DialogService>(DialogService);
    return await dialogService.showLoading(opts);
  }
}
