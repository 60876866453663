import { createAction, props } from '@ngrx/store';
import { CustomerAPI } from '@sudshare/custom-node-package';
import { LaundryProState } from './laundry-pro.reducer';

export enum LaundryProActionTypes {
  GetLaundryPros = '[Laundry Pro] Getting Laundry Pros',
  ArrangeLaundryPros = '[Laundry Pro] Arrange Preferred List',
  LaundryProUnblocked = '[Laundry Pro] Laundry Pro Unblocked',
  LaundryProBlocked = '[Laundry Pro] Laundry Pro Blocked',
  UpdatePreferredAndBlocked = '[Laundry Pro] Lists Updated',
}
export const GetLaundryPros = createAction(
  LaundryProActionTypes.GetLaundryPros,
  props<{ payload: LaundryProState }>()
);

export const LaundryProUnblocked = createAction(
  LaundryProActionTypes.LaundryProUnblocked,
  props<{ payload: CustomerAPI.Data.LaundryPro }>()
);

export const LaundryProBlocked = createAction(
  LaundryProActionTypes.LaundryProBlocked,
  props<{ payload: CustomerAPI.Data.LaundryPro }>()
);

export const UpdatePreferredAndBlocked = createAction(
  LaundryProActionTypes.UpdatePreferredAndBlocked
);

export const ArrangePreferredList = createAction(
  LaundryProActionTypes.ArrangeLaundryPros,
  props<{ payload: CustomerAPI.Data.LaundryPro[] }>()
);
