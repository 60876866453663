import { createSelector } from '@ngrx/store';
import { StoreState } from '../store.state';
import { DeviceState } from './device.reducer';

const deviceState = (state: StoreState) => state.device;

export const getDeviceId = createSelector(
  deviceState,
  (state: DeviceState) => state.deviceId || ''
);
