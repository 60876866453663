import { Injectable, inject } from '@angular/core';
import { Capacitor, Plugin } from '@capacitor/core';
import { environment } from '@environments/environment';
import { IterablePlugin } from '@sudshare/capacitor-iterable';
import { DeepLinkService } from '../deep-link/deep-link.service';

@Injectable({
  providedIn: 'root',
})
export class IterableService {
  private _deepLinkService = inject(DeepLinkService);

  async init(userId: string): Promise<void> {
    switch (Capacitor.getPlatform()) {
      case 'ios':
        await IterablePlugin.initialize({
          apiKey: environment.iterable.iosKey,
          packageName: environment.iterable.iosPackage,
          userId: userId,
        });
        break;
      case 'android':
        await IterablePlugin.initialize({
          apiKey: environment.iterable.andKey,
          packageName: environment.iterable.andPackage,
          userId: userId,
        });
        break;
      default:
        await IterablePlugin.initialize({
          apiKey: environment.iterable.webKey,
          packageName: environment.externalLinks.webApp,
          userId: userId,
        });
        break;
    }
    (IterablePlugin as unknown as Plugin).addListener(
      'IterableInAppOnUrl',
      (data) => {
        this._deepLinkService.handleUrl(data.url, false);
      }
    );
  }

  async updateToken(token: string): Promise<void> {
    await IterablePlugin.updateToken({ token });
  }

  async updateUser(info: Record<string, any>): Promise<void> {
    return await IterablePlugin.updateUserProfile({ userData: info });
  }

  trackPush(
    templateId: number,
    campaignId: number,
    messageId: string,
    appWasRunning: boolean
  ): Promise<string> {
    return IterablePlugin.trackPush({
      templateId,
      campaignId,
      msgId: messageId,
      appWasRunning,
    });
  }
}
