import { inject, Injectable, Injector, NgZone } from '@angular/core';
import { PaymentSheetEventsEnum, Stripe } from '@capacitor-community/stripe';
import { Capacitor, PluginListenerHandle } from '@capacitor/core';
import { ModalController } from '@ionic/angular/standalone';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import {
  AnalyticsService,
  ApiService,
  DialogService,
  NavigationService,
  StoreService,
  StripeService,
} from '@services';
import { PaymentIntentResult } from '@stripe/stripe-js';
import {
  CustomerAPI,
  extractStatusCode,
  SegmentEventType,
} from '@sudshare/custom-node-package';
import {
  CANCEL_TRANSIENT_ORDER_ENDPOINT,
  HttpBusinessError,
  loadCredits,
  NEW_ORDER,
  OUTSTANDING_ORDER_ENDPOINT,
  PaymentIntentStatus,
  presentSuccessModal,
  STRIPE_CANCEL_INTENT,
  STRIPE_CREATE_INTENT,
  STRIPE_INVOICE,
} from '@utils';
import { StripeWebService } from 'app/services/stripe/stripe.webservice';
import { deleteUTMValuesCookie } from 'app/utils/cookie-tracking';
import { customAlphabet } from 'nanoid';
import { Observable, of, switchMap, take, withLatestFrom } from 'rxjs';
import { NewOrdersStateActionTypes } from './new-orders.actions';

const PREVENT_UI_FLICKER_DELAY = 3000;

@Injectable()
export class NewOrdersEffects {
  private _actions = inject(Actions);
  private _storeService = inject(StoreService);
  private _apiService = inject(ApiService);
  private _stripeService = inject(StripeService);
  private _stripeWebService = inject(StripeWebService);
  private _navService = inject(NavigationService);
  private _injector = inject(Injector);
  private _zone = inject(NgZone);
  private _analytics = inject(AnalyticsService);
  private stripeListeners: PluginListenerHandle[] = [];
  private _modalCtrl = inject(ModalController);

  private internalOrderId = '';
  private paymentId = '';
  private externalId: string | null = null;
  private outstandingOrder: CustomerAPI.Request.OutstandingOrder | undefined;

  placeNewOrders$ = createEffect(() =>
    this._actions.pipe(
      ofType(NewOrdersStateActionTypes.PlaceOrder),
      withLatestFrom(
        this._storeService.getNewOrderData(),
        this._storeService.getCommercialAccount()
      ),
      switchMap(([, orderRequest, isCommercial]) => {
        if (!this.externalId) {
          this.generateOrderId();
        }

        const orderReqBody = {
          ...orderRequest,
          order: {
            ...orderRequest.order,
            orderId: this.externalId,
          },
        } as CustomerAPI.Request.NewOrder;

        this.placeOrderOne(orderReqBody, isCommercial);
        this.externalId = null;
        return new Observable<Action>();
      })
    )
  );

  trackPickupAdded$ = createEffect(
    (): Observable<boolean> =>
      this._actions.pipe(
        ofType(NewOrdersStateActionTypes.PickupAdded),
        switchMap(() => {
          this.createTrackEvent(SegmentEventType.PickupLocation);
          return of(true);
        })
      ),
    { dispatch: false }
  );

  trackServiceSpeed$ = createEffect(
    (): Observable<boolean> =>
      this._actions.pipe(
        ofType(NewOrdersStateActionTypes.DeliveryAdded),
        switchMap(() => {
          this.createTrackEvent(SegmentEventType.ServiceSpeed);
          return of(true);
        })
      ),
    { dispatch: false }
  );

  trackLaundryCare$ = createEffect(
    (): Observable<boolean> =>
      this._actions.pipe(
        ofType(NewOrdersStateActionTypes.PreferencesAdded),
        switchMap(() => {
          this.createTrackEvent(SegmentEventType.LaundryCare);
          return of(true);
        })
      ),
    { dispatch: false }
  );

  trackBagCount$ = createEffect(
    (): Observable<boolean> =>
      this._actions.pipe(
        ofType(NewOrdersStateActionTypes.BagSizeAdded),
        switchMap(() => {
          this.createTrackEvent(SegmentEventType.BagCount);
          return of(true);
        })
      ),
    { dispatch: false }
  );

  trackOversizeAdded$ = createEffect(
    (): Observable<boolean> =>
      this._actions.pipe(
        ofType(NewOrdersStateActionTypes.OversizedItemsAdded),
        switchMap(() => {
          this.createTrackEvent(SegmentEventType.OversizedItems);
          return of(true);
        })
      ),
    { dispatch: false }
  );

  trackPreferredLpAdded$ = createEffect(
    (): Observable<boolean> =>
      this._actions.pipe(
        ofType(NewOrdersStateActionTypes.PreferredLPAdded),
        switchMap(() => {
          this.createTrackEvent(SegmentEventType.PreferredLaundryPro);
          return of(true);
        })
      ),
    { dispatch: false }
  );

  trackCoverageAdded$ = createEffect(
    (): Observable<boolean> =>
      this._actions.pipe(
        ofType(NewOrdersStateActionTypes.CoverageAdded),
        switchMap(() => {
          this.createTrackEvent(SegmentEventType.Coverage);
          return of(true);
        })
      ),
    { dispatch: false }
  );

  removeTransientOrder$ = createEffect(
    (): Observable<Action> =>
      this._actions.pipe(
        ofType(NewOrdersStateActionTypes.RemoveTransientOrder),
        switchMap(() => {
          this.deleteTransientOrder(false);
          return new Observable<Action>();
        })
      )
  );

  cancelPaymentIntent$ = createEffect(
    (): Observable<Action> =>
      this._actions.pipe(
        ofType(NewOrdersStateActionTypes.CancelPaymentIntent),
        switchMap(() => {
          this.deletePaymentIntent();
          return new Observable<Action>();
        })
      )
  );

  private placeOrderOne(
    req: CustomerAPI.Request.NewOrder,
    isCommercial: boolean
  ): void {
    this._apiService
      .post<CustomerAPI.Response.NewOrder>(NEW_ORDER, req, {
        customLoadingText: 'Activating Poplin magic',
      })
      .subscribe({
        next: ({ statusCode, data }) => {
          if (statusCode === 201) {
            this.internalOrderId = data.internalOrderId;
            if (isCommercial) {
              this.generateOutstandingOrder(data, req);
              this.placeOrderCommercial(
                req.order.orderId,
                data.internalOrderId,
                data.preAuth
              );
            } else {
              this.subscribeStripeListeners();
              this.generateOutstandingOrder(data, req);
              this.placeOrderTwo(
                req.order.orderId,
                data.internalOrderId,
                data.preAuth
              );
            }
          }
        },
        error: (err: Error) => {
          const statusCode = extractStatusCode(err);
          if (
            statusCode === HttpBusinessError.unprocessableSurgeChargeAvailable
          ) {
            const regexMatch = err.message.match(/Surge: (\d+)/);
            const surgePrice = regexMatch ? parseInt(regexMatch[1]) : 0;
            this._storeService.addSurgePrice({
              hasSurge: surgePrice > 0,
              surgeAmount: surgePrice,
            });
          } else if (statusCode === HttpBusinessError.conflictDuplicatedOrder) {
            setTimeout(() => {
              this.unsubscribeStripeListeners();
              this.completeOrder();
            }, PREVENT_UI_FLICKER_DELAY);
          } else {
            this.showErrorAlert(err.message);
          }
        },
        complete: () => {
          return;
        },
      });
  }

  private placeOrderTwo(
    orderId: string,
    internalOrderId: string,
    orderAmount: number
  ): void {
    const stripeReqBody = {
      orderId: orderId,
      internalOrderId: internalOrderId,
      amount: orderAmount,
      isPreAuth: true,
      paymentRetry: false,
    };

    this._apiService
      .post<CustomerAPI.Response.StripeIntent>(
        STRIPE_CREATE_INTENT,
        stripeReqBody,
        { customLoadingText: 'Creating pre-auth' }
      )
      .subscribe({
        next: async ({ statusCode, data }) => {
          if (statusCode === 200) {
            this.paymentId = data.paymentIntent;
            if (Capacitor.isNativePlatform()) {
              await this.showStripePayment(
                data.stripeId,
                data.paymentIntent,
                data.ephemeralKey
              );
            } else {
              this._stripeWebService.paymentResult$.subscribe({
                next: async (result: PaymentIntentResult) => {
                  if (
                    result?.paymentIntent?.status ===
                    PaymentIntentStatus.SUCCESS
                  ) {
                    this.externalId = null;
                  }
                },
              });
              await this._stripeWebService.handleWebPayment(
                data.stripeId,
                data.paymentIntent,
                data.ephemeralKey,
                orderId,
                internalOrderId,
                orderAmount,
                true,
                this.outstandingOrder
              );
              deleteUTMValuesCookie();
            }
          }
        },
        error: (err: Error) => {
          Promise.allSettled([
            this.deletePaymentIntent(),
            this.deleteTransientOrder(),
          ]);
          this.showErrorAlert(err.message);
        },
      });
  }

  private placeOrderCommercial(
    orderId: string,
    internalOrderId: string,
    orderAmount: number
  ) {
    const stripeReqBody = {
      orderId: orderId,
      internalOrderId: internalOrderId,
      amount: orderAmount,
      isTip: false,
    };

    this._apiService
      .post(STRIPE_INVOICE, stripeReqBody, {
        customLoadingText: 'Updating invoice',
      })
      .subscribe({
        error: (err: Error) => {
          Promise.allSettled([
            this.deletePaymentIntent(),
            this.deleteTransientOrder(),
          ]);
          this.showErrorAlert(err.message);
        },
        complete: () => {
          this.createOutstandingOrder();
          this._zone.run(() => {
            this._navService.navRootWithOptions('/laundry', {
              orderPlaced: true,
            });
          });
          deleteUTMValuesCookie();
        },
      });
  }

  private deleteTransientOrder(nav = true): void {
    this._apiService
      .delete(
        CANCEL_TRANSIENT_ORDER_ENDPOINT,
        {
          orderId: this.internalOrderId,
        },
        false
      )
      .subscribe({
        error: () => {
          return;
        },
        complete: () => {
          if (nav) {
            this._zone.run(() => {
              this._navService.navRootWithOptions('/laundry', {
                orderPlaced: true,
              });
            });
          }
          return;
        },
      });
  }

  private deletePaymentIntent(): void {
    const cleanId = this.paymentId.substring(
      0,
      this.paymentId.indexOf('_secret_')
    );
    this._apiService
      .delete(
        STRIPE_CANCEL_INTENT,
        {
          paymentId: cleanId,
        },
        false
      )
      .subscribe({
        error: () => {
          return;
        },
        complete: () => {
          return;
        },
      });
  }

  private subscribeStripeListeners(): void {
    const failedToLoad = Stripe.addListener(
      PaymentSheetEventsEnum.FailedToLoad,
      () => {
        this.unsubscribeStripeListeners();
        Promise.allSettled([
          this.deletePaymentIntent(),
          this.deleteTransientOrder(),
        ]);
      }
    );

    const failed = Stripe.addListener(PaymentSheetEventsEnum.Failed, () => {
      this.unsubscribeStripeListeners();
      Promise.allSettled([
        this.deletePaymentIntent(),
        this.deleteTransientOrder(),
      ]);
    });

    const canceled = Stripe.addListener(PaymentSheetEventsEnum.Canceled, () => {
      this.unsubscribeStripeListeners();
      Promise.allSettled([
        this.deletePaymentIntent(),
        this.deleteTransientOrder(false),
      ]);
    });

    const completed = Stripe.addListener(
      PaymentSheetEventsEnum.Completed,
      () => {
        this._storeService.newOrderPaid();
        this.unsubscribeStripeListeners();
        this._zone.run(() => {
          if (!this.outstandingOrder) {
            this.completeOrder();
          } else {
            this.createOutstandingOrder();
          }
        });
        presentSuccessModal(this._storeService, this._modalCtrl);
      }
    );

    this.stripeListeners.push(failedToLoad, failed, canceled, completed);
  }

  private completeOrder(): void {
    this.externalId = null;
    this._storeService.resetOrder();
    loadCredits(this._apiService, this._storeService, this._injector);
    this._zone.run(() => {
      this._navService.navRootWithOptions('/laundry', {
        orderPlaced: true,
      });
    });
  }

  private unsubscribeStripeListeners(): void {
    this.stripeListeners.forEach((s) => s.remove());
    this.stripeListeners = [];
  }

  private createTrackEvent(checkoutStep: string): void {
    const eventName = `${SegmentEventType.CheckoutStepCompleted} - ${checkoutStep}`;
    this._storeService
      .getAccountDetails()
      .pipe(take(1))
      .subscribe({
        next: async (user) => {
          if (user) {
            this._analytics.createTrackEvent(eventName, {
              orderCount: user.orderCount,
            });
          }
        },
      });
  }

  private async showStripePayment(
    stripeId: string,
    paymentIntent: string,
    ephemeralKey: string
  ): Promise<void> {
    await this._stripeService
      .handlePaymentSheet(stripeId, paymentIntent, ephemeralKey)
      .catch(() => {
        Promise.allSettled([
          this.deletePaymentIntent(),
          this.deleteTransientOrder(),
        ]);
        this.showErrorAlert('An unknown issue with payment, please try again');
      });
  }

  private async showErrorAlert(message: string): Promise<void> {
    const dialogService: DialogService =
      this._injector.get<DialogService>(DialogService);
    await dialogService.showErrorAlert({ message });
  }

  private generateOutstandingOrder(
    data: CustomerAPI.Response.NewOrder,
    req: CustomerAPI.Request.NewOrder
  ): void {
    this.outstandingOrder = {
      id: data.internalOrderId,
      order: {
        orderId: req.order.orderId,
        orderProfileName: req.order.orderProfileName,
        orderProfileId: req.order.orderProfileId,
      },
      orderPlacedTimestamp: data.orderInfo.orderPlacedTimestamp,
      pickupDeadline: data.orderInfo.pickupDeadline,
      deliveryDeadline: data.orderInfo.deliveryDeadline,
      orderStatusTimestamp: data.orderInfo.orderStatusTimestamp,
      orderTimezone: req.pickup.pickupTimezone,
      authAmount: data.preAuth,
      bagCount: req.bags.small + req.bags.regular + req.bags.large,
    } as CustomerAPI.Request.OutstandingOrder;
  }

  private generateOrderId(): void {
    const customOptions = customAlphabet(
      'ABCDEFGHIJKLMNPQRSTUVWXYZ123456789',
      10
    );
    this.externalId = `P${customOptions(9)}`;
  }
  private createOutstandingOrder(): void {
    if (this.outstandingOrder) {
      this._apiService
        .post(OUTSTANDING_ORDER_ENDPOINT, this.outstandingOrder, {
          showLoading: false,
          handleErrorResponse: false,
        })
        .pipe(take(1))
        .subscribe({
          next: () => this.completeOrder(),
          error: () => {
            // If fails trigger will also create this record.
            this.completeOrder();
          },
          complete: () => {
            return;
          },
        });
    }
  }
}
