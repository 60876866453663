import {
  HttpEvent,
  HttpHandlerFn,
  HttpHeaders,
  HttpInterceptorFn,
  HttpRequest,
} from '@angular/common/http';
import { inject } from '@angular/core';
import { Capacitor } from '@capacitor/core';
import { environment } from '@environments/environment';
import { AuthService } from '@services';
import { CustomHttpHeader } from '@sudshare/custom-node-package';
import { Observable, catchError, from, mergeMap, throwError } from 'rxjs';
import {
  CUSTOM_POPLIN_RETRY_HEADER,
  CUSTOM_POPLIN_RETRY_HEADER_VALUE,
  EMULATOR_AUTH_HEADER,
  LIVE_AUTH_HEADER,
} from './constants';

export const UnauthorizedInterceptor: HttpInterceptorFn = (
  req: HttpRequest<any>,
  next: HttpHandlerFn
): Observable<HttpEvent<any>> => {
  const _authService = inject(AuthService);

  return next(req).pipe(
    catchError((err) => {
      if (err.status === 401) {
        // ignore sub retries to avoid infinite loop
        if (
          req.headers.get(CUSTOM_POPLIN_RETRY_HEADER) ===
          CUSTOM_POPLIN_RETRY_HEADER_VALUE
        ) {
          return throwError(() => err);
        }
        // Refreshing the token here will make user$ to emit at auth.effects in
        // constructor which will update the store, for subsequent requests
        return from(_authService.getToken(true)).pipe(
          mergeMap((token) => {
            if (!token) {
              return throwError(() => err);
            }

            const headers = buildHeaders(req.headers, token, true);
            return next(req.clone({ headers }));
          })
        );
      }

      return throwError(() => err);
    })
  );
};

function buildHeaders(
  initialHeaders: HttpHeaders,
  token: string | null,
  is401Retry = false
) {
  let headers = initialHeaders
    .set(CustomHttpHeader.appPlatform, Capacitor.getPlatform())
    .set(CustomHttpHeader.appVersion, environment.version);

  if (token) {
    const authHeaderName = environment.emulator
      ? EMULATOR_AUTH_HEADER
      : LIVE_AUTH_HEADER;
    headers = headers.set(authHeaderName, `Bearer ${token}`);
  }

  if (is401Retry) {
    headers = headers.set(
      CUSTOM_POPLIN_RETRY_HEADER,
      CUSTOM_POPLIN_RETRY_HEADER_VALUE
    );
  }

  return headers;
}
