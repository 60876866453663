<ion-content class="help-modal-page no-padding">
  <ion-grid class="help-modal-container">
    <ion-row>
      <ion-col size="12">
        <poplin-header
          class="modal-header"
          title="Help"
          [showClose]="true"
          (eventEmitter)="closeModal()"
        ></poplin-header>
      </ion-col>
    </ion-row>
    <ion-row class="button-and-list-container">
      <ion-col size="12">
        <ion-row>
          <ion-col size="12">
            <div class="tutorial-buttons">
              <button
                class="tutorial-button"
                (click)="handleButtonClick('help/process')"
              >
                <poplin-icon
                  name="gear_custom"
                  [size]="48"
                  color="--pink-core"
                ></poplin-icon>
                <span>Process</span>
              </button>
              <button
                class="tutorial-button"
                (click)="handleButtonClick('help/pricing')"
              >
                <poplin-icon
                  name="dollar_custom"
                  [size]="48"
                  color="--pink-core"
                ></poplin-icon>
                <span>Pricing</span>
              </button>
              <button
                class="tutorial-button"
                (click)="handleButtonClick('help/faq')"
              >
                <poplin-icon
                  name="help_circle_custom"
                  [size]="48"
                  color="--pink-core"
                ></poplin-icon>
                <span>FAQ</span>
              </button>
            </div>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col size="12">
            <poplin-list gap="default" size="default">
              <poplin-item
                id="chat-item"
                (eventEmitter)="handleEvent($event, helpType.SeniorSupport)"
                [fill]="'clear'"
                [size]="'default'"
                [title]="'Senior Support'"
                [showDescription]="true"
                [description]="'Dedicated support for 65+'"
                [showTrailingIcon]="true"
                [trailingIconColor]="'--pink-core'"
                [isTappable]="true"
                [showTitleIcon]="true"
                [titleIcon]="'call'"
                [titleIconColor]="'--color-content-primary'"
                ngClass="help-items"
                *ngIf="isSenior"
              ></poplin-item>
              <poplin-item
                id="chat-item"
                (eventEmitter)="handleEvent($event, helpType.HelpChat)"
                [fill]="'clear'"
                [size]="'default'"
                [title]="'Help chat'"
                [showTrailingIcon]="true"
                [trailingIconColor]="'--pink-core'"
                [isTappable]="true"
                [showTitleIcon]="true"
                [titleIcon]="'comment_custom'"
                [titleIconColor]="'--color-content-primary'"
                ngClass="help-items"
                *ngIf="showChat"
              ></poplin-item>
              <poplin-item
                id="center-item"
                (eventEmitter)="handleEvent($event, helpType.HelpCenter)"
                [fill]="'clear'"
                [size]="'default'"
                [title]="'Visit help center'"
                [showTrailingIcon]="true"
                [trailingIconColor]="'--pink-core'"
                [isTappable]="true"
                [showTitleIcon]="true"
                [titleIcon]="'life_preserver_custom'"
                [titleIconColor]="'--color-content-primary'"
                ngClass="help-items"
              ></poplin-item>
            </poplin-list>
          </ion-col>
        </ion-row>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col size="12">
        <ion-footer class="ion-no-border">
          <ion-toolbar class="modal-footer-toolbar">
            <ion-label class="app-label" slot="start">Poplin.co</ion-label>
            <ion-label class="app-version" slot="end"
              >Version {{ version }}</ion-label
            >
          </ion-toolbar>
        </ion-footer>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>
