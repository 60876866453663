import { Injectable } from '@angular/core';
import { Capacitor } from '@capacitor/core';
import {
  AndroidSettings,
  IOSSettings,
  NativeSettings,
} from 'capacitor-native-settings';

@Injectable({
  providedIn: 'root',
})
export class MobileSettingsService {
  isApple = false;

  constructor() {
    if (!Capacitor.isNativePlatform()) {
      return;
    }

    if (Capacitor.getPlatform() === 'ios') {
      this.isApple = true;
    }
  }

  openAppSettings(): void {
    if (this.isApple) {
      NativeSettings.openIOS({
        option: IOSSettings.App,
      });
    } else {
      NativeSettings.openAndroid({
        option: AndroidSettings.ApplicationDetails,
      });
    }
  }
}
