// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-nocheck
// TODO: Rares - Typescript complaining about stuff I dont have time for right now...
export function getQueryParams(
  url?: string
): Record<string, string | string[]> {
  // Using URLSearchParams to parse query parameters
  const queryParams = new URLSearchParams(
    url ? new URL(url).search : window.location.search
  );
  const params: Record<string, string | string[]> = {};

  // Iterate over all keys and values
  for (const [key, value] of queryParams) {
    // eslint-disable-next-line no-prototype-builtins
    if (params.hasOwnProperty(key)) {
      // If the key exists, append to or create an array
      params[key] = Array.isArray(params[key])
        ? [...params[key], value]
        : [params[key], value];
    } else {
      // Otherwise, just assign the value
      params[key] = value;
    }
  }

  return params;
}
