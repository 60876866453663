import { Action, createReducer, on } from '@ngrx/store';
import { STORE_STATUS, StoreStatusOptions } from '@utils';
import {
  DeviceInfo,
  DeviceInfoFailure,
  DeviceInfoSuccess,
} from './device.actions';

export interface DeviceState {
  deviceId: string | null;
  error: string | null;
  status: STORE_STATUS;
}

// Default for Web
const initialState: DeviceState = {
  deviceId: '8ee1cfe1-a078-4ad1-ad6d-b7401a2c06f2',
  error: null,
  status: StoreStatusOptions.SUCCESS,
};

const deviceReducer = createReducer(
  initialState,

  on(DeviceInfo, (state) => ({
    ...state,
    status: StoreStatusOptions.LOADING,
  })),

  on(DeviceInfoSuccess, (state, { payload }) => ({
    ...state,
    status: StoreStatusOptions.SUCCESS,
    deviceId: payload.deviceId,
  })),

  on(DeviceInfoFailure, (state, { payload }) => ({
    ...state,
    status: StoreStatusOptions.ERROR,
    error: payload.error,
  }))
);

export function DeviceReducer(
  state: DeviceState | undefined,
  action: Action
): DeviceState {
  return deviceReducer(state, action);
}
