import { inject, Injectable, Injector } from '@angular/core';
import { LoadingController, ModalController } from '@ionic/angular/standalone';
import {
  ApiService,
  DialogService,
  NavigationService,
  StoreService,
} from '@services';
import { PaymentIntentResult } from '@stripe/stripe-js';
import { CustomerAPI } from '@sudshare/custom-node-package';
import {
  loadCredits,
  OUTSTANDING_ORDER_ENDPOINT,
  PaymentIntentStatus,
  presentSuccessModal,
} from '@utils';
import { StripeWebModalComponent } from 'app/components/stripe-web-modal/stripe-web-modal.component';
import {
  StripePaymentElementComponent,
  StripeServiceInterface,
} from 'ngx-stripe';
import { BehaviorSubject, take } from 'rxjs';
import { RollbarService } from '../rollbar/rollbar.service';

@Injectable({
  providedIn: 'root',
})
export class StripeWebService {
  private _rollbar = inject(RollbarService);

  constructor(
    private _storeService: StoreService,
    private _modalCtrl: ModalController,
    private _navService: NavigationService,
    private _dialogService: DialogService,
    private _loadingCtrl: LoadingController,
    private _apiService: ApiService,
    private _injector: Injector
  ) {}
  private stripePaymentModalInstance: HTMLIonModalElement | null = null;
  // Define a BehaviorSubject to hold the result data
  private paymentResultSource = new BehaviorSubject<any>(null);
  // Expose the observable part of the BehaviorSubject
  paymentResult$ = this.paymentResultSource.asObservable();
  paymentComplete = false;

  async presentPaymentModal(componentProps: any): Promise<HTMLIonModalElement> {
    const modal = await this._modalCtrl.create({
      component: StripeWebModalComponent,
      mode: 'ios',
      initialBreakpoint: 0.9,
      breakpoints: [0.5, 0.75, 0.9, 1],
      handle: false,
      componentProps,
    });
    await modal.present();
    return modal;
  }

  async handleWebPayment(
    stripeCustomerId: string,
    paymentIntentId: string,
    ephemeralKey: string,
    orderId: string,
    internalOrderId: string,
    amount: number,
    preAuth: boolean,
    outstandingOrder?: CustomerAPI.Request.OutstandingOrder
  ): Promise<void> {
    this.stripePaymentModalInstance = await this.presentPaymentModal({
      customerId: stripeCustomerId,
      paymentIntentClientSecret: paymentIntentId,
      clientSecret: ephemeralKey,
      orderId,
      internalOrderId,
      amount,
      preAuth,
      outstandingOrder,
    });

    await this.stripePaymentModalInstance.onDidDismiss().then((res) => {
      this.stripePaymentModalInstance = null;
      // res.data = undefined when modal is dismissed without any action
      // res.data.paymentSuccess = true when payment is successful and confirmed.
      if (preAuth && !res.data?.paymentSuccess) {
        this._storeService.removeTransientOrder();
      }
    });
  }

  private async handlePaymentResult(
    preAuth: boolean,
    result?: PaymentIntentResult,
    outstandingOrder?: CustomerAPI.Request.OutstandingOrder
  ): Promise<void> {
    await this._loadingCtrl.dismiss();
    if (result && this.isPaymentSuccessful(result)) {
      if (outstandingOrder) {
        this._apiService
          .post(OUTSTANDING_ORDER_ENDPOINT, outstandingOrder, {
            showLoading: false,
            handleErrorResponse: false, // direct call or trigger will create this record.
          })
          .pipe(take(1))
          .subscribe({
            next: () => this.paymentSuccess(preAuth, result),
            error: () => {
              this.paymentSuccess(preAuth, result);
              if (this.stripePaymentModalInstance) {
                this.stripePaymentModalInstance.dismiss({
                  paymentSuccess: false,
                });
              }

              this._rollbar.error(result.error?.message);
            },
            complete: () => {
              if (this.stripePaymentModalInstance) {
                this.stripePaymentModalInstance.dismiss({
                  paymentSuccess: true,
                });
              }
            },
          });
      } else {
        this.paymentSuccess(preAuth, result);
      }
    } else {
      if (result?.error) {
        this._dialogService.showErrorAlert({
          message: result.error.message,
        });
      }

      // Optionally, emit error or null to indicate failure
      this.paymentResultSource.next(null);
    }
  }

  private async paymentSuccess(
    preAuth: boolean,
    result?: PaymentIntentResult
  ): Promise<void> {
    this.paymentComplete = true;
    // Emit the result data
    this.paymentResultSource.next(result);

    if (preAuth) {
      this._navService.navRootWithOptions('/laundry', {
        orderPlaced: true,
      });
      this._storeService.resetOrder();
    }
    loadCredits(this._apiService, this._storeService, this._injector);
  }

  private isPaymentSuccessful(result: PaymentIntentResult): boolean {
    return (
      result &&
      (result.paymentIntent?.status === PaymentIntentStatus.SUCCESS ||
        result.paymentIntent?.status === PaymentIntentStatus.REQUIRES_CAPTURE)
    );
  }

  processPayment(
    stripe: StripeServiceInterface,
    paymentElement: StripePaymentElementComponent,
    preAuth: boolean,
    outstandingOrder?: CustomerAPI.Request.OutstandingOrder
  ): void {
    const message = preAuth ? 'Placing Order' : '';
    this._storeService.presentLoading(message);

    stripe
      .confirmPayment({
        elements: paymentElement.elements,
        redirect: 'if_required',
      })
      .subscribe({
        next: async (result: PaymentIntentResult) => {
          await this.handlePaymentResult(preAuth, result, outstandingOrder);
        },
        complete: () => {
          presentSuccessModal(this._storeService, this._modalCtrl);
          return;
        },
      });
  }
}
