import { CommonModule, Location } from '@angular/common';
import {
  Component,
  CUSTOM_ELEMENTS_SCHEMA,
  EventEmitter,
  inject,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import {
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { RouterModule } from '@angular/router';
import {
  IonCol,
  IonContent,
  IonGrid,
  IonModal,
  IonRow,
  ModalController,
} from '@ionic/angular/standalone';
import {
  ButtonModule,
  CheckboxModule,
  HeaderModule,
  IconModule,
  SelectModule,
  TextareaModule,
} from '@sudshare/custom-design-package';
import { closeModal, DetergentOptions, NewOrderState } from '@utils';

@Component({
  selector: 'app-laundry-preferences-form',
  templateUrl: 'laundry-preferences.component.html',
  styleUrls: ['laundry-preferences.component.scss'],
  standalone: true,
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [
    CommonModule,
    IonGrid,
    IonContent,
    IonRow,
    IonCol,
    IonModal,
    HeaderModule,
    RouterModule,
    SelectModule,
    CheckboxModule,
    ReactiveFormsModule,
    ButtonModule,
    TextareaModule,
    IconModule,
  ],
})
export class LaundryPreferencesComponent implements OnInit {
  private _location = inject(Location);
  private _modalCtrl = inject(ModalController);

  @Output() detergentSelected: EventEmitter<string> = new EventEmitter();
  @Output() delicateChecked: EventEmitter<boolean> = new EventEmitter();
  @Output() hangDryChecked: EventEmitter<boolean> = new EventEmitter();
  @Output() withHangersChecked: EventEmitter<boolean> = new EventEmitter();
  @Output() instructionsChecked: EventEmitter<boolean> = new EventEmitter();
  @Output() inputEvent: EventEmitter<string> = new EventEmitter<string>();
  @Input() instructionsValue = '';
  @Input() label = 'Select Detergent';
  @Input() selectOptions: string[] = [
    DetergentOptions.HYPOALLERGENIC,
    DetergentOptions.PREMIUM_SCENTED,
    DetergentOptions.I_WILL_PROVIDE,
  ];
  @Input() selectedDetergent = '';
  @Input() attemptedContinue = false;
  @Input() isDelicateChecked = false;
  @Input() isHangDryChecked = false;
  @Input() isReturnWithHangersChecked = false;
  @Input() hasAdditionalInstructionsChecked = false;
  @Input() delicateSubLabel =
    "Place your delicates in a separate bag clearly labeled 'delicates'.";
  @Input() hangDrySubLabel =
    "Place hang-dry items in a separate bag with the correct number of hangers (Laundry Pros don't stock hangers) and label it 'hang-dry.' These items will be returned hung. There's no extra fee, but hang-dry items are weighed hung, so they're weighed with the hangers.";
  @Input() withHangersSubLabel =
    "Place the items you'd like returned hung in a separate bag labeled 'return hung' with the needed hangers (Laundry Pros do not provide hangers). Hung items are weighed with hangers after they are washed, dried, hung, and packed.";
  @Input() additionalInstructionsSubLabel = '';
  @Input() isExpressOrder = false;

  state = this._location.getState() as NewOrderState;
  detergent = this.state.laundryPreferences?.detergent;
  delicates = !!this.state.laundryPreferences?.delicates;
  hangDry = !!this.state.laundryPreferences?.hangDry;
  hangers = !!this.state.laundryPreferences?.hangers;
  instructions = this.state.laundryPreferences?.instructions;
  hasInstructions = !!this.state.laundryPreferences?.hasInstructions;
  laundryPreferencesForm: FormGroup = new FormGroup({
    detergent: new FormControl(this.detergent),
    delicates: new FormControl(this.delicates),
    hangDry: new FormControl(this.hangDry),
    hangers: new FormControl(this.hangers),
    hasInstructions: new FormControl(this.hasInstructions),
    instructions: new FormControl(this.instructions, [
      Validators.maxLength(250),
    ]),
  });

  ngOnInit(): void {
    if (this.selectedDetergent) {
      this.detergent = this.selectedDetergent;
      this.laundryPreferencesForm.controls['detergent'].setValue(
        this.detergent
      );
    }

    if (this.isDelicateChecked) {
      this.delicates = this.isDelicateChecked;
      this.laundryPreferencesForm.controls['delicates'].setValue(
        this.delicates
      );
    }

    if (this.isHangDryChecked) {
      this.hangDry = this.isHangDryChecked;
      this.laundryPreferencesForm.controls['hangDry'].setValue(this.hangDry);
    }

    if (this.isReturnWithHangersChecked) {
      this.hangers = this.isReturnWithHangersChecked;
      this.laundryPreferencesForm.controls['hangers'].setValue(this.hangers);
    }

    if (this.hasAdditionalInstructionsChecked) {
      this.hasInstructions = this.hasAdditionalInstructionsChecked;
      this.laundryPreferencesForm.controls['hasInstructions'].setValue(
        this.hasInstructions
      );
    }

    if (this.instructionsValue.trim()) {
      this.instructions = this.instructionsValue.trim();
      this.laundryPreferencesForm.controls['instructions'].setValue(
        this.instructions
      );
    }
    this.isExpressOrder = this.state.deliverySpeed === 'SameDay';
  }

  handleChecked(ev: boolean, type: string): void {
    switch (type) {
      case 'delicate-checked':
        this.delicates = !this.delicates;
        this.delicateChecked.emit(ev);
        break;
      case 'hang-dry-checked':
        this.hangDry = !this.hangDry;
        this.hangDryChecked.emit(ev);
        break;
      case 'hangers-checked':
        this.hangers = !this.hangers;
        this.withHangersChecked.emit(ev);
        break;
      case 'instructions-checked':
        this.hasInstructions = !this.hasInstructions;
        this.instructionsChecked.emit(ev);
        break;
    }
  }

  handleSelect(ev: Event): void {
    const event = (ev.target as HTMLIonSelectElement).value;
    return this.detergentSelected.emit(event);
  }

  handleTextAreaEvent(ev: Event): void {
    const event = (ev.target as HTMLTextAreaElement).value;
    return this.inputEvent.emit(event);
  }

  closeModal(): Promise<boolean> {
    return closeModal(this._modalCtrl);
  }
}
