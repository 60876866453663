import { Injectable, inject } from '@angular/core';
import { Device } from '@capacitor/device';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable, catchError, exhaustMap, from, mergeMap, of } from 'rxjs';
import {
  DeviceActionTypes,
  DeviceInfoFailure,
  DeviceInfoSuccess,
} from './device.actions';

@Injectable()
export class DeviceEffects {
  private _actions = inject(Actions);

  getDeviceId$ = createEffect((): Observable<Action> => {
    return this._actions.pipe(
      ofType(DeviceActionTypes.DeviceInfo),
      exhaustMap(() =>
        from(Device.getId()).pipe(
          mergeMap(({ identifier }) =>
            of(
              DeviceInfoSuccess({
                payload: {
                  status: 'success',
                  deviceId: identifier,
                  error: null,
                },
              })
            )
          ),
          catchError((error) =>
            of(
              DeviceInfoFailure({
                payload: { status: 'error', deviceId: null, error },
              })
            )
          )
        )
      )
    );
  });
}
