import { AgmCoreModule } from '@agm/core';
import { provideHttpClient, withInterceptors } from '@angular/common/http';
import {
  DEFAULT_CURRENCY_CODE,
  enableProdMode,
  ErrorHandler,
  importProvidersFrom,
} from '@angular/core';
import {
  ScreenTrackingService,
  UserTrackingService,
} from '@angular/fire/analytics';
import { provideNgxStripe } from 'ngx-stripe';

import { bootstrapApplication, BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { ServiceWorkerModule } from '@angular/service-worker';
import { Smartlook } from '@awesome-cordova-plugins/smartlook/ngx';
import { Capacitor } from '@capacitor/core';
import { environment } from '@environments/environment';
import {
  IonicRouteStrategy,
  provideIonicAngular,
} from '@ionic/angular/standalone';
import { defineCustomElements } from '@ionic/pwa-elements/loader';
import { FirebaseModule } from '@modules';
import { ErrorHandlingService } from '@services';
import { defineCustomElements as stripeCustomElements } from '@stripe-elements/stripe-elements/loader';
import {
  LoaderInterceptor,
  TokenInterceptor,
  UnauthorizedInterceptor,
} from '@utils';
import { AppRoutingModule } from 'app/app-routing.module';
import { AppComponent } from 'app/app.component';
import {
  rollbarFactory,
  RollbarService,
} from 'app/services/rollbar/rollbar.service';
import { AppStoreModule } from 'app/store/store.module';

if (environment.production) {
  enableProdMode();
}

if (Capacitor.getPlatform() === 'web') {
  defineCustomElements(window);
  stripeCustomElements(window);
}

const webOnlyProviders = [];
if (
  Capacitor.getPlatform() === 'web' &&
  !/localhost/.test(globalThis.location.origin)
) {
  webOnlyProviders.push(
    ServiceWorkerModule.register('service-workers.js', {
      registrationStrategy: 'registerWhenStable:30000',
    })
  );
}

const mobileOnlyProviders = [];
if (Capacitor.getPlatform() !== 'web') {
  mobileOnlyProviders.push(Smartlook);
}

bootstrapApplication(AppComponent, {
  providers: [
    provideIonicAngular({
      mode: 'ios',
      swipeBackEnabled: false,
      animated: true,
    }),
    importProvidersFrom(
      webOnlyProviders,
      AppRoutingModule,
      BrowserModule,
      AgmCoreModule.forRoot({
        apiKey: environment.google.placesKey,
        libraries: ['places'],
      }),
      FirebaseModule,
      AppStoreModule
    ),
    mobileOnlyProviders,
    ScreenTrackingService,
    UserTrackingService,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: ErrorHandler, useClass: ErrorHandlingService },
    { provide: RollbarService, useFactory: rollbarFactory },
    { provide: DEFAULT_CURRENCY_CODE, useValue: 'USD' },
    provideHttpClient(
      withInterceptors([
        TokenInterceptor,
        LoaderInterceptor,
        UnauthorizedInterceptor,
      ])
    ),
    provideNgxStripe(environment.stripe.publicKey),
  ],
}).catch((err) => console.error(`%c${err}`, 'color: red'));
