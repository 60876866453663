import { createAction, props } from '@ngrx/store';
import { CustomerAPI, PartialNested } from '@sudshare/custom-node-package';

export enum NewOrdersStateActionTypes {
  ProfileDataAdded = '[New Orders] Profile Data Added',
  AddressAdded = '[New Orders] Address Added',
  CoverageAdded = '[New Orders] Coverage Added',
  DeliveryAdded = '[New Orders] Delivery Added',
  PreferencesAdded = '[New Orders] Preferences Added',
  BagSizeAdded = '[New Orders] Bag Size Added',
  PickupAdded = '[New Orders] Pickup Added',
  PlaceOrder = '[New Orders] Place Order',
  ResetOrder = '[New Orders] Reset Order',
  OversizedItemsAdded = '[New Orders] Oversized Items Added',
  SurgeAgreed = '[New Orders] Surge Price Agreed',
  SurgePriceSet = '[New Orders] Surge Price Set',
  CreditAdded = '[New Orders] Credit Added',
  CouponAdded = '[New Orders] Coupon Added',
  FeesAdded = '[New Orders] Fees Added',
  CommercialAccountSet = '[New Orders] Commercial Account Set',
  PreferredLPAdded = '[New Orders] Preferred LP Added',
  RemoveTransientOrder = '[New Orders] Delete Transient Order',
  CancelPaymentIntent = '[New Orders] Cancel Payment Intent',
  ProtectionAccepted = '[New Orders] Protection Accepted',
  NewOrderPaid = '[New Orders] New Order Paid',
  RepeatOrder = '[New Orders] Repeat Order started',
  PriceInformation = '[New Orders] Price Information Addded',
}

export const ProfileDataAdded = createAction(
  NewOrdersStateActionTypes.ProfileDataAdded,
  props<{ payload: PartialNested<CustomerAPI.Request.NewOrder> }>()
);

export const AddressAdded = createAction(
  NewOrdersStateActionTypes.AddressAdded,
  props<{ payload: PartialNested<CustomerAPI.Request.NewOrder> }>()
);

export const CoverageAdded = createAction(
  NewOrdersStateActionTypes.CoverageAdded,
  props<{ payload: PartialNested<CustomerAPI.Request.NewOrder> }>()
);

export const DeliveryAdded = createAction(
  NewOrdersStateActionTypes.DeliveryAdded,
  props<{ payload: PartialNested<CustomerAPI.Request.NewOrder> }>()
);

export const PreferencesAdded = createAction(
  NewOrdersStateActionTypes.PreferencesAdded,
  props<{ payload: PartialNested<CustomerAPI.Request.NewOrder> }>()
);

export const BagSizeAdded = createAction(
  NewOrdersStateActionTypes.BagSizeAdded,
  props<{ payload: PartialNested<CustomerAPI.Request.NewOrder> }>()
);

export const PickupAdded = createAction(
  NewOrdersStateActionTypes.PickupAdded,
  props<{ payload: PartialNested<CustomerAPI.Request.NewOrder> }>()
);

export const OversizedItemsAdded = createAction(
  NewOrdersStateActionTypes.OversizedItemsAdded,
  props<{ payload: PartialNested<CustomerAPI.Request.NewOrder> }>()
);

export const SurgePriceSet = createAction(
  NewOrdersStateActionTypes.SurgePriceSet,
  props<{ payload: CustomerAPI.Response.CustomerSurge }>()
);

export const CommercialAccountSet = createAction(
  NewOrdersStateActionTypes.CommercialAccountSet,
  props<{ payload: boolean }>()
);

export const PriceInformationAdded = createAction(
  NewOrdersStateActionTypes.PriceInformation,
  props<{ payload: PartialNested<CustomerAPI.Request.NewOrder> }>()
);

export const ProtectionAccepted = createAction(
  NewOrdersStateActionTypes.ProtectionAccepted,
  props<{ payload: boolean }>()
);

export const PreferredLPAdded = createAction(
  NewOrdersStateActionTypes.PreferredLPAdded,
  props<{
    payload: PartialNested<{
      preferredLp: string[];
      preferredLpNames: string[];
      preferredPickup: string;
    }>;
  }>()
);

export const CreditAdded = createAction(
  NewOrdersStateActionTypes.CreditAdded,
  props<{ payload: PartialNested<CustomerAPI.Request.NewOrder> }>()
);

export const CouponAdded = createAction(
  NewOrdersStateActionTypes.CouponAdded,
  props<{ payload: PartialNested<CustomerAPI.Request.NewOrder> }>()
);

export const FeesAdded = createAction(
  NewOrdersStateActionTypes.FeesAdded,
  props<{ payload: PartialNested<CustomerAPI.Request.NewOrder> }>()
);

export const RepeatOrder = createAction(
  NewOrdersStateActionTypes.RepeatOrder,
  props<{ payload: PartialNested<CustomerAPI.Request.NewOrder> }>()
);

export const RemoveTransientOrder = createAction(
  NewOrdersStateActionTypes.RemoveTransientOrder
);

export const CancelPaymentIntent = createAction(
  NewOrdersStateActionTypes.CancelPaymentIntent
);

export const NewOrderPaid = createAction(
  NewOrdersStateActionTypes.NewOrderPaid
);

export const PlaceOrder = createAction(NewOrdersStateActionTypes.PlaceOrder);
export const ResetOrder = createAction(NewOrdersStateActionTypes.ResetOrder);
