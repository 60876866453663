import { Action, createReducer, on } from '@ngrx/store';
import {
  CustomerProfileWithId,
  PartialNested,
} from '@sudshare/custom-node-package';
import { StoreStatusOptions, STORE_STATUS } from '@utils';
import {
  DeleteProfile,
  DuplicateProfile,
  ProfileAddressAdded,
  ProfileIdAdded,
  ProfileLaundryCareAdded,
  ProfileNameAdded,
  ProfilePickupAdded,
  ResetProfile,
  UpdateProfile,
} from './new-profiles.actions';

export interface NewProfileState {
  profileData: PartialNested<CustomerProfileWithId>;
  error: string | null;
  status: STORE_STATUS;
}

const initialState: NewProfileState = {
  profileData: {
    Id: '',
    ProfileName: '',
    Address: {
      Full: '',
      Line1: '',
      Line2: '',
      City: '',
      State: '',
      ZipCode: '',
      Country: '',
    },
    PickupSpot: {
      Instructions: '',
      SimpleSpot: '',
      Type: '',
      PickupPlace: '',
    },
    Preferences: {
      Delicates: false,
      Detergent: undefined,
      HangDry: false,
      Hangers: false,
      Instructions: '',
    },
    Timezone: '',
    GeoLocation: undefined,
  },
  error: null,
  status: StoreStatusOptions.PENDING,
};

const newProfileReducer = createReducer(
  initialState,

  on(ProfileIdAdded, (state, { payload }) => ({
    ...state,
    status: StoreStatusOptions.PICKUP,
    profileData: {
      ...state.profileData,
      Id: payload.Id,
    },
  })),

  on(ProfileNameAdded, (state, { payload }) => ({
    ...state,
    status: StoreStatusOptions.PROFILE_NAME,
    profileData: {
      ...state.profileData,
      ProfileName: payload.ProfileName,
    },
  })),

  on(ProfileAddressAdded, (state, { payload }) => ({
    ...state,
    status: StoreStatusOptions.ADDRESS,
    profileData: {
      ...state.profileData,
      Address: payload.Address,
      GeoLocation: payload.GeoLocation,
      Timezone: payload.Timezone,
    },
  })),

  on(ProfilePickupAdded, (state, { payload }) => ({
    ...state,
    status: StoreStatusOptions.PICKUP,
    profileData: {
      ...state.profileData,
      PickupSpot: payload.PickupSpot,
    },
  })),

  on(ProfileLaundryCareAdded, (state, { payload }) => ({
    ...state,
    status: StoreStatusOptions.PREFERENCES,
    profileData: {
      ...state.profileData,
      Preferences: payload?.Preferences,
    },
  })),

  on(UpdateProfile, (state) => ({
    ...state,
    status: StoreStatusOptions.UPDATE_PROFILE,
    profileData: {
      ...state.profileData,
    },
  })),

  on(DuplicateProfile, (state) => ({
    ...state,
    status: StoreStatusOptions.DUPLICATE_PROFILE,
    profileData: {
      ...state.profileData,
    },
  })),

  on(DeleteProfile, (state, { payload }) => ({
    ...state,
    status: StoreStatusOptions.DELETE_PROFILE,
    profileData: {
      Id: payload.Id,
    },
  })),

  on(ResetProfile, () => ({
    ...initialState,
  }))
);

export function NewProfilerReducer(
  state: NewProfileState | undefined,
  action: Action
): NewProfileState {
  return newProfileReducer(state, action);
}
