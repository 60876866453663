<ion-content>
  <ion-grid>
    <ion-row>
      <ion-col size="12">
        <header>
          <h1>{{ title }}</h1>
          <poplin-button
            id="confirm-action-modal-close-button"
            color="monochrome"
            size="small"
            fill="clear"
            shape="round"
            icon="close"
            [showIcon]="true"
            iconSlot="icon-only"
            class="close-icon"
            (click)="onCancel()"
          ></poplin-button>
        </header>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col size="12">
        <p class="content">{{ content }}</p>

        <poplin-button
          id="confirm-action-modal-confirm-button"
          *ngIf="showConfirm"
          iconColor="--viridian-800"
          [label]="confirmButtonLabel"
          class="space-bottom"
          (eventEmitter)="onConfirm()"
        ></poplin-button>
        <poplin-button
          id="confirm-action-modal-cancel-button"
          *ngIf="showCancel"
          [label]="cancelButtonLabel"
          fill="outline"
          (eventEmitter)="onCancel()"
        ></poplin-button>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>
