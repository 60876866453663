import { inject, Injectable } from '@angular/core';
import {
  PaymentSheetResultInterface,
  Stripe,
} from '@capacitor-community/stripe';
import { environment } from '@environments/environment';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { CustomerAPI, OrderDocument } from '@sudshare/custom-node-package';
import { STRIPE_CREATE_INTENT } from '@utils';
import { ApiService } from '../api/api.service';
import { DatabaseService } from '../firebase/database/database.service';

@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class StripeService {
  private _databaseService = inject(DatabaseService);
  private _apiService = inject(ApiService);

  constructor() {
    Stripe.initialize({
      publishableKey: environment.stripe.publicKey,
    });
  }

  async handlePaymentSheet(
    stripeCustomerId: string,
    paymentIntentId: string,
    ephemeralKey: string
  ): Promise<{
    paymentResult: PaymentSheetResultInterface;
  }> {
    await Stripe.createPaymentSheet({
      merchantDisplayName: 'Poplin',
      countryCode: 'US',
      paymentIntentClientSecret: paymentIntentId,
      customerId: stripeCustomerId,
      customerEphemeralKeySecret: ephemeralKey,
      enableGooglePay: true,
      enableApplePay: true,
      applePayMerchantId: environment.stripe.applePayMerchant,
    });

    return await Stripe.presentPaymentSheet();
  }

  resolvePayment = (orderId: string): void => {
    this._databaseService
      .subscribeToOrderDoc(orderId)
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (order: OrderDocument | null) => {
          if (order) {
            const req = {
              orderId: order.OrderId,
              internalOrderId: orderId,
              amount: order.PaymentDeclined,
              isPreAuth: false,
              paymentRetry: true,
            };

            this._apiService
              .post<CustomerAPI.Response.StripeIntent>(
                STRIPE_CREATE_INTENT,
                req,
                { customLoadingText: 'Creating charge' }
              )
              .pipe(untilDestroyed(this))
              .subscribe({
                next: (res) => {
                  if (res && res.statusCode === 200 && res.success) {
                    const data = res.data;
                    this.handlePaymentSheet(
                      data.stripeId,
                      data.paymentIntent,
                      data.ephemeralKey
                    );
                  }
                },
              });
          }
        },
      });
  };
}
