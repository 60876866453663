import { createSelector } from '@ngrx/store';
import { StoreState } from '../store.state';
import { NewOrderState } from './new-orders.reducer';

const newOrderState = (state: StoreState) => state.newOrder;

export const getNewOrderData = createSelector(
  newOrderState,
  (state: NewOrderState) => structuredClone(state.orderData)
);

export const getSurgePriceData = createSelector(
  newOrderState,
  (state: NewOrderState) => state.surgePrice
);

export const getCommercialAccount = createSelector(
  newOrderState,
  (state: NewOrderState) => state.isCommercial
);

export const getCredits = createSelector(
  newOrderState,
  (state: NewOrderState) => state.orderData.additional?.credits || 0
);

export const getOrderAddress = createSelector(getNewOrderData, (state) =>
  structuredClone(state.pickup)
);

export const getOrderPreferences = createSelector(getNewOrderData, (state) =>
  structuredClone(state.laundry)
);

export const getOrderPreferred = createSelector(getNewOrderData, (state) =>
  structuredClone(state.preferred)
);

export const getProtectionAccepted = createSelector(
  newOrderState,
  (state: NewOrderState) => state.protectionAccepted
);

export const getNewOrderStatus = createSelector(
  newOrderState,
  (state: NewOrderState) => state.status
);
