import { MemoizedSelector, createSelector } from '@ngrx/store';
import { StoreState } from '../store.state';
import { MessageData, MessagesState } from './messages.reducer';

const orderMessagesState = (state: StoreState) => state.orderMessages;

export const getOrderMessages = createSelector(
  orderMessagesState,
  (state: MessagesState): MessageData[] | null =>
    structuredClone(state.messageData)
);

export const getOrderMessageById = (
  orderId: string
): MemoizedSelector<
  StoreState,
  MessageData[] | undefined,
  (s1: MessageData[] | null) => MessageData[] | undefined
> =>
  createSelector(getOrderMessages, (messages) => {
    return messages?.filter((m) => m.orderId === orderId);
  });
