<ion-content>
  <ion-grid>
    <ion-row>
      <ion-col size="12">
        <header>
          <h1>{{ laundryProName }}</h1>
          <poplin-button
            id="block-lp-close-button"
            color="monochrome"
            size="small"
            fill="clear"
            shape="round"
            icon="close"
            [showIcon]="true"
            iconSlot="icon-only"
            class="close-icon"
            (click)="onClose()"
          ></poplin-button>
        </header>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col size="12">
        <p class="blocked-content" *ngIf="isBlock">
          Would you like to block {{ laundryProName.split(" ")[0] }}? Blocked
          Laundry Pros cannot see or accept your orders.
          {{ laundryProName.split(" ")[0] }} will NOT be notified of this block.
        </p>
        <poplin-button
          id="block-lp-button"
          class="confirm-button"
          iconColor="--viridian-800"
          [label]="buttonLabel"
          (eventEmitter)="onConfirm()"
        ></poplin-button>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>
