import { Injectable, inject } from '@angular/core';
import { ReferralRedemptionModalComponent } from '@components';
import { ModalController } from '@ionic/angular/standalone';
import {
  AnalyticsService,
  ApiService,
  DialogService,
  LoggingService,
  StorageService,
  StoreService,
} from '@services';
import { CustomerAPI, SegmentEventType } from '@sudshare/custom-node-package';
import {
  CREDITS_ENDPOINT,
  LOCAL_STORAGE_ITEMS,
  REFERRAL_REDEEM_ENDPOINT,
} from '@utils';

const LOG_TAG = '[ReferralService]';

@Injectable({
  providedIn: 'root',
})
export class ReferralService {
  private _logger = inject(LoggingService);
  private _apiService = inject(ApiService);
  private _modalController = inject(ModalController);
  private _analytics = inject(AnalyticsService);
  private _storage = inject(StorageService);
  private _store = inject(StoreService);
  private _dialogService: DialogService = inject<DialogService>(DialogService);

  redeemReferral(referralId: string): void {
    if (!referralId) {
      return;
    }

    this._apiService
      .post<CustomerAPI.Response.ReferralRedeem>(
        REFERRAL_REDEEM_ENDPOINT,
        {
          referralId: referralId,
        },
        { showLoading: false }
      )
      .subscribe({
        next: async (x) => {
          const res = x.data;
          if (res.ui) {
            const referralRedemptionModal = await this._modalController.create({
              component: ReferralRedemptionModalComponent,
              componentProps: {
                content: res.ui.props.modalData.bodyText,
                path: res.ui.props.buttons[0].path,
                buttonActionName: res.ui.props.buttons[0].label,
                modalAction: res.ui.props.buttons[0].action,
                headerOne: res.ui.props.modalData.headerTextOne,
                headerTwo: res.ui.props.modalData.headerTextTwo,
              },
              cssClass: 'redeem-modal',
            });

            referralRedemptionModal.present();
          }

          this._apiService.get(CREDITS_ENDPOINT, false).subscribe({
            next: (res) => {
              const credits: number = res.data.amount;
              if (credits !== 0) {
                this._store.addCredit({
                  additional: {
                    credits: credits,
                  },
                });
              }
            },
            error: (e) => {
              this._logger.logWarning(LOG_TAG, e.message);
            },
          });

          this._analytics.createTrackEvent(SegmentEventType.ReferralRedeemed, {
            referralId: referralId,
          });
        },
        complete: async () => {
          await this._storage.removeItem(LOCAL_STORAGE_ITEMS.referralId);
        },
        error: async (err: Error) => {
          this._logger.logWarning(LOG_TAG, err.message);
          await this._storage.removeItem(LOCAL_STORAGE_ITEMS.referralId);
          await this._dialogService.showErrorAlert({
            message: err.message,
          });
        },
      });
  }
}
