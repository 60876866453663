import { HttpContextToken } from '@angular/common/http';
import { LoadingOptions } from '@ionic/angular/standalone';
import { calcBreakpoint } from './functions';

// NgRx Store Status
export const StoreStatusOptions = {
  PENDING: 'pending',
  LOADING: 'loading',
  ERROR: 'error',
  SUCCESS: 'success',
  ADDRESS: 'address',
  COVERAGE: 'coverage',
  DELIVERY: 'delivery',
  PREFERENCES: 'preferences',
  LOAD_SIZE: 'loadSize',
  PICKUP: 'pickup',
  READY: 'ready',
  OVERSIZED_ITEMS: 'oversizedItems',
  SURGE_AGREED: 'surgeAgreed',
  PREFERRED_LP: 'preferredLp',
  CREDIT: 'credit',
  COUPON: 'coupon',
  CHOOSE_PROFILE: 'chooseProfile',
  PROFILE_NAME: 'profileName',
  UPDATE_PROFILE: 'updateProfile',
  DUPLICATE_PROFILE: 'duplicateProfile',
  DELETE_PROFILE: 'deleteProfile',
  PAST_ORDERS: 'pastOrders',
  NEW_ORDER_PAID: 'newOrderPaid',
  FEES: 'feesAdded',
  REPEAT_ORDER: 'repeatOrder',
  PRICE_INFORMATION: 'priceInformation',
} as const;

export type STORE_STATUS =
  (typeof StoreStatusOptions)[keyof typeof StoreStatusOptions];

export type STATE_FROM_REVIEW<T> = T & {
  isFromReview: boolean;
};

// Custom HTTP Context
export const SHOW_LOADING = new HttpContextToken<boolean>(() => false);
export const SET_LOADING_MSG = new HttpContextToken<string>(() => 'Loading');
export enum HttpBusinessError {
  conflictDuplicatedOrder = 209,
  unprocessableSurgeChargeAvailable = 422,
  authenticationError = 401,
  gatewayTimeout = 504, // no connection to own api or firebase error in connection.
}
export const EMULATOR_AUTH_HEADER = 'x-forwarded-authorization';
export const LIVE_AUTH_HEADER = 'Authorization';
export const CUSTOM_POPLIN_RETRY_HEADER = 'X-poplin-retry';
export const CUSTOM_POPLIN_RETRY_HEADER_VALUE = 'true';

//Modal Breakpoints
export const breakpoints = [
  0,
  calcBreakpoint(320),
  calcBreakpoint(420),
  calcBreakpoint(464),
  calcBreakpoint(576),
  0.35,
  0.5,
  0.75,
  0.8,
  1,
];

// Keys Local Storage
export enum LOCAL_STORAGE_ITEMS {
  userId = 'userId',
  newUser = 'newUser',
  supportUser = 'supportUser',
  marketing = 'marketing',
  webLink = 'webLink',
  referralId = 'referralId',
}

// Checkout Step Names
export enum CheckoutStep {
  AddressStep = 'Address',
  PickupStep = 'Pickup Location',
  LaundryPreferences = 'Laundry Preferences',
  ProfileSelected = 'Profile Selected',
  DeliverySpeed = 'Delivery Speed',
  BagSize = 'Bag Size',
  OversizeItems = 'Oversize Items',
  PreferredLPAdded = 'Preferred LP',
  Coverage = 'Coverage',
}

export enum CheckoutStepNumber {
  Address = 1,
  PickupStep = 2,
  LaundryPreferences = 3,
  DeliverySpeed = 4,
  BagSize = 5,
  OversizeItems = 6,
  PreferredLPs = 7,
  Coverage = 8,
}

export const PickupOptions = {
  ASAP: 'asap',
  FLEXIBLE: 'flexible',
} as const;

export const DetergentOptions = {
  HYPOALLERGENIC: 'Hypoallergenic',
  PREMIUM_SCENTED: 'Premium Scented',
  I_WILL_PROVIDE: 'I Will Provide',
} as const;

export const VALIDATION_DELAY = 2000;

// General API
export const NEW_ACCOUNT_ENDPOINT = '/general/v1/user';
export const RESET_PASSWORD_ENDPOINT = '/general/v1/user/reset-password';
export const RESEND_VERIFICATION_EMAIL_ENDPOINT =
  '/general/v1/user/resend-email-verification';
export const CHECK_PHONE_ENDPOINT = '/general/v1/user/check-phone';
export const ADDRESS_CHECK_ENDPOINT = '/general/v1/check-coverage/address';
export const TRACK_EVENT_ENDPOINT = '/general/v1/analytics/track-event';
// Customer API
export const PROFILE_ENDPOINT = '/customer/v1/document/profiles';
export const OUTSTANDING_ORDER_ENDPOINT = '/customer/v1/order/outstanding';
export const RATE_ORDER_ENDPOINT = '/customer/v1/order/rate';
export const ORDER_PRICE_ESTIMATION = '/customer/v1/order/cost';
export const ORDER_RECEIPT = '/customer/v1/receipt';
export const LAUNDRY_PRO_PATH = '/customer/v1/laundry-pro';
export const CUSTOMER_PATH = '/customer/v1/document';
export const STRIPE_CREATE_INTENT = '/customer/v1/stripe/create-intent';
export const STRIPE_CANCEL_INTENT = '/customer/v1/stripe/cancel-intent';
export const STRIPE_INVOICE = '/customer/v1/stripe/invoice';
export const STRIPE_REFUND = '/customer/v1/stripe/refund-payment';
export const MARKETING_DOC_UPDATE = '/customer/v1/document/marketing';
export const APP_INFO_ENDPOINT = '/customer/v1/app-info';
export const ZENDESK_ENDPOINT = '/customer/v1/zendesk';
export const NEW_ORDER = '/customer/v1/order';
export const COUPON_ENDPOINT = '/customer/v1/coupon';
export const USER_DOC_UPDATE = '/customer/v1/document';
export const NEW_STRIPE_ACCOUNT = '/customer/v1/stripe/account';
export const MIGRATE_STRIPE_DATA = '/customer/v1/stripe/migrate';
export const ORDER_HISTORY_PATH = '/customer/v1/order/history';
export const MESSAGING_ENDPOINT = '/customer/v1/order/messaging';
export const SURGE_ENDPOINT = '/customer/v1/order/surge';
export const CREDITS_ENDPOINT = '/customer/v1/credits';
export const CANCEL_TRANSIENT_ORDER_ENDPOINT = '/customer/v1/order/transient';
export const REFERRAL_ENDPOINT = '/customer/v1/referral';
export const REFERRAL_REDEEM_ENDPOINT = '/customer/v1/referral/redeem';
export const DELETE_PROFILE_ENDPOINT = `${PROFILE_ENDPOINT}?profileId=`;
export const PAYMENT_METHOD_ENDPOINT = `customer/v1/stripe/payment-method`;
export const APPSFLYER_DATA_ENDPOINT = `customer/v1/document/appsflyer`;
export const PREFERRED_LP_ENDPOINT = '/customer/v1/order/preferred';

// New Order Routes
export enum NewOrderRoutes {
  CHOOSE_PROFILE = '/choose-profile',
  PICKUP_LOCATION = '/pickup-location',
  DELIVERY_SPEED = '/delivery-speed',
  LAUNDRY_CARE = '/laundry-preferences',
  COVERAGE = '/coverage',
  REVIEW_ORDER = '/review-order',
  PREFERRED_LP = '/choose-preferred-laundry-pro',
  BAG_COUNT = '/bag-count',
  OVERSIZED_ITEMS = '/oversized-items',
  RATE_TIP = '/rate-tip',
  ADDRESS = '/address',
}

export enum ProfileRoutes {
  ADDRESS = '/profiles/address',
}

export const FormatTimestampOptions = {
  TIME_AGO: 'time ago',
  PLACED: 'placed',
  DAY_OF_WEEK: 'day of week',
} as const;

export enum PaymentIntentStatus {
  SUCCESS = 'succeeded',
  REQUIRES_CAPTURE = 'requires_capture',
}

export enum AppRoutes {
  LAUNDRY = '/laundry',
  PAST_ORDERS = '/past-orders',
}

export enum StatsigFlags {
  TRUST_AND_SAFETY_FEE = 'trustandsafetyfee',
  FORCE_TOKEN_REFRESH = 'forcetokenrefresh',
  REPEAT_ORDERS = 'repeatordersenabled',
  PRICE_TESTING = 'pricetesting',
}
export const CENTS_TO_DOLLAR = 100;

export const loadingOptions = (message: string): LoadingOptions => {
  return {
    message: message,
    spinner: 'circular',
    cssClass: 'poplin-loading',
    mode: 'ios',
  };
};
