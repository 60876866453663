import { DOCUMENT } from '@angular/common';
import { inject, Injectable } from '@angular/core';
import { Scripts } from '@utils';
import { ScriptStore } from './script.store';

interface ScriptObj {
  script: string;
  status: string;
  loaded: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class ScriptService {
  private document = inject(DOCUMENT);

  private scripts: Scripts[] = ScriptStore.map((script: Scripts) => ({
    name: script.name,
    src: script.src,
    loaded: false,
  }));

  load(scripts: string[]): Promise<ScriptObj[]> {
    const loadPromises = scripts.map((script) => this.loadScript(script));
    return Promise.all(loadPromises);
  }

  private loadScript(name: string): Promise<ScriptObj> {
    return new Promise((resolve, reject) => {
      const existingScript = this.scripts.find(
        (script) => script.name === name
      );

      if (existingScript && existingScript.loaded) {
        resolve({
          script: name,
          loaded: true,
          status: 'Loaded',
        });
        return;
      }

      if (!existingScript) {
        reject({
          script: name,
          loaded: false,
          status: 'NotFound',
        });
        return;
      }

      const scriptElement = this.document.createElement('script');
      scriptElement.type = 'text/javascript';
      scriptElement.src = existingScript.src;
      scriptElement.async = true;

      scriptElement.onload = () => {
        existingScript.loaded = true;
        resolve({
          script: name,
          loaded: true,
          status: 'Loaded',
        });
      };

      scriptElement.onerror = (error) => {
        reject({
          script: name,
          loaded: false,
          status: 'Error',
          error: error,
        });
      };

      this.document.head.appendChild(scriptElement);
    });
  }
}
