import { createAction, props } from '@ngrx/store';
import { CustomerDocument, PartialNested } from '@sudshare/custom-node-package';
import { UserState } from '.';

export enum UserActionTypes {
  SubscribeSuccess = '[User] Subscribe Success',
  SubscribeFailure = '[User] Subscribe Failure',
  DocUpdate = '[User] Doc Update',
  CloseAccount = '[User] Close Account',
  AccountDisabled = '[User] Account Disabled',
  DiscountUpdated = '[User] Discount Updated',
}

export const SubscribeSuccess = createAction(
  UserActionTypes.SubscribeSuccess,
  props<{ payload: UserState }>()
);
export const SubscribeFailure = createAction(
  UserActionTypes.SubscribeFailure,
  props<{ payload: UserState }>()
);
export const AccountDisabled = createAction(
  UserActionTypes.AccountDisabled,
  props<{ payload: UserState }>()
);
export const DocUpdate = createAction(
  UserActionTypes.DocUpdate,
  props<{
    payload: { doc: PartialNested<CustomerDocument>; showLoading: boolean };
  }>()
);

export const CloseAccount = createAction(
  UserActionTypes.CloseAccount,
  props<{ payload: { showLoading: boolean } }>()
);

export const DiscountUpdated = createAction(
  UserActionTypes.DiscountUpdated,
  props<{ payload: { discount: UserDiscount } }>()
);

export interface UserDiscount {
  provider: string;
  jwt: string;
}
