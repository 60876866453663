import { Injectable, Injector, inject } from '@angular/core';
import { AppUpdate, AppUpdateInfo } from '@capawesome/capacitor-app-update';
import { AlertOptions } from '@ionic/angular/standalone';
import { LoggingService } from '@services';
import { DialogService } from '../dialog/dialog.service';

const LOG_TAG = '[AppUpdateService]';
@Injectable({
  providedIn: 'root',
})
export class AppUpdateService {
  private _injector = inject(Injector);
  private _logger = inject(LoggingService);

  init(): void {
    AppUpdate.getAppUpdateInfo({ country: 'US' })
      .then((appUpdateInfo) => {
        this.checkForUpdate(appUpdateInfo);
      })
      .catch((e) => {
        this._logger.logError(LOG_TAG, ': ', e.code || e.message || e);
      });
  }

  private checkForUpdate(appUpdateInfo: AppUpdateInfo) {
    const localVersion = appUpdateInfo.currentVersion;
    const storeVersion = appUpdateInfo.availableVersion;

    if (localVersion < storeVersion) {
      this.showUpdateAlert({
        id: 'app-update-alert',
        header: 'App Update',
        message: 'Open the app store to update your application',
        buttons: [
          {
            id: 'app-update-cancel',
            text: 'Cancel',
            role: 'cancel',
          },
          {
            id: 'app-update-ok',
            text: 'App Store',
            role: 'ok',
            handler: () => {
              this.openAppStore();
            },
          },
        ],
      });
    }
  }

  private async openAppStore(): Promise<void> {
    await AppUpdate.openAppStore();
  }

  private async showUpdateAlert(
    options: AlertOptions
  ): Promise<HTMLIonAlertElement> {
    const dialogService: DialogService =
      this._injector.get<DialogService>(DialogService);
    return await dialogService.showAlert(options);
  }
}
