export const environment = {
  version: '4.3.4',
  iosVersion: '4.3.4',
  androidVersion: '4.3.4',
  production: false,
  emulator: false,
  apiPath: 'https://non-prod-1ip7g17v.uc.gateway.dev',
  apiToken: 'AIzaSyDh1zGEIVsu0MJKzzIU3n6wC3fmfqKu_mE',
  firebase: {
    apiKey: 'AIzaSyACXhMm6krhv3qeCZcfanoP9DiTvoqKqc8',
    authDomain: 'nonprod.poplin.co',
    databaseURL: 'https://sudsharetest.firebaseio.com',
    projectId: 'sudsharetest',
    locationId: 'us-central',
    storageBucket: 'sudsharetest.appspot.com',
    messagingSenderId: '119070405931',
    appId: '1:119070405931:web:685f71f6478a3090ce016e',
    measurementId: 'G-57GTWLSJ51',
  },
  google: {
    placesKey: 'AIzaSyDTmQmq8vJccbjbz58H-QeRHCjo6dl75AE',
  },
  stripe: {
    publicKey: 'pk_test_UN2Hmj2I2XyW9cSoQvuYriao',
    applePayMerchant: 'merchant.com.sudshare.sudshare',
  },
  externalLinks: {
    marketingWebsite: 'https://nonprod.poplin.co',
    webApp: 'https://nonprod-app.poplin.co',
    customUriSchema: 'poplintest',
  },
  messaging: {
    vapidKey:
      'BF9OfnQ1bM1qBx_8HWi3sgj935Tri58zjLQ_R1ja4ZIMVqm6b8NRwkC0Z7GFw5qwdQN9LS7a1gyjZ4GvRSPz8eY',
  },
  segment: {
    webKey: 'EzcvZ3HyEkS9rf3RCUIE2DaVZdHibW2q',
    andKey: 'RsrKpSGwiH0c3XA83xE6AiuGLZb08baA',
    iosKey: 'voUNtzw5YuW2tLYeLfdmpAKi0FyvF03v',
  },
  statsig: {
    clientKey: 'client-uBJavzHK4RVMe6rupshUK0SmyYrSsuttSRJp6dmm16z',
    gates: {},
    configs: {},
  },
  zendesk: {
    iosKey:
      'eyJzZXR0aW5nc191cmwiOiJodHRwczovL3BvcGxpbi56ZW5kZXNrLmNvbS9tb2JpbGVfc2RrX2FwaS9zZXR0aW5ncy8wMUg2VjZLU045SEVIUVpFNUo3QVFOMUpURi5qc29uIn0=',
    androidKey:
      'eyJzZXR0aW5nc191cmwiOiJodHRwczovL3BvcGxpbi56ZW5kZXNrLmNvbS9tb2JpbGVfc2RrX2FwaS9zZXR0aW5ncy8wMUg2VktESjA1MU5GQ0RHTk03NUE1QjRYNi5qc29uIn0=',
    webKey: '956bb62e-8eb8-428f-b6bd-1fc0cc54d362',
    customerLink: 'https://poplin.zendesk.com/hc/en-us',
    submitRequestLink: 'https://poplin.zendesk.com/hc/en-us/requests/new',
  },
  iterable: {
    webKey: '9d586bd23946436eb0b54c385325a0c6',
    andKey: '078878e818c04b2bbc45082e7490a1aa',
    andPackage: 'com.sudshare.sudshare.test',
    iosKey: '0218c23cba2b49fca54af85f41783212',
    iosPackage: 'com.sudshare.SudShare.test',
  },
  appsFlyer: {
    key: 'qSCKCrqKsKGTZLLR2ijtk',
    andAppId: 'com.sudshare.sudshare.test',
    iosAppId: 'id6444035246',
    oneLink: 'nonprod-appsflyer.poplin.co',
    emailLinkWrapper: 'click.poplin.co',
  },
  smartlook: {
    mobileKey: 'd1eded6974d3c057e3342191bb4545a7f33f51f8',
    webKey: 'd1eded6974d3c057e3342191bb4545a7f33f51f8',
  },
  coupon: {
    publicKey:
      '-----BEGIN PUBLIC KEY-----\nMIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAvsdguJlAX3UqNYNk0JGi\nFt55J10wufZq6iQA67jSTU370iM5LYqv7emVvH7WHHokvkLQBroHvgH71CUZYRyB\nPj7QzUAa17BcXLQ0JZ5uAiLtoggf4k4Af+WROKUqHuQdaZKC2Q4HNSK4M6kFA9c4\nv5ArNIsZ1JXKebGTypP9Yyb0HxIuSG8Co+bB1EbJFr7yIzYXZfCRWJjnvGyM9x2O\nydiQDH3xg6pK/Jsa8GOYe87ForCQC5vjLCi5IpXrQdWb/pTeoaYdF2kRb0k3kw2b\nXftzhgc6J2eP6jZs/pZJR9Zx9LX5+j3mpKxvLalcFH2lu74F1fK392L4N08O1B7G\n2wIDAQAB\n-----END PUBLIC KEY-----',
  },
  rollbar: {
    accessToken: 'bd258fdbd3b54c53919c6913a7a6d07b',
  },
};
