import { inject, Injectable } from '@angular/core';
import { FirebaseMessaging } from '@capacitor-firebase/messaging';
import { Capacitor } from '@capacitor/core';
import { environment } from '@environments/environment';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ZendeskMessaging } from '@sudshare/capacitor-zendesk';
import { ZENDESK_ENDPOINT } from '@utils';
import { map, Observable } from 'rxjs';
import { ApiService } from '../api/api.service';
import { StoreService } from '../store/store.service';

const ZENDESK_INIT_DELAY = 3000;

@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class ZendeskService {
  private _apiService = inject(ApiService);
  private _store = inject(StoreService);

  async init(): Promise<void> {
    switch (Capacitor.getPlatform()) {
      case 'ios':
        await ZendeskMessaging.initialize({
          channelKey: environment.zendesk.iosKey,
        }).then(async () => {
          this.login();
          await this.mobileNotifications();
        });
        break;
      case 'android':
        await ZendeskMessaging.initialize({
          channelKey: environment.zendesk.androidKey,
        }).then(async () => {
          this.login();
          await this.mobileNotifications();
        });
        break;
      default:
        await ZendeskMessaging.initialize({
          channelKey: environment.zendesk.webKey,
        }).then(() => this.login());
        break;
    }
  }

  async logout(): Promise<void> {
    await ZendeskMessaging.userLogout();
  }

  async show(): Promise<void> {
    await ZendeskMessaging.showMessaging();
  }

  private async mobileNotifications(): Promise<void> {
    const res = await FirebaseMessaging.getToken();
    await ZendeskMessaging.registerNotificationToken({ token: res.token });
  }

  private login(): void {
    this.getCustomerToken()
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (data) => {
          const { token } = data as any;
          setTimeout(() => {
            ZendeskMessaging.userLogin({ userJwt: token });
          }, ZENDESK_INIT_DELAY);
        },
      });
  }

  private getCustomerToken(): Observable<string | Record<string, any> | null> {
    let firstName = '';
    let lastName = '';

    this._store
      .getAccountDetails()
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (x) => {
          firstName = x.firstName;
          lastName = x.lastName;
        },
      });

    return this._apiService
      .post(
        ZENDESK_ENDPOINT,
        {
          firstName: firstName,
          lastName: lastName,
        },
        { showLoading: false }
      )
      .pipe(map((res) => res.data));
  }
}
