import { createAction, props } from '@ngrx/store';
import { MessagesState } from '.';

export enum MessagesStateActionTypes {
  MessagesSubscribeSuccess = '[Order Messages] Subscribe Success',
  MessagesSubscribePending = '[Order Messages] Subscribe Pending',
}

export const MessagesSubscribeSuccess = createAction(
  MessagesStateActionTypes.MessagesSubscribeSuccess,
  props<{ payload: MessagesState }>()
);
export const MessagesSubscribePending = createAction(
  MessagesStateActionTypes.MessagesSubscribePending,
  props<{ payload: MessagesState }>()
);
