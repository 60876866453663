import { Component, Input } from '@angular/core';
import { IonFooter } from '@ionic/angular/standalone';

@Component({
  selector: 'app-customer-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  standalone: true,
  imports: [IonFooter],
})
export class FooterComponent {
  @Input() classes = '';
  @Input() type: 'nav' | 'button' | 'message' | 'text' = 'button';

  public get footerClasses(): string[] {
    return [this.classes, `${this.type}-footer`];
  }
}
