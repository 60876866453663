import { Injectable } from '@angular/core';
import { Capacitor } from '@capacitor/core';
import { environment } from '@environments/environment';
import Smartlook from 'smartlook-client';

@Injectable({
  providedIn: 'root',
})
export class SmartLookWebService {
  init(): boolean {
    return Smartlook.init(environment.smartlook.webKey, {
      advancedNetwork: {
        allowedUrls: [
          new RegExp(`^${environment.externalLinks.webApp}`),
          new RegExp(`^${environment.apiPath}`),
          new RegExp(`^${environment.zendesk.customerLink}`),
        ],
      },
    });
  }

  userSetup(userId: string, fullName: string, userEmail: string): void {
    Smartlook.identify(userId, {
      name: fullName,
      email: userEmail,
    });

    Smartlook.properties({ platformType: Capacitor.getPlatform() });

    return;
  }

  stop(): boolean {
    return Smartlook.disable();
  }
}
