import { inject, Injectable, Injector } from '@angular/core';
import { FirebaseError } from '@angular/fire/app';
import {
  ConfirmationResult,
  EmailAuthProvider,
  User,
  UserCredential,
} from '@angular/fire/auth';
import { Capacitor } from '@capacitor/core';
import { LoadingController } from '@ionic/angular/standalone';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import {
  AnalyticsService,
  ApiService,
  AppsFlyerService,
  AuthService,
  DatabaseService,
  DialogService,
  LoggingService,
  NavigationService,
  StorageService,
  StoreService,
} from '@services';
import { PlatformType, SegmentEventType } from '@sudshare/custom-node-package';
import { isNewGoogleAccount, loadCredits, LOCAL_STORAGE_ITEMS } from '@utils';
import { ForbiddenError } from 'app/services/error-handling/base-error';
import { trackEvent } from 'app/utils/track-event';
import {
  catchError,
  concatMap,
  filter,
  from,
  map,
  mergeMap,
  Observable,
  of,
  switchMap,
  tap,
} from 'rxjs';
import {
  AuthActionTypes,
  LoginFailure,
  LoginPending,
  LoginSuccess,
} from './auth.actions';

import { MARKETING_DOC_UPDATE, PatchDataType } from '@utils';
import { getUTMValuesFromCookie } from 'app/utils/cookie-tracking';

const LOG_TAG = '[AuthEffects]';

@UntilDestroy()
@Injectable()
export class AuthEffects {
  private _actions = inject(Actions);
  private _authService = inject(AuthService);
  private _navService = inject(NavigationService);
  private _storage = inject(StorageService);
  private _storeService = inject(StoreService);
  private _apiService = inject(ApiService);
  private _appsFlyerService = inject(AppsFlyerService);
  private _analytics = inject(AnalyticsService);
  private _databaseService = inject(DatabaseService);
  private _dialogService = inject(DialogService);
  private _loadingCtrl = inject(LoadingController);
  private _logger = inject(LoggingService);
  private _injector = inject(Injector);
  constructor() {
    this._authService.user$.pipe(untilDestroyed(this)).subscribe((user) => {
      if (user) {
        this._storeService.updateUser({
          status: 'success',
          user: { ...user },
          error: null,
        });
      }
    });
  }

  checkWithEmail$ = createEffect(
    (): Observable<void> =>
      this._actions.pipe(
        ofType(AuthActionTypes.CheckEmail),
        switchMap(({ email }) =>
          from(
            this._authService.checkEmail(email).then((res) => {
              if (res.length === 0) {
                this._navService.forwardWithOptions('auth/choose-password', {
                  emailAddress: email,
                });
                return;
              }
              // Commented out the code as we are not gonna be using the google auth method for Beta release
              // if (res.indexOf(GoogleAuthProvider.GOOGLE_SIGN_IN_METHOD) != -1) {
              //   if (Capacitor.isNativePlatform()) {
              //     return this._storeService.googleMobileLogin();
              //   } else {
              //     return this._authService.googleWeb();
              //   }
              // }

              if (
                res.indexOf(EmailAuthProvider.EMAIL_PASSWORD_SIGN_IN_METHOD) !=
                -1
              ) {
                this._navService.forwardWithOptions('auth/enter-password', {
                  emailAddress: email,
                });
                return;
              }
            })
          )
        ),
        catchError((err) => {
          throw new ForbiddenError(this.handleAuthErrors(err), err.stack);
        })
      ),
    { dispatch: false }
  );

  loginWithEmail$ = createEffect(
    (): Observable<Action> =>
      this._actions.pipe(
        ofType(AuthActionTypes.LoginEmail),
        switchMap(({ email, password }) => {
          this._dialogService.showLoading();
          return from(this._authService.emailLogin(email, password)).pipe(
            map((r) => {
              this._dialogService.dismissLoading(this._loadingCtrl);
              this.handleMarketingParamsAndEvents(r.user.uid, 'email');
              return LoginSuccess({
                payload: {
                  status: 'success',
                  user: Object.freeze({ ...r.user }),
                  error: null,
                },
              });
            }),
            catchError((e) => {
              this._dialogService.dismissLoading(this._loadingCtrl);
              return of(
                LoginFailure({
                  payload: { status: 'error', user: null, error: e },
                })
              );
            })
          );
        })
      )
  );

  loginAdmin$ = createEffect(
    (): Observable<Action> =>
      this._actions.pipe(
        ofType(AuthActionTypes.LoginAdmin),
        switchMap(({ token }) =>
          from(this._authService.adminLogin(token)).pipe(
            tap(
              async () =>
                await this._storage.setItem(
                  LOCAL_STORAGE_ITEMS.supportUser,
                  'true'
                )
            ),
            map((r) =>
              LoginSuccess({
                payload: {
                  status: 'success',
                  user: Object.freeze({ ...r.user }),
                  error: null,
                },
              })
            ),
            catchError((e) =>
              of(
                LoginFailure({
                  payload: { status: 'error', user: null, error: e },
                })
              )
            )
          )
        )
      )
  );

  verifyAccountWeb$ = createEffect(
    (): Observable<ConfirmationResult> =>
      this._actions.pipe(
        ofType(AuthActionTypes.VerifyAccountWeb),
        switchMap(({ phoneNumber, isResend }) =>
          from(this._authService.verifyAccountWeb(phoneNumber)).pipe(
            tap(({ verificationId }) => {
              this._navService.forwardWithOptions('auth/confirm-phone', {
                webConfirm: verificationId,
                phoneNumber: phoneNumber,
                isResend: isResend,
              });
            }),
            catchError((err) => {
              throw new ForbiddenError(this.handleAuthErrors(err), err.stack);
            })
          )
        )
      ),
    { dispatch: false }
  );

  linkPhone$ = createEffect(
    (): Observable<void> =>
      this._actions.pipe(
        ofType(AuthActionTypes.LinkPhone),
        switchMap(({ phoneNumber }) => {
          return from(this._authService.getUser()).pipe(
            filter((user): user is User => user !== null),
            switchMap((user) =>
              from(this._authService.linkPhoneNumber(user, phoneNumber)).pipe(
                tap(() => {
                  this._navService.forwardWithOptions('auth/confirm-phone', {
                    linkPhone: true,
                    phoneNumber: phoneNumber,
                  });
                }),
                catchError((err) => {
                  throw new ForbiddenError(
                    this.handleAuthErrors(err),
                    err.stack
                  );
                })
              )
            )
          );
        })
      ),
    { dispatch: false }
  );

  confirmLinkPhone$ = createEffect(
    (): Observable<Action> =>
      this._actions.pipe(
        ofType(AuthActionTypes.ConfirmLinkPhone),
        switchMap(({ confirmationCode }) =>
          from(this._authService.confirmPhoneLink(confirmationCode)).pipe(
            tap((r) => {
              this.handleMarketingParamsAndEvents(r.user.uid, 'phone');
            }),
            map((r) =>
              LoginSuccess({
                payload: {
                  status: 'success',
                  user: Object.freeze({ ...r.user }),
                  error: null,
                },
              })
            ),
            catchError((e) =>
              of(
                LoginFailure({
                  payload: { status: 'error', user: null, error: e },
                })
              )
            )
          )
        )
      )
  );

  verifyAccountMobile$ = createEffect(
    () =>
      this._actions.pipe(
        ofType(AuthActionTypes.VerifyAccountMobile),
        tap({
          next: ({ verifyId, phoneNumber }) => {
            this._navService.forwardWithOptions('auth/confirm-phone', {
              mobileConfirm: verifyId,
              phoneNumber: phoneNumber,
            });
          },
        }),
        catchError((err) => {
          throw new ForbiddenError(this.handleAuthErrors(err), err.stack);
        })
      ),
    { dispatch: false }
  );

  confirmPhone$ = createEffect(
    (): Observable<Action> =>
      this._actions.pipe(
        ofType(AuthActionTypes.ConfirmPhone),
        switchMap(({ verifyId, confirmationCode }) =>
          from(
            this._authService.phoneVerifyCode(verifyId, confirmationCode)
          ).pipe(
            map((r) =>
              LoginSuccess({
                payload: {
                  status: 'success',
                  user: Object.freeze({ ...r.user }),
                  error: null,
                },
              })
            ),
            catchError((e) =>
              of(
                LoginFailure({
                  payload: { status: 'error', user: null, error: e },
                })
              )
            )
          )
        )
      )
  );

  loginWithGoogleWeb$ = createEffect(
    (): Observable<Action> =>
      this._actions.pipe(
        ofType(AuthActionTypes.LoginGoogleWeb),
        switchMap(() =>
          from(this._authService.googleWebFinish()).pipe(
            filter((r): r is UserCredential => r !== null),
            map((r) => {
              if (isNewGoogleAccount(r?.user)) {
                this.populateBasicInfoPage(r);
                return LoginPending({
                  payload: { status: 'pending', user: null, error: null },
                });
              } else {
                this.handleMarketingParamsAndEvents(r.user.uid, 'social');
                return LoginSuccess({
                  payload: {
                    status: 'success',
                    user: Object.freeze({ ...r?.user }),
                    error: null,
                  },
                });
              }
            }),
            catchError((e) =>
              of(
                LoginFailure({
                  payload: { status: 'error', user: null, error: e },
                })
              )
            )
          )
        )
      )
  );

  loginWithGoogleMobile$ = createEffect(
    (): Observable<Action> =>
      this._actions.pipe(
        ofType(AuthActionTypes.LoginGoogleMobile),
        switchMap(() =>
          from(this._authService.googleMobile()).pipe(
            filter((r): r is UserCredential => r !== null),
            map((r) => {
              if (isNewGoogleAccount(r?.user)) {
                this.populateBasicInfoPage(r);
                return LoginPending({
                  payload: { status: 'pending', user: null, error: null },
                });
              } else {
                return LoginSuccess({
                  payload: {
                    status: 'success',
                    user: Object.freeze({ ...r?.user }),
                    error: null,
                  },
                });
              }
            }),
            catchError((e) => {
              if (
                e.code === 'auth/user-cancelled' ||
                e.message.includes('12501')
              ) {
                return of(
                  LoginPending({
                    payload: { status: 'pending', user: null, error: null },
                  })
                );
              }
              return of(
                LoginFailure({
                  payload: { status: 'error', user: null, error: e },
                })
              );
            })
          )
        )
      )
  );

  handleLoginSuccess$ = createEffect(
    (): Observable<boolean> =>
      this._actions.pipe(
        ofType(AuthActionTypes.LoginSuccess, AuthActionTypes.LoggedIn),
        concatMap(
          ({
            payload: {
              user: { uid, email, providerId },
            },
          }) => {
            this._storage.setItem(LOCAL_STORAGE_ITEMS.userId, uid);
            loadCredits(this._apiService, this._storeService, this._injector);

            this._analytics.trackEvent(uid, SegmentEventType.Login, {
              userType: 'Customer',
              email: email,
              loginMethod: providerId,
            });
            return of(true);
          }
        )
      ),
    { dispatch: false }
  );

  handleLoginError$ = createEffect(
    (): Observable<boolean> =>
      this._actions.pipe(
        ofType(AuthActionTypes.LoginFailure),
        concatMap(
          ({ payload: { error } }: { payload: { error: FirebaseError } }) => {
            throw new ForbiddenError(this.handleAuthErrors(error), error.stack);
          }
        )
      ),
    { dispatch: false }
  );

  logout$ = createEffect(
    (): Observable<boolean> =>
      this._actions.pipe(
        ofType(AuthActionTypes.Logout),
        mergeMap(() =>
          from(this._databaseService.unsubscribeAll()).pipe(
            mergeMap(() =>
              from(this._storage.clearStorage()).pipe(
                mergeMap(() => from(this._authService.signOut()))
              )
            )
          )
        ),
        tap(() => this._navService.navRoot('')),
        tap(() => window.location.reload()),
        map(() => true)
      ),
    { dispatch: false }
  );

  private async appsFlyerInit(userId: string): Promise<any> {
    try {
      await this._appsFlyerService.setCustomerId(userId);
      return await this._appsFlyerService.getAppsFlyerData();
    } catch (error) {
      console.error('Error fetching AppsFlyer data:', error);
      return {};
    }
  }

  private populateBasicInfoPage(r: UserCredential) {
    const fullName = r?.user.displayName?.split(' ');

    this._navService.forwardWithOptions('auth/basic-info', {
      accountType: 'Google',
      firstName: fullName?.[0] || '',
      lastName: fullName?.[1] || '',
      phoneNumber: r?.user.phoneNumber,
      emailAddress: r?.user.email,
      userId: r?.user.uid,
    });
  }

  private handleAuthErrors(error: FirebaseError): string {
    switch (error.code) {
      case 'auth/user-not-found':
        return 'Account not found, please create one';
      case 'auth/wrong-password':
        return 'Incorrect password, please try again';
      case 'auth/missing-password':
        return 'Password field is empty, please try again';
      case 'auth/web-storage-unsupported':
        return 'Please enable third-party cookies in your browser';
      case 'auth/internal-error':
        return 'Unexpected issue, please try in a few minutes';
      case 'auth/invalid-phone-number':
        return 'Invalid phone number, must be a US number';
      case 'auth/email-already-exists':
        return 'Email already in use, please sign-in instead';
      case 'auth/phone-number-already-exists':
        return 'Phone number already in use, please sign-in instead';
      case 'auth/weak-password':
        return 'Please use a stronger password and try again';
      case 'auth/user-disabled':
        return 'Your account has been suspended, please contact support';
      case 'auth/too-many-requests':
        return 'Please try again in 5 mins, the system is overwhelmed';
      default:
        return error.message;
    }
  }

  private async handleMarketingParamsAndEvents(
    userId: string,
    loginMethod: string
  ) {
    try {
      const ActiveUTMS = getUTMValuesFromCookie();
      const appsFlyerData =
        Capacitor.getPlatform() !== 'web'
          ? await this.appsFlyerInit(userId)
          : {};

      const patchData: PatchDataType = {};
      if (ActiveUTMS.utmValues) {
        patchData['QueryParams'] = ActiveUTMS.utmValues;
      }

      if (ActiveUTMS.OriginalLink) {
        patchData['OriginalLink'] = ActiveUTMS.OriginalLink;
      }

      if (appsFlyerData) {
        patchData['AppsFlyer'] = appsFlyerData;
      }

      this._apiService
        .patch(
          MARKETING_DOC_UPDATE,
          {
            PlatformType:
              Capacitor.getPlatform() === 'ios'
                ? PlatformType.ios
                : Capacitor.getPlatform() === 'android'
                ? PlatformType.android
                : PlatformType.web,
            ...patchData,
          },
          false
        )
        .subscribe({
          complete: async () => {
            await this._storage.removeItem(LOCAL_STORAGE_ITEMS.webLink);
            await this._storage.removeItem(LOCAL_STORAGE_ITEMS.marketing);

            trackEvent({
              eventData: {
                event: 'AccountLoggedIn',
                userId: userId,
                loginMethod,
              },
            });
          },
          error: async () => {
            await this._storage.removeItem(LOCAL_STORAGE_ITEMS.webLink);
            await this._storage.removeItem(LOCAL_STORAGE_ITEMS.marketing);
          },
        });
    } catch (error) {
      this._logger.logError(
        LOG_TAG,
        'handleMarketingParamsAndEvents unable to set MarketingData: ',
        error
      );
    }
  }
}
