import { createAction, props } from '@ngrx/store';
import { ActiveOrdersState } from '.';

export enum ActiveOrderActionTypes {
  ActiveOrdersSubscribeSuccess = '[Active Orders] Subscribe Success',
  ActiveOrdersSubscribePending = '[Active Orders] Subscribe Pending',
}

export const ActiveOrdersSubscribeSuccess = createAction(
  ActiveOrderActionTypes.ActiveOrdersSubscribeSuccess,
  props<{ payload: ActiveOrdersState }>()
);
export const ActiveOrdersSubscribePending = createAction(
  ActiveOrderActionTypes.ActiveOrdersSubscribePending,
  props<{ payload: ActiveOrdersState }>()
);
