import { createSelector } from '@ngrx/store';
import { StoreState } from '../store.state';
import { LaundryProState } from './laundry-pro.reducer';

const laundryProState = (state: StoreState) => state.laundryPro;

export const getLaundryPros = createSelector(
  laundryProState,
  (state: LaundryProState) => state
);
