<poplin-header
  [showClose]="true"
  (eventEmitter)="onClose()"
  [title]="laundryProInfoTitle"
  class="block-header"
></poplin-header>
<ion-content class="interior-page">
  <ion-grid>
    <ion-row>
      <ion-col size="12">
        <p>
          When placing an order you can designate "Preferred Laundry Pros" from
          a list of previously used Laundry Pros, and prioritize them. Only the
          Laundry Pros you select as Preferred when placing your order will be
          given preferred status.
        </p>
        <p>
          Your Preferred Laundry Pros are notified first about your order, in
          order of the priority you select, with your #1 Preferred Laundry Pro
          given an exclusive opportunity to accept your order for a period of
          time.
        </p>
        <p>
          Designating Preferred Laundry Pros dramatically increases the
          likelihood that you'll be assigned one of your Preferred Laundry Pros,
          but because Laundry Pro's work load and schedules vary it is not a
          guarantee.
        </p>
        <p>
          If no Preferred Laundry Pro is available, the next available Laundry
          Pro will be offered your order. All Poplin's Laundry Pros are
          professionals, vetted, and utilize best practices.
        </p>
        <p>
          You can only select a Preferred Laundry Pro on your first order if
          you've been referred to a Laundry Pro.
        </p>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>
