import { CommonModule } from '@angular/common';
import { Component, inject, OnInit } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { FooterComponent } from '@components';
import { IonFooter, IonRouterOutlet } from '@ionic/angular/standalone';
import { NavigationService, RouterTrackingService } from '@services';
import { IconModule } from '@sudshare/custom-design-package';

@Component({
  selector: 'app-tab-layout',
  templateUrl: './tab-layout.component.html',
  styleUrls: ['./tab-layout.component.scss'],
  standalone: true,
  imports: [
    FooterComponent,
    IonRouterOutlet,
    IonFooter,
    RouterModule,
    IconModule,
    CommonModule,
  ],
})
export class TabLayoutComponent implements OnInit {
  private _router = inject(Router);
  private _navService = inject(NavigationService);
  private _routerTracking = inject(RouterTrackingService);

  currentRoute = this._routerTracking.removeQueryParams(this._router.url);
  navItems = this.buildNavItems();

  ngOnInit(): void {
    this.currentRoute = this._routerTracking.removeQueryParams(
      this._router.url
    );

    this.navItems = this.buildNavItems();
  }

  trackNavItems(
    index: number,
    navItemObject: {
      label: string;
      icon: string;
      iconColor: string;
      isActive: boolean;
    }
  ): string {
    return `${navItemObject.label}-${index}`;
  }

  navigateTo(ev: MouseEvent, route: string): void {
    const current = this._routerTracking.currentRoute;
    ev.preventDefault();
    ev.stopPropagation();

    if (current && current === route) {
      return;
    }

    if (current === '/laundry' || !current) {
      switch (route) {
        case '/past-orders':
        case '/account':
        case '/referrals/select-type':
          this._navService.forwardWithOptions(route, {
            animationDirection: 'forward',
          });
          break;

        default:
          this._navService.forwardWithOptions(route, {
            animationDirection: 'back',
          });
          break;
      }
    }

    if (current === '/past-orders') {
      switch (route) {
        case '/account':
        case '/referrals/select-type':
          this._navService.forwardWithOptions(route, {
            animationDirection: 'forward',
          });
          break;

        default:
          this._navService.forwardWithOptions(route, {
            animationDirection: 'back',
          });
          break;
      }
    }

    if (current === '/account') {
      switch (route) {
        case '/referrals/select-type':
          this._navService.forwardWithOptions(route, {
            animationDirection: 'forward',
          });
          break;

        default:
          this._navService.forwardWithOptions(route, {
            animationDirection: 'back',
          });
          break;
      }
    }

    if (current === '/referrals/select-type') {
      switch (route) {
        case '/laundry':
        case '/past-orders':
        case '/account':
          this._navService.forwardWithOptions(route, {
            animationDirection: 'back',
          });
          break;

        default:
          this._navService.forwardWithOptions(route, {
            animationDirection: 'forward',
          });
          break;
      }
    }
  }
  private buildNavItems() {
    return [
      {
        route: '/laundry',
        label: 'Laundry',
        icon: 'shirts_custom',
        iconColor:
          this.currentRoute === '/laundry'
            ? '--footer-nav-svg-active-color'
            : '--footer-nav-svg-color',
        isActive: this.currentRoute === '/laundry' ? true : false,
      },
      {
        route: '/past-orders',
        label: 'Orders',
        icon: 'ticket_custom',
        iconColor:
          this.currentRoute === '/past-orders'
            ? '--footer-nav-svg-active-color'
            : '--footer-nav-svg-color',
        isActive: this.currentRoute === '/past-orders' ? true : false,
      },
      {
        route: '/account',
        label: 'Account',
        icon: 'person',
        iconColor:
          this.currentRoute === '/account'
            ? '--footer-nav-svg-active-color'
            : '--footer-nav-svg-color',
        isActive: this.currentRoute === '/account' ? true : false,
      },
      {
        route: '/referrals/select-type',
        label: 'Referrals',
        icon: 'gift',
        iconColor:
          this.currentRoute === '/referrals/select-type'
            ? '--footer-nav-svg-active-color'
            : '--footer-nav-svg-color',
        isActive: this.currentRoute === '/referrals/select-type' ? true : false,
      },
    ];
  }
}
