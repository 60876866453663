import { FirebaseError } from '@angular/fire/app';
import { User } from '@angular/fire/auth';
import { Action, createReducer, on } from '@ngrx/store';
import { STORE_STATUS, StoreStatusOptions } from '@utils';
import {
  CheckEmail,
  ConfirmPhone,
  LoggedIn,
  LoginAdmin,
  LoginEmail,
  LoginFailure,
  LoginGoogleMobile,
  LoginGoogleWeb,
  LoginPending,
  LoginSuccess,
  UpdateAuthUser,
  VerifyAccountMobile,
  VerifyAccountWeb,
} from './auth.actions';

export interface AuthState {
  user: User | null;
  error: FirebaseError | null;
  status: STORE_STATUS;
}

const initialState: AuthState = {
  user: null,
  error: null,
  status: StoreStatusOptions.PENDING,
};

const authReducer = createReducer(
  initialState,

  on(
    CheckEmail,
    LoginEmail,
    VerifyAccountWeb,
    VerifyAccountMobile,
    ConfirmPhone,
    LoginGoogleWeb,
    LoginGoogleMobile,
    LoginAdmin,
    (state) => ({
      ...state,
      status: StoreStatusOptions.LOADING,
    })
  ),

  on(LoginSuccess, LoggedIn, (state, { payload }) => ({
    ...state,
    status: StoreStatusOptions.SUCCESS,
    user: payload.user,
  })),

  on(LoginPending, (state) => ({
    ...state,
    status: StoreStatusOptions.PENDING,
    user: null,
    error: null,
  })),

  on(LoginFailure, (state, { payload }) => ({
    ...state,
    status: StoreStatusOptions.ERROR,
    error: payload.error,
  })),

  on(UpdateAuthUser, (state, { payload }) => ({
    ...state,
    user: payload.user,
  }))
);

export function AuthReducer(
  state: AuthState | undefined,
  action: Action
): AuthState {
  return authReducer(state, action);
}
