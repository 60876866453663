import { Component, Injector, OnInit, inject } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Capacitor } from '@capacitor/core';
import { environment } from '@environments/environment';
import { IonApp, IonRouterOutlet } from '@ionic/angular/standalone';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {
  LoggingService,
  RouterTrackingService,
  ScriptService,
  StorageService,
  StoreService,
} from '@services';
import { concatMap, filter } from 'rxjs';
import { register } from 'swiper/element/bundle';
import { LOCAL_STORAGE_ITEMS, QueryParams, lazyService } from './utils';
import {
  storeUTMCodesInCookie,
  trySendGA4PageView,
} from './utils/cookie-tracking';

// Global
register();

const LOG_TAG = '[AppComponent]';

// Lazy Services
const DeepLinkImport = () => import('@services').then((m) => m.DeepLinkService);
const AuthImport = () => import('@services').then((m) => m.AuthService);
const AnalyticsImport = () =>
  import('@services').then((m) => m.AnalyticsService);
const AppsFlyerImport = () =>
  import('@services').then((m) => m.AppsFlyerService);
const SmartlookMobile = () =>
  import('@services').then((m) => m.SmartLookMobileService);
const SmartLookWeb = () =>
  import('@services').then((m) => m.SmartLookWebService);
const MobileNetwork = () => import('@services').then((m) => m.NetworkService);

@UntilDestroy()
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
  standalone: true,
  imports: [IonApp, IonRouterOutlet],
})
export class AppComponent implements OnInit {
  private _deepLinks = lazyService(DeepLinkImport);
  private _auth = lazyService(AuthImport);
  private _analytics = lazyService(AnalyticsImport);
  private _appsFlyer = lazyService(AppsFlyerImport);
  private _smartLookMobile = lazyService(SmartlookMobile);
  private _smartLookWeb = lazyService(SmartLookWeb);
  private _mobileNetwork = lazyService(MobileNetwork);
  private _injector = inject(Injector);
  private _logger = inject(LoggingService);
  private _store = inject(StoreService);
  private _activatedRoute = inject(ActivatedRoute);
  private _scriptService = inject(ScriptService);
  private _routerTracking = inject(RouterTrackingService);
  private _router = inject(Router);

  ngOnInit(): void {
    this._router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event) => {
        if (environment.production) {
          trySendGA4PageView(event);
        }
      });

    this.initializeApp();
  }

  private initializeApp(): void {
    this._routerTracking.init();
    this.signedIn();
    this._analytics
      .pipe(
        untilDestroyed(this),
        concatMap(async (x) => x.init())
      )
      .subscribe();

    if (Capacitor.isNativePlatform()) {
      return this.initializeMobile();
    }

    this.processQueryParams();
    this.injectSmartBannerScript();
    this.initializeWeb();
  }

  private initializeWeb(): void {
    this._smartLookWeb.pipe(
      untilDestroyed(this),
      concatMap(async (x) => x.init())
    );
  }

  private initializeMobile(): void {
    this._store.createDeviceInfo();

    this._deepLinks
      .pipe(
        untilDestroyed(this),
        concatMap(async (x) => x.init())
      )
      .subscribe();

    this._appsFlyer
      .pipe(
        untilDestroyed(this),
        concatMap(async (x) => x.init())
      )
      .subscribe();

    this._smartLookMobile
      .pipe(
        untilDestroyed(this),
        concatMap(async (x) => x.init())
      )
      .subscribe({
        complete: () => {
          this._smartLookMobile
            .pipe(
              untilDestroyed(this),
              concatMap(async (x) => x.start())
            )
            .subscribe();
        },
      });

    this._mobileNetwork
      .pipe(
        untilDestroyed(this),
        concatMap(async (x) => x.init())
      )
      .subscribe();
  }

  private signedIn(): void {
    this._auth
      .pipe(
        untilDestroyed(this),
        concatMap(async (x) =>
          x
            .isLoggedIn()
            .then((user) => {
              return user ? this._store.isLoggedIn(user) : null;
            })
            .catch((e) => {
              return this._logger.logError(LOG_TAG, 'Internal Exception: ', e);
            })
        )
      )
      .subscribe();
  }

  private processQueryParams(): void {
    const queryParams: QueryParams = {};

    let processOnce = false;
    this._activatedRoute.queryParams
      .pipe(
        untilDestroyed(this),
        filter(() => !processOnce)
      )
      .subscribe({
        next: (params) => {
          for (const p in params) {
            if (Object.prototype.hasOwnProperty.call(params, p)) {
              queryParams[p] = params[p];
            }
          }
          if (Object.keys(queryParams).length !== 0) {
            processOnce = true;
            //on web, this sets the cookie shared between poplin.co and app.poplin.co
            storeUTMCodesInCookie(window.location.href);

            if (queryParams['referralId'] !== null) {
              const storageService: StorageService =
                this._injector.get<StorageService>(StorageService);
              storageService.setItem(
                LOCAL_STORAGE_ITEMS.referralId,
                queryParams['referralId']
              );
            }
          }
        },
      });
  }

  private async injectSmartBannerScript(): Promise<void> {
    try {
      await this._scriptService.load(['smart-banner']);
    } catch (e) {
      this._logger.logError(LOG_TAG, 'Script Failure: ', e);
    }
  }
}
