import { Action, createReducer, on } from '@ngrx/store';
import { MessagesDocumentWithId } from '@sudshare/custom-node-package';
import { STORE_STATUS, StoreStatusOptions } from '@utils';
import {
  MessagesSubscribePending,
  MessagesSubscribeSuccess,
} from './messages.actions';

export interface MessageData {
  orderId: string;
  orderMessages: Partial<MessagesDocumentWithId>[];
}

export interface MessagesState {
  messageData: MessageData[] | null;
  error: string | null;
  status: STORE_STATUS;
}

const initialState: MessagesState = {
  messageData: null,
  error: null,
  status: StoreStatusOptions.PENDING,
};

const messagesReducer = createReducer(
  initialState,

  on(MessagesSubscribeSuccess, (state, { payload }) => ({
    ...state,
    status: StoreStatusOptions.SUCCESS,
    messageData: payload.messageData,
    error: payload.error,
  })),

  on(MessagesSubscribePending, (state, { payload }) => ({
    ...state,
    status: StoreStatusOptions.PENDING,
    messageData: payload.messageData,
    error: payload.error,
  }))
);

export function MessageReducer(
  state: MessagesState | undefined,
  action: Action
): MessagesState {
  return messagesReducer(state, action);
}
