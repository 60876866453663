import { CommonModule } from '@angular/common';
import {
  Component,
  CUSTOM_ELEMENTS_SCHEMA,
  inject,
  Input,
  OnInit,
} from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { Capacitor } from '@capacitor/core';
import {
  IonCard,
  IonCol,
  IonContent,
  IonGrid,
  IonRow,
  ModalController,
} from '@ionic/angular/standalone';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AnalyticsService, NavigationService, StoreService } from '@services';
import {
  ButtonComponent,
  CheckboxModule,
  HeaderComponent,
  IconModule,
  ItemComponent,
  NotificationModule,
} from '@sudshare/custom-design-package';
import {
  convertToDollars,
  SegmentEventType,
} from '@sudshare/custom-node-package';
import {
  breakpoints,
  closeModalWithOptions,
  ModalNav,
  NewOrderRoutes,
  openPreAuthorizationModal,
} from '@utils';
import { trackEvent } from 'app/utils/track-event';
import { take } from 'rxjs';
@UntilDestroy()
@Component({
  selector: 'app-drawer-modal',
  standalone: true,
  imports: [
    CommonModule,
    IonContent,
    IonGrid,
    IonRow,
    IonCol,
    HeaderComponent,
    ButtonComponent,
    IconModule,
    IonCard,
    CheckboxModule,
    ReactiveFormsModule,
    ItemComponent,
    NotificationModule,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  templateUrl: './drawer-modal.component.html',
  styleUrls: ['./drawer-modal.component.scss'],
})
export class DrawerModalComponent implements OnInit {
  private _navService = inject(NavigationService);
  private _modalCtrl = inject(ModalController);
  private _formBuilder = inject(FormBuilder);
  private _analytics = inject(AnalyticsService);
  private _storeService = inject(StoreService);
  breakpoints = breakpoints;
  openPreAuthorizationModal = openPreAuthorizationModal;
  @Input() title = '';
  @Input() body = '';
  @Input() laundryProId = '';
  @Input() buttonLabel = '';
  @Input() showButton = false;
  @Input() secondButtonLabel = '';
  @Input() showSecondButton = false;
  @Input() showCard = false;
  @Input() cardTitle = '';
  @Input() cardBody = '';
  @Input() cardIcon = '';
  @Input() cardIconColor = '';
  @Input() cardRoute = '';
  @Input() showCheckboxes = false;
  @Input() isFeedbackModal = false;
  @Input() selectedRating = 0;
  @Input() feedBackModalLP = '';
  @Input() isEstimatedCostModal = false;
  @Input() showPreAuthLine = true;
  @Input() checkboxTitles: string[] = [];
  @Input() warning = '';
  @Input() bags = 0;
  @Input() oversizedItems = 0;
  @Input() oversizedItemPricing = 0;
  @Input() estimatedWeight = 0;
  @Input() estimatedWeightPricing = 0;
  @Input() minimumPrice = 0;
  @Input() minimumPriceApplied = false;
  @Input() pricePerPound = 0;
  @Input() coverage = '';
  @Input() coveragePricing = 0;
  @Input() accountCreditsApplied = '0.00';
  @Input() discountCreditsAmount = 0;
  @Input() discountCreditsDetails = '';
  @Input() discountPercentage = 0;
  @Input() discountPercentageAmount = 0;
  @Input() surgePrice = 0;
  @Input() orderSubTotal = 0;
  @Input() orderCustomerCharge = 0;
  @Input() orderTotal = 0;
  @Input() orderPreAuth = 0;
  @Input() orderTax = 0;
  @Input() navigation: ModalNav = { route: '' };
  @Input() isCommercial = false;
  @Input() trustAndSafetyFee = 0;
  @Input() deliveryType = '';
  @Input() recommendedTip = 0;

  trustAndSafetyFeeString = '';
  userId = '';
  isDisabled = true;
  formGroup: FormGroup = new FormGroup({
    BusinessAccount: new FormGroup({}),
  });

  ngOnInit(): void {
    if (this.showCheckboxes) {
      for (let i = 0; i < this.checkboxTitles.length; i++) {
        this.formGroup.addControl(
          i.toString(),
          this._formBuilder.control(false, [Validators.requiredTrue])
        );
      }
    }
    this.trustAndSafetyFeeString = convertToDollars(
      this.trustAndSafetyFee
    ).toFixed(2);
    this.getAccountDetails();
  }

  public onConfirm(): void {
    closeModalWithOptions(this._modalCtrl, { confirm: true }, 'confirm');
    if (this.showCheckboxes) {
      this.handleCheckboxNavigation();
    }
  }

  private handleCheckboxNavigation(): void {
    if (/review-order/.test(this.navigation.route)) {
      this._navService.back();
    } else if (
      this.navigation.route === NewOrderRoutes.PREFERRED_LP ||
      this.navigation.route === NewOrderRoutes.COVERAGE
    ) {
      this.trackUnsanitaryEvent();
      this._navService.forwardWithOptions(this.navigation.route, {
        ...this.navigation.state,
      });
    }
  }

  private trackUnsanitaryEvent(): void {
    //Old event logic. Will be removed
    const eventDescription = `${SegmentEventType.CheckoutStepCompleted} - ${SegmentEventType.UnsanitaryDisclaimer}`;
    this._analytics.createTrackEvent(eventDescription);
    // New event logic
    trackEvent({
      eventData: {
        event: 'CheckoutStepCompleted_UnsanitaryDisclaimer',
        userId: this.userId,
      },
    });
  }

  public onClose(): void {
    closeModalWithOptions(this._modalCtrl, { confirm: false }, 'cancel');
  }

  getAccountDetails(): void {
    this._storeService
      .getAccountDetails()
      .pipe(untilDestroyed(this), take(1))
      .subscribe({
        next: (user) => {
          if (user) {
            this.userId = user.userId;
          }
        },
      });
  }

  modalClick(): void {
    closeModalWithOptions(this._modalCtrl, { confirm: false }, 'route');
    this._navService.forward(this.cardRoute);
  }

  navigateToPreferredLaundryPros(): void {
    closeModalWithOptions(this._modalCtrl, { confirm: false }, 'route');
    this._navService.forwardWithOptions('/preferred-laundry-pros', {
      isFromRatePage: true,
    });
  }

  navigateToReferralsWithPreferredLaundryPro(): void {
    closeModalWithOptions(this._modalCtrl, { confirm: false }, 'route');
    this._navService.forwardWithOptions('/referrals/select-type', {
      laundryProId: this.laundryProId,
    });
  }

  async clickOpenPreAuthorizationModal(): Promise<void> {
    await openPreAuthorizationModal(this._modalCtrl);
  }
  async clickFourStarReferral(): Promise<void> {
    closeModalWithOptions(this._modalCtrl, { confirm: false }, 'route');
    this._navService.forward('/referrals/select-type');
  }

  async clickRatePoplin(): Promise<void> {
    closeModalWithOptions(this._modalCtrl, { confirm: false }, 'route');
    const userPlatform = Capacitor.getPlatform();
    this._navService.navRoot('/laundry');
    if (userPlatform == 'ios') {
      window.open(
        'https://apps.apple.com/us/app/poplin-got-laundry-allow-us/id1333675995'
      );
    } else if (userPlatform == 'android') {
      window.open(
        'https://play.google.com/store/apps/details?id=com.sudshare.sudshare'
      );
    } else {
      window.open('https://www.poplin.co', '_blank');
    }
  }

  trackCheckboxTitles(index: number, checkboxTitle: string): string {
    return `${checkboxTitle}-${index}`;
  }
}
