import { MemoizedSelector, createSelector } from '@ngrx/store';
import {
  OutstandingOrdersWithId,
  PartialNested,
} from '@sudshare/custom-node-package';
import { STORE_STATUS } from '@utils';
import { StoreState } from '../store.state';
import { ActiveOrdersState } from './active-orders.reducer';

const orderState = (state: StoreState) => state.activeOrders;

export const getActiveOrders = createSelector(
  orderState,
  (
    state: ActiveOrdersState
  ): {
    orderData: PartialNested<OutstandingOrdersWithId>[] | null;
    status: STORE_STATUS;
  } => ({
    orderData: state.orderData || [],
    status: state.status,
  })
);

export const getActiveOrderById = (
  orderId: string
): MemoizedSelector<
  StoreState,
  PartialNested<OutstandingOrdersWithId>[] | undefined,
  (s1: {
    orderData: PartialNested<OutstandingOrdersWithId>[] | null;
    status: STORE_STATUS;
  }) => PartialNested<OutstandingOrdersWithId>[] | undefined
> =>
  createSelector(getActiveOrders, (state) => {
    return state.orderData?.filter((x) => x.Id === orderId);
  });
