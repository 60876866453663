import { NgModule } from '@angular/core';
import { environment } from '@environments/environment';
import { EffectsModule } from '@ngrx/effects';
import {
  Action,
  ActionReducer,
  ActionReducerMap,
  MetaReducer,
  StoreModule,
} from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import {
  ActiveOrdersEffects,
  ActiveOrdersReducer,
  AuthActionTypes,
  AuthEffects,
  AuthReducer,
  DeviceEffects,
  DeviceReducer,
  LaundryProEffects,
  LaundryProReducer,
  LoadingEffects,
  logger,
  MessageReducer,
  MessagesEffects,
  NewOrderReducer,
  NewOrdersEffects,
  NewProfilerReducer,
  NewProfilesEffects,
  PastOrdersEffects,
  PastOrdersReducer,
  ProfileEffects,
  ProfileReducer,
  StoreState,
  UserEffects,
  UserReducer,
} from '.';
const resetStore = (reducer: ActionReducer<any>): ActionReducer<any> => {
  return function (state: any, action: Action): any {
    switch (action.type) {
      case AuthActionTypes.Logout: {
        return reducer(undefined, action);
      }
      default: {
        return reducer(state, action);
      }
    }
  };
};

const reducers: ActionReducerMap<StoreState> = {
  device: DeviceReducer,
  auth: AuthReducer,
  user: UserReducer,
  activeOrders: ActiveOrdersReducer,
  orderMessages: MessageReducer,
  profile: ProfileReducer,
  newOrder: NewOrderReducer,
  newProfile: NewProfilerReducer,
  laundryPro: LaundryProReducer,
  pastOrders: PastOrdersReducer,
};

const metaReducers: MetaReducer<any>[] = !environment.production
  ? [logger, resetStore]
  : [resetStore];

const effects = [
  AuthEffects,
  DeviceEffects,
  UserEffects,
  ProfileEffects,
  ActiveOrdersEffects,
  MessagesEffects,
  LoadingEffects,
  NewOrdersEffects,
  NewProfilesEffects,
  LaundryProEffects,
  PastOrdersEffects,
];

@NgModule({
  imports: [
    StoreModule.forRoot(reducers, { metaReducers: metaReducers }),
    !environment.production
      ? StoreDevtoolsModule.instrument({
          name: 'Customer',
          maxAge: 10,
          logOnly: true,
        })
      : [],
    EffectsModule.forRoot(effects),
  ],
  declarations: [],
  exports: [],
  providers: [],
})
export class AppStoreModule {}
