import {
  HttpEvent,
  HttpHandlerFn,
  HttpHeaders,
  HttpInterceptorFn,
  HttpRequest,
} from '@angular/common/http';
import { inject } from '@angular/core';
import { Capacitor } from '@capacitor/core';
import { environment } from '@environments/environment';
import { AuthService } from '@services';
import { CustomHttpHeader } from '@sudshare/custom-node-package';
import { Observable, from, mergeMap } from 'rxjs';
import { EMULATOR_AUTH_HEADER, LIVE_AUTH_HEADER } from './constants';

export const TokenInterceptor: HttpInterceptorFn = (
  req: HttpRequest<any>,
  next: HttpHandlerFn
): Observable<HttpEvent<any>> => {
  const _authService = inject(AuthService);

  return from(_authService.getToken()).pipe(
    mergeMap((token) => {
      const headers = buildHeaders(req.headers, token);
      return next(req.clone({ headers }));
    })
  );
};

function buildHeaders(initialHeaders: HttpHeaders, token: string | null) {
  let headers = initialHeaders
    .set(CustomHttpHeader.appPlatform, Capacitor.getPlatform())
    .set(CustomHttpHeader.appVersion, environment.version);

  if (token) {
    const authHeaderName = environment.emulator
      ? EMULATOR_AUTH_HEADER
      : LIVE_AUTH_HEADER;
    headers = headers.set(authHeaderName, `Bearer ${token}`);
  }

  return headers;
}
