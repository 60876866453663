import { inject, Injectable, Injector, NgZone } from '@angular/core';
import { Capacitor } from '@capacitor/core';
import { environment } from '@environments/environment';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import {
  AnalyticsService,
  ApiService,
  AppUpdateService,
  AuthService,
  DatabaseService,
  IterableService,
  NavigationService,
  PushNotificationService,
  ReferralService,
  SmartLookMobileService,
  SmartLookWebService,
  StatsigService,
  StorageService,
  StoreService,
  ZendeskService,
} from '@services';
import {
  CampaignCouponJwtClaims,
  CustomerDocument,
} from '@sudshare/custom-node-package';
import {
  COUPON_ENDPOINT,
  getClaimsFromJtw,
  LOCAL_STORAGE_ITEMS,
  MIGRATE_STRIPE_DATA,
  NEW_STRIPE_ACCOUNT,
  USER_DOC_UPDATE,
} from '@utils';
import { AdvertisingService } from 'app/services/advertising-service/advertising.service';
import { NotFoundError } from 'app/services/error-handling/base-error';

import {
  catchError,
  concatMap,
  exhaustMap,
  filter,
  from,
  map,
  mergeMap,
  Observable,
  of,
  Subscription,
  takeUntil,
  tap,
} from 'rxjs';
import { AuthActionTypes } from '../auth';
import {
  AccountDisabled,
  SubscribeFailure,
  SubscribeSuccess,
  UserActionTypes,
  UserDiscount,
} from './user.actions';

@Injectable()
export class UserEffects {
  private _injector = inject(Injector);
  private _actions = inject(Actions);
  private _database = inject(DatabaseService);
  private _navService = inject(NavigationService);
  private _analytics = inject(AnalyticsService);
  private _store = inject(StoreService);
  private _referralService = inject(ReferralService);
  private _apiService = inject(ApiService);
  private _storage = inject(StorageService);
  private _zone = inject(NgZone);
  private _authService = inject(AuthService);
  private _databaseService = inject(DatabaseService);
  private runAnalyticsOnce = true;
  private runServicesInitOnce = true;
  private goToMainPageOnce = true;
  private _advertiseService = inject(AdvertisingService);
  private _pushNotificationService = inject(PushNotificationService);

  subscribeToUser$ = createEffect((): Observable<Action> => {
    return this._actions.pipe(
      ofType(AuthActionTypes.LoginSuccess, AuthActionTypes.LoggedIn),
      exhaustMap(
        ({
          payload: {
            user: { uid },
          },
        }) => {
          const subscription = this._database.subscribeToUserDoc(uid).pipe(
            mergeMap((data) => {
              if (data === null) {
                return of(
                  SubscribeFailure({
                    payload: {
                      status: 'error',
                      docId: null,
                      docData: null,
                      discount: null,
                      error: 'No user document',
                    },
                  })
                );
              }
              if ((data as CustomerDocument).Blocked) {
                {
                  return of(
                    AccountDisabled({
                      payload: {
                        status: 'error',
                        docId: null,
                        discount: null,
                        docData: {
                          Blocked: (data as CustomerDocument).Blocked,
                        },
                        error: 'Account Disabled',
                      },
                    })
                  );
                }
              }
              return of(
                SubscribeSuccess({
                  payload: {
                    status: 'success',
                    docId: uid,
                    docData: data,
                    error: null,
                    discount: null,
                  },
                })
              );
            }),
            catchError(() => new Observable<Action>())
          );

          subscription
            .pipe(
              takeUntil(
                this._actions.pipe(
                  ofType(
                    AuthActionTypes.Logout,
                    UserActionTypes.AccountDisabled
                  )
                )
              )
            )
            .subscribe();
          return subscription;
        }
      )
    );
  });

  handleSuccess$ = createEffect(
    (): Observable<boolean> =>
      this._actions.pipe(
        ofType(UserActionTypes.SubscribeSuccess),
        filter(() => this.goToMainPageOnce),
        tap(async () => {
          this.goToMainPageOnce = false;
          if (this._advertiseService.shouldShowPermissionPage()) {
            this._navService.forwardWithOptions('/auth/privacy-page', {});
            return;
          }

          const showPnPage = await this._pushNotificationService
            .shouldShowPermissionPage()
            .catch(() => false);

          if (showPnPage) {
            this._navService.forward('/auth/notifications');
            return;
          }

          await this._pushNotificationService.init();
          return this._navService.navRoot('/laundry');
        })
      ),
    { dispatch: false }
  );

  handleNotFoundError$ = createEffect(
    (): Observable<boolean> =>
      this._actions.pipe(
        ofType(UserActionTypes.SubscribeFailure),
        tap(() => {
          this._store.logout();
        }),
        tap(() => {
          throw new NotFoundError(
            'Your account requires "Poplin for Laundry Pros" app, also in the app store'
          );
        })
      ),
    { dispatch: false }
  );

  webTracking$ = createEffect(
    () =>
      this._actions.pipe(
        ofType(UserActionTypes.SubscribeSuccess),
        filter(
          () => Capacitor.getPlatform() === 'web' && this.runAnalyticsOnce
        ),
        tap(
          ({
            payload: {
              docId,
              docData: { FirstName, LastName, ContactEmail, Phone },
            },
          }) => {
            this._analytics.identifyUser(docId, {
              name: `${FirstName} ${LastName}`,
              email: ContactEmail,
              phone: Phone,
              userType: 'Customer',
            });

            this.runAnalyticsOnce = false;
          }
        )
      ),
    { dispatch: false }
  );

  mobileTracking$ = createEffect(
    () =>
      this._actions.pipe(
        ofType(UserActionTypes.SubscribeSuccess),
        filter(
          () => Capacitor.getPlatform() !== 'web' && this.runAnalyticsOnce
        ),
        tap(
          async ({
            payload: {
              docId,
              docData: {
                FirstName,
                LastName,
                ContactEmail,
                Phone,
                DeviceTracking,
              },
            },
          }) => {
            const { AdvertisingId } = await import(
              '@capacitor-community/advertising-id'
            );
            const checkPermissions =
              await AdvertisingId.getAdvertisingStatus().catch(() => {
                return { status: 'Denied' };
              });

            const permissionGranted = async () => {
              const adInfo = await AdvertisingId.getAdvertisingId().catch(
                () => {
                  return { id: null };
                }
              );

              this._analytics.identifyUser(docId, {
                name: `${FirstName} ${LastName}`,
                email: ContactEmail,
                phone: Phone,
                userType: 'Customer',
                deviceTracking: true,
                advertisingId: adInfo.id,
              });

              if (!DeviceTracking) {
                this._store.updateCustomerDoc({ DeviceTracking: true }, false);
              }
            };

            const permissionDenied = () => {
              this._analytics.identifyUser(docId, {
                name: `${FirstName} ${LastName}`,
                email: ContactEmail,
                phone: Phone,
                userType: 'Customer',
                deviceTracking: false,
              });

              if (!DeviceTracking) {
                this._store.updateCustomerDoc({ DeviceTracking: false }, false);
              }
            };

            switch (checkPermissions.status) {
              case 'Denied':
              case 'Restricted':
                permissionDenied();
                break;
              case 'Not Determined':
                break;
              case 'Authorized':
                await permissionGranted();
                break;
            }

            this.runAnalyticsOnce = false;
          }
        )
      ),
    { dispatch: false }
  );

  userDocUpdate$ = createEffect(
    (): Observable<Subscription> =>
      this._actions.pipe(
        ofType(UserActionTypes.DocUpdate),
        map(({ payload: { doc, showLoading } }) => {
          return this._apiService
            .patch(USER_DOC_UPDATE, doc, showLoading)
            .subscribe();
        })
      ),
    { dispatch: false }
  );

  userAccountClose$ = createEffect(
    (): Observable<Subscription> =>
      this._actions.pipe(
        ofType(UserActionTypes.CloseAccount),
        map(({ payload: { doc, showLoading } }) => {
          return this._apiService
            .delete(USER_DOC_UPDATE, doc, showLoading)
            .subscribe({
              next: () => {
                this._store.logout();
              },
            });
        })
      ),
    { dispatch: false }
  );

  setupAfterSubscribe$ = createEffect(
    (): Observable<void> =>
      this._actions.pipe(
        ofType(UserActionTypes.SubscribeSuccess),
        filter(() => this.runServicesInitOnce),
        concatMap(
          async ({ payload: { docId, docData } }) =>
            await this.postLoginInit(docId, docData)
        )
      ),
    { dispatch: false }
  );

  accountDisabled$ = createEffect(
    (): Observable<boolean> =>
      this._actions.pipe(
        ofType(UserActionTypes.AccountDisabled),
        mergeMap(() =>
          from(this._databaseService.unsubscribeAll()).pipe(
            mergeMap(() =>
              from(this._storage.clearStorage()).pipe(
                mergeMap(() => from(this._authService.signOut()))
              )
            )
          )
        ),
        tap(() => this._navService.navRoot('/auth')),
        map(() => true)
      ),
    { dispatch: false }
  );

  private async postLoginInit(
    userId: string,
    userDocument: CustomerDocument
  ): Promise<void> {
    this._zone.run(async () => {
      // Run once on App Start
      this.runServicesInitOnce = false;

      // Initiate statsig
      const statsig: StatsigService =
        this._injector.get<StatsigService>(StatsigService);
      await statsig.init(userId, userDocument.SignupTime.toDate().toString());

      // Support
      const isAdminUser = await this._storage.getItem(
        LOCAL_STORAGE_ITEMS.supportUser
      );
      if (isAdminUser.value === 'true') {
        return;
      }
      // Platforms Specific
      if (Capacitor.getPlatform() !== 'web') {
        await this.mobileInit(
          userId,
          `${userDocument.FirstName} ${userDocument.LastName}`,
          userDocument.ContactEmail
        );
      } else {
        await this.webInit(
          userId,
          `${userDocument.FirstName} ${userDocument.LastName}`,
          userDocument.ContactEmail
        );
      }

      // Mobile Production Only
      if (environment.production && Capacitor.getPlatform() !== 'web') {
        const appUpdate: AppUpdateService =
          this._injector.get<AppUpdateService>(AppUpdateService);
        appUpdate.init();
        const zendeskService: ZendeskService =
          this._injector.get<ZendeskService>(ZendeskService);
        appUpdate.init();
        zendeskService.init();
      }

      await this.validateActiveCoupons();

      // All Platforms and Environments
      await this.createNewStripe();
      await this.migrateExistingStripe();
    });
  }

  private async validateActiveCoupons() {
    const apiService: ApiService = this._injector.get<ApiService>(ApiService);
    apiService
      .get(COUPON_ENDPOINT, false)
      .pipe(
        catchError((err) => {
          console.error(err);

          return of(null);
        })
      )
      .subscribe(async (response) => {
        if (response?.data?.coupon) {
          const discountInfo = (await getClaimsFromJtw(
            response.data.coupon,
            environment.coupon.publicKey
          )) as CampaignCouponJwtClaims;

          if (discountInfo) {
            const discount: UserDiscount = {
              provider: discountInfo.provider,
              jwt: response.data.coupon,
            };

            this._store.updateCampaignActiveCoupon(discount);
          }
        }
      });
  }

  private async webInit(userId: string, name: string, email: string) {
    const smartLookWeb: SmartLookWebService =
      this._injector.get<SmartLookWebService>(SmartLookWebService);
    const notification: PushNotificationService =
      this._injector.get<PushNotificationService>(PushNotificationService);
    smartLookWeb.userSetup(userId, name, email);
    notification.init();
    const zendeskService: ZendeskService =
      this._injector.get<ZendeskService>(ZendeskService);
    zendeskService.init();
  }

  private async mobileInit(userId: string, name: string, email: string) {
    const iterableService: IterableService =
      this._injector.get<IterableService>(IterableService);
    const smartLookMobile: SmartLookMobileService =
      this._injector.get<SmartLookMobileService>(SmartLookMobileService);

    await iterableService.init(userId).catch(() => {
      return false;
    });
    smartLookMobile.userSetup(userId, name, email);

    const { FirebaseAnalytics } = await import('@capacitor-firebase/analytics');
    const { FirebaseCrashlytics } = await import(
      '@capacitor-firebase/crashlytics'
    );

    await FirebaseAnalytics.setUserId({
      userId: userId,
    });
    await FirebaseCrashlytics.setUserId({
      userId: userId,
    });
  }

  private async createNewStripe(): Promise<void> {
    const isNewUser = await this._storage.getItem(LOCAL_STORAGE_ITEMS.newUser);

    if (isNewUser.value === 'true') {
      const apiService: ApiService = this._injector.get<ApiService>(ApiService);
      apiService
        .post(NEW_STRIPE_ACCOUNT, {}, { showLoading: false })
        .subscribe({
          complete: async () => {
            await this._storage.removeItem(LOCAL_STORAGE_ITEMS.newUser);
            await this.handleReferralRedeem();
            return;
          },
          error: () => {
            return;
          },
        });
    }

    return;
  }

  private async migrateExistingStripe(): Promise<void> {
    const isNewUser = await this._storage.getItem(LOCAL_STORAGE_ITEMS.newUser);
    if (isNewUser.value !== 'true') {
      const apiService: ApiService = this._injector.get<ApiService>(ApiService);
      apiService
        .post(
          MIGRATE_STRIPE_DATA,
          {},
          {
            showLoading: false,
          }
        )
        .subscribe({
          complete: async () => {
            await this.handleReferralRedeem();
            return;
          },
          error: () => {
            return;
          },
        });
    }

    return;
  }

  private async handleReferralRedeem() {
    const referralId = await this._storage.getItem(
      LOCAL_STORAGE_ITEMS.referralId
    );

    if (referralId.value && referralId.value !== 'undefined') {
      this._referralService.redeemReferral(referralId.value);
    }
  }
}
