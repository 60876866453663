import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { ApiService, StoreService } from '@services';
import { CustomerAPI } from '@sudshare/custom-node-package';
import { CUSTOMER_PATH, LAUNDRY_PRO_PATH, StoreStatusOptions } from '@utils';
import {
  exhaustMap,
  mergeMap,
  Observable,
  of,
  switchMap,
  withLatestFrom,
} from 'rxjs';
import { ActiveOrderActionTypes } from '../active-orders';
import { UserActionTypes } from '../user';
import { GetLaundryPros, LaundryProActionTypes } from './laundry-pro.actions';
import { LaundryProState } from './laundry-pro.reducer';
import { PastOrderActionTypes } from '../past-orders';

@Injectable()
export class LaundryProEffects {
  private _actions = inject(Actions);
  private _apiService = inject(ApiService);
  private _storeService = inject(StoreService);

  getLaundryPros$ = createEffect((): Observable<Action> => {
    return this._actions.pipe(
      ofType(
        UserActionTypes.SubscribeSuccess,
        ActiveOrderActionTypes.ActiveOrdersSubscribeSuccess,
        PastOrderActionTypes.RateAndTip
      ),
      exhaustMap(() => {
        return this._apiService
          .get<CustomerAPI.Response.PreferredAndBlockedLaundryPros>(
            LAUNDRY_PRO_PATH,
            false
          )
          .pipe(
            mergeMap(({ data }) => {
              return of(
                GetLaundryPros({
                  payload: {
                    laundryProData: {
                      preferred: data.preferred,
                      blocked: data.blocked,
                    },
                    error: null,
                    status: StoreStatusOptions.SUCCESS,
                  },
                })
              );
            })
          );
      })
    );
  });

  updateLaundryPros$ = createEffect((): Observable<Action> => {
    return this._actions.pipe(
      ofType(LaundryProActionTypes.UpdatePreferredAndBlocked),
      withLatestFrom(this._storeService.getLaundryPros()),
      switchMap(([, updateRequest]) => {
        this.updateLaundryPros(updateRequest);
        return new Observable<Action>();
      })
    );
  });

  private updateLaundryPros(req: LaundryProState): void {
    if (req.laundryProData?.preferred && req.laundryProData?.blocked) {
      const preferredIds = req.laundryProData?.preferred.map((lp) => lp.id);
      const blockedIds = req.laundryProData.blocked.map((lp) => lp.id);
      this._apiService
        .patch(
          CUSTOMER_PATH,
          {
            PreferredSudsters: preferredIds,
            BlockedSudsters: blockedIds,
          },
          false
        )
        .subscribe({
          next: () => {
            return;
          },
        });
    }
  }
}
