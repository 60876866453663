import { CommonModule, Location } from '@angular/common';
import {
  Component,
  CUSTOM_ELEMENTS_SCHEMA,
  EventEmitter,
  inject,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import {
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import {
  IonCol,
  IonGrid,
  IonModal,
  IonRow,
  ModalController,
} from '@ionic/angular/standalone';
import { UntilDestroy } from '@ngneat/until-destroy';
import { NavigationService } from '@services';

import {
  ButtonModule,
  CheckboxModule,
  IconModule,
  InputModule,
  SelectModule,
  TextareaModule,
} from '@sudshare/custom-design-package';
import {
  closeModal,
  CustomRegex,
  NewOrderRoutes,
  NewOrderState,
  ProfileRoutes,
} from '@utils';

@UntilDestroy()
@Component({
  selector: 'app-customer-pickup-location-form',
  standalone: true,
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [
    CommonModule,
    IonGrid,
    IonRow,
    IonCol,
    InputModule,
    TextareaModule,
    SelectModule,
    CheckboxModule,
    ButtonModule,
    ReactiveFormsModule,
    IonModal,
    IconModule,
  ],
  templateUrl: './pickup-location-form.component.html',
  styleUrls: ['./pickup-location-form.component.scss'],
})
export class PickupLocationFormComponent implements OnInit, OnChanges {
  private _navService = inject(NavigationService);
  private _location = inject(Location);
  private _modalCtrl = inject(ModalController);

  private state?: NewOrderState;

  @Input() address = '';
  @Input() pickupLocation = '';
  @Input() instructions = '';
  @Input() hasInstructions = false;
  @Input() flow = '';
  @Input() attemptedContinue = false;
  @Output() addressChange: EventEmitter<string> = new EventEmitter<string>();
  @Output() pickupLocationChange: EventEmitter<string> =
    new EventEmitter<string>();
  @Output() instructionsChange: EventEmitter<string> =
    new EventEmitter<string>();
  @Output() hasInstructionsChange: EventEmitter<boolean> =
    new EventEmitter<boolean>();

  pickupLocationForm: FormGroup = new FormGroup({});

  ngOnInit(): void {
    this.buildLocalForm();

    this.state = this._location.getState() as NewOrderState;
    if (this.state) {
      const {
        pickupSpot = {
          address: '',
          pickupLocation: '',
          hasInstructions: false,
          instructions: '',
        },
      } = this.state;
      const { address, pickupLocation, hasInstructions, instructions } =
        pickupSpot;

      if (pickupLocation && !this.pickupLocation) {
        this.pickupLocation = pickupLocation;
        this.pickupLocationForm.controls['PickupSpot'].setValue(
          this.pickupLocation
        );
      }

      if (hasInstructions && !this.hasInstructions) {
        this.hasInstructions = hasInstructions;
        this.pickupLocationForm.controls['instructionsCheckbox'].setValue(
          this.hasInstructions
        );
      }

      if (instructions && !this.instructions) {
        this.instructions = instructions;
        this.pickupLocationForm.controls['instructions'].setValue(
          this.instructions
        );
      }

      if (address && !this.address) {
        this.address = this.getAddress(address);
        this.pickupLocationForm.controls['address'].setValue(this.address);
        this.addressChange.emit(this.address);
      }
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes['pickupLocation']?.currentValue !==
        changes['pickupLocation']?.previousValue ||
      changes['hasInstructions']?.currentValue !==
        changes['hasInstructions']?.previousValue ||
      changes['instructions']?.currentValue !==
        changes['instructions']?.previousValue ||
      changes['address']?.currentValue !== changes['address']?.previousValue
    ) {
      this.buildLocalForm();
    }
  }

  async handleNavigationClick(): Promise<void> {
    const options = {
      profileName: this.state?.profileName,
      profileId: this.state?.profileId,
    };
    this._navService.forwardWithOptions(
      this.flow === 'NewOrder' ? NewOrderRoutes.ADDRESS : ProfileRoutes.ADDRESS,
      options
    );
  }

  handleChecked(ev: boolean): void {
    this.hasInstructions = ev;
    this.hasInstructionsChange.emit(this.hasInstructions);

    if (!this.hasInstructions) {
      this.instructions = '';
      this.instructionsChange.emit('');
    }
  }

  handleSelect(ev: Event): void {
    const value = (ev.target as HTMLSelectElement).value;
    this.pickupLocation = value;
    this.pickupLocationChange.emit(this.pickupLocation);
  }

  handleTextAreaEvent(ev: Event): void {
    const value = (ev.target as HTMLTextAreaElement).value;
    this.instructions = value;
    this.instructionsChange.emit(this.instructions);
  }

  getAddress(address: string | { Full: string }): string {
    if (typeof address === 'string') {
      return address;
    }

    return address.Full;
  }

  closeModal(): Promise<boolean> {
    return closeModal(this._modalCtrl);
  }

  private buildLocalForm(): void {
    this.pickupLocationForm = new FormGroup({
      address: new FormControl(this.address, [
        Validators.required,
        Validators.pattern(CustomRegex.FULL_ADDRESS),
      ]),
      instructions: new FormControl(this.instructions, [
        Validators.maxLength(250),
      ]),
      PickupSpot: new FormControl(this.pickupLocation, [Validators.required]),
      instructionsCheckbox: new FormControl(this.hasInstructions),
    });
  }
}
