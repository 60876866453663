import { inject, Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';
import {
  FirebaseMessaging,
  NotificationActionPerformedEvent,
  NotificationReceivedEvent,
} from '@capacitor-firebase/messaging';
import { Capacitor } from '@capacitor/core';
import type { PermissionState } from '@capacitor/core/types';
import { Badge } from '@capawesome/capacitor-badge';
import { environment } from '@environments/environment';
import { AlertOptions } from '@ionic/angular/standalone';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { DeepLinkService, IterableService } from '@services';
import {
  NotificationData,
  NotificationType,
  PlatformType,
} from '@sudshare/custom-node-package';
import {
  APP_INFO_ENDPOINT,
  IterableNotification,
  IterableObjectParams,
  NotificationContent,
} from '@utils';
import { ApiService } from '../api/api.service';
import { DialogService } from '../dialog/dialog.service';
import { LoggingService } from '../logging/logging.service';
import { StoreService } from '../store/store.service';

enum Platform {
  Mobile = 'mobile',
  Web = 'web',
}

const LOG_TAG = '[PushNotificationService]';
@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class PushNotificationService {
  private _injector = inject(Injector);
  private _storeService = inject(StoreService);
  private _apiService = inject(ApiService);
  private _route = inject(Router);
  private _logger = inject(LoggingService);
  private _deepLinkService = inject(DeepLinkService);
  private _iterableService = inject(IterableService);

  private _deviceState!: string;

  mobileBrowser = navigator.userAgent.match(
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i
  );
  async init(): Promise<boolean> {
    this._storeService
      .getDeviceInfo()
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (data) => {
          this._deviceState = data;
        },
      });

    if (Capacitor.isNativePlatform()) {
      return this.registerMobile().catch(() => false);
    } else {
      return this.registerWeb().catch(() => false);
    }
  }

  private registerWeb = async (): Promise<boolean> => {
    // Check if it's a mobile device using a browser app
    if (this.mobileBrowser && !('Notification' in window)) {
      return false;
    }

    if ('serviceWorker' in navigator || 'Notification' in window) {
      let requestAccepted = false;
      const checkPerm = await this.checkPermissions();
      let currentToken = '';

      switch (checkPerm) {
        case 'granted':
          currentToken = await this.getToken(true);
          if (currentToken) {
            await this.storeToken(
              currentToken,
              PlatformType.web,
              this._deviceState
            );
          }

          break;
        case 'denied':
          this.showAlert({
            id: 'notification-permission-alert',
            header: 'Permission Required',
            message:
              'It looks like you have blocked notifications on your browser. Please allow notifications in your browser settings.',
            buttons: [
              {
                id: 'notification-permission-cancel',
                text: 'Cancel',
                role: 'cancel',
              },
              {
                id: 'notification-permission-ok',
                text: 'Ok',
                role: 'ok',
              },
            ],
          });
          break;
        default: {
          const result = await this.requestPermissions();
          requestAccepted = result === 'granted';
        }
      }

      this.addNotificationListeners(Platform.Web);

      return checkPerm === 'granted' || requestAccepted;
    }

    return false;
  };

  private registerMobile = async (): Promise<boolean> => {
    let requestAccepted = false;
    const currentPlatform =
      Capacitor.getPlatform() === 'ios'
        ? PlatformType.ios
        : PlatformType.android;
    const checkPerm = await this.checkPermissions();
    const iterableService: IterableService =
      this._injector.get<IterableService>(IterableService);

    switch (checkPerm) {
      case 'granted': {
        const currentToken = await this.getToken(false);
        await this.storeToken(currentToken, currentPlatform, this._deviceState);
        await iterableService.updateToken(currentToken);

        break;
      }
      case 'denied':
        this.showAlert({
          id: 'notification-permission-alert',
          header: 'Permission Required',
          message:
            'It looks like you have blocked notifications on your device. Please allow notifications in your device settings.',
          buttons: [
            {
              id: 'notification-permission-cancel',
              text: 'Cancel',
              role: 'cancel',
            },
            {
              id: 'notification-permission-ok',
              text: 'Ok',
              role: 'ok',
            },
          ],
        });
        break;
      default: {
        const result = await this.requestPermissions();
        requestAccepted = result === 'granted';
      }
    }
    this.addNotificationListeners(Platform.Mobile);

    return checkPerm === 'granted' || requestAccepted;
  };

  checkPermissions = async (): Promise<PermissionState> => {
    const result = await FirebaseMessaging.checkPermissions();
    return result.receive;
  };

  shouldShowPermissionPage = async (): Promise<boolean> => {
    const checkPerm = await this.checkPermissions();
    return checkPerm === 'prompt' || checkPerm === 'prompt-with-rationale';
  };

  private requestPermissions = async () => {
    const result = await FirebaseMessaging.requestPermissions();
    return result.receive;
  };

  private getToken = async (web: boolean) => {
    if (web) {
      const result = await FirebaseMessaging.getToken({
        vapidKey: environment.messaging.vapidKey,
      }).catch((err) => {
        this._logger.logError(LOG_TAG, `Failed to retrieve web token`, err);
        return { token: '' };
      });
      return result.token;
    } else {
      const result = await FirebaseMessaging.getToken();

      return result.token;
    }
  };

  private storeToken = async (
    token: string,
    platform: string,
    deviceId: string
  ): Promise<void> => {
    const appVersion =
      platform === PlatformType.ios
        ? environment.iosVersion // user is on iOS
        : platform === PlatformType.android // user is on Android
        ? environment.androidVersion
        : environment.version; // user is on Web app

    this._apiService
      .post(
        APP_INFO_ENDPOINT,
        {
          platformType: platform,
          deviceId: deviceId,
          pushToken: token,
          appVersion: appVersion,
        },
        { showLoading: false }
      )
      .pipe(untilDestroyed(this))
      .subscribe();
  };

  private addNotificationListeners = async (platform: string) => {
    const iterableService: IterableService =
      this._injector.get<IterableService>(IterableService);

    await FirebaseMessaging.addListener('tokenReceived', async (event) => {
      const token = event.token;
      await iterableService.updateToken(token);

      await this.storeToken(token, platform, this._deviceState);
    });

    await FirebaseMessaging.addListener('notificationReceived', (event) => {
      this.displayNotificationReceived(event);
      this.ackNotification(event, platform);
    });

    await FirebaseMessaging.addListener(
      'notificationActionPerformed',
      (event) => {
        this.processNotificationReceived(event);
      }
    );
  };

  // Future Work Flow
  private processNotificationReceived = async (
    e: NotificationActionPerformedEvent
  ) => {
    const customPayload = e.notification.data as NotificationData;
    let customData: Record<string, any> = {
      type: '',
    };

    try {
      // android=string, ios=object
      const iterablePayloadNotification = e.notification
        .data as IterableNotification;
      if (iterablePayloadNotification.itbl) {
        const itbl = this.getAsObject(iterablePayloadNotification.itbl);
        const templateId = itbl.templateId;
        const campaignId = itbl.campaignId;
        const messageId = itbl.messageId;

        this._iterableService.trackPush(
          templateId,
          campaignId,
          messageId,
          false
        );
      }

      customData = JSON.parse(customPayload.data as unknown as string);
    } catch (error) {
      customData = customPayload as unknown as Record<string, any>;
    }

    switch (customPayload.type) {
      case NotificationType.chatMessages: {
        customData;
        break;
      }
      default: {
        const url = customData['url'] || customData['uri'];
        if (url) {
          this._deepLinkService.handleUrl(url, true);
        }

        break;
      }
    }
  };

  private displayNotificationReceived = async (
    e: NotificationReceivedEvent
  ) => {
    const data = e.notification.data as NotificationData;
    if (
      data.type === 'chatMessages' &&
      this._route.url.startsWith('/messaging/')
    ) {
      return;
    }

    if (e.notification.title && e.notification.body) {
      this.showNotification({
        header: e.notification.title,
        text: e.notification.body,
      });
    }
  };

  private ackNotification = async (
    e: NotificationReceivedEvent,
    platform: string
  ) => {
    if (platform === Platform.Mobile) {
      await Badge.clear();

      const notification = e.notification;
      if (notification.data) {
        const iterablePayload = notification.data as IterableNotification;

        if (iterablePayload.itbl) {
          const itbl = this.getAsObject(iterablePayload.itbl);

          // case PN from In-app message from iterable
          if (itbl.messageId === 'BACKGROUND_NOTIFICATION') {
            return;
          }

          const campaignId = itbl.campaignId;
          const templateId = itbl.templateId;
          const messageId = itbl.messageId;

          this._iterableService.trackPush(
            templateId,
            campaignId,
            messageId,
            true
          );
        }
      }

      if (notification) {
        await FirebaseMessaging.removeDeliveredNotifications({
          notifications: [notification],
        }).catch((err) => {
          this._logger.logError(LOG_TAG, `Failed to remove notification`, err);
        });
      }
    }
  };

  private async showAlert(options: AlertOptions): Promise<HTMLIonAlertElement> {
    const dialogService: DialogService =
      this._injector.get<DialogService>(DialogService);
    return await dialogService.showAlert(options);
  }

  private showNotification(opts: {
    header: string | undefined;
    text: string | undefined;
  }): void {
    const dialogService: DialogService =
      this._injector.get<DialogService>(DialogService);

    const notificationToast: NotificationContent = {
      showHeading: true,
      notificationHeading: opts.header,
      text: opts.text,
      showNotificationIcon: true,
      placement: 'toast',
      showButtonCTA: false,
      showLinkCTA: true,
      linkCTAText: 'Dismiss',
    };

    dialogService.showCustomToast(notificationToast);
  }

  private getAsObject(data: string | object): IterableObjectParams {
    if (!data) {
      return {} as IterableObjectParams;
    }

    if (typeof data === 'string') {
      try {
        return JSON.parse(data);
      } catch (error) {
        this._logger.logError(LOG_TAG, `Failed parsing string payload`, error);
        return {} as IterableObjectParams;
      }
    }

    return data as IterableObjectParams;
  }
}
