interface BaseErrorParams extends Error {
  readonly name: ErrorName;
  readonly details?: string | undefined;
  readonly statusCode?: number;
}

type ErrorName =
  | 'NETWORK_ISSUE'
  | 'UNPROCESSABLE_REQUEST'
  | 'FORBIDDEN_REQUEST'
  | 'UNSTABLE_CONNECTION'
  | 'NOT_FOUND'
  | 'UNKNOWN_ERROR';

export abstract class BaseError extends Error {
  override readonly name: ErrorName;
  override readonly message: string;
  override readonly stack?: string | undefined;
  readonly statusCode?: number;

  constructor(params: BaseErrorParams) {
    super(params.message);
    this.name = params.name;
    this.message = params.message;
    this.statusCode = params.statusCode;
    if (params.stack) {
      this.stack = params.stack;
    }
  }
}

export class NotFoundError extends BaseError {
  constructor(message = 'Resource not found!', stack?: string) {
    super({
      name: 'NOT_FOUND',
      statusCode: 404,
      message,
      stack,
    });
  }
}

export class NetWorkIssueError extends BaseError {
  constructor(
    message = 'A network issue occurred, please try again later.',
    stack?: string
  ) {
    super({
      name: 'NOT_FOUND',
      statusCode: 0,
      message,
      stack,
    });
  }
}

export class UnprocessableError extends BaseError {
  constructor(message = "Can't process this information!", stack?: string) {
    super({
      name: 'UNPROCESSABLE_REQUEST',
      statusCode: 422,
      message,
      stack,
    });
  }
}

export class UnstableConnectionError extends BaseError {
  constructor(
    message = 'Network connection is not stable. Please check your connection and try again.!',
    stack?: string
  ) {
    super({
      name: 'UNSTABLE_CONNECTION',
      statusCode: 504,
      message,
      stack,
    });
  }
}

export class ForbiddenError extends BaseError {
  constructor(message = 'Unauthorized action!', stack?: string) {
    super({
      name: 'FORBIDDEN_REQUEST',
      message,
      stack,
    });
  }
}

export class UnknownError extends BaseError {
  constructor(message = 'Unknown error!', stack?: string) {
    super({
      name: 'UNKNOWN_ERROR',
      message,
      stack,
    });
  }
}
