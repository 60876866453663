import {
  DatePipe,
  LocationStrategy,
  PathLocationStrategy,
} from '@angular/common';
import { NgModule } from '@angular/core';
import { canActivate, redirectUnauthorizedTo } from '@angular/fire/auth-guard';
import { RouterModule, Routes } from '@angular/router';
import { BasicLayoutComponent, TabLayoutComponent } from '@components';
import { SelectiveLoadingStrategy, WebAppGuard } from './utils';

const redirectUnauthorizedToLogin = () => redirectUnauthorizedTo(['auth']);

const routes: Routes = [
  {
    path: '',
    loadComponent: () =>
      import('./pages/intro/intro.page').then((m) => m.IntroPage),
    canActivate: [WebAppGuard],
  },

  {
    path: 'auth',
    children: [
      {
        path: '',
        pathMatch: 'full',
        loadComponent: () =>
          import('./pages/auth/auth.page').then((m) => m.AuthPage),
        data: {
          preload: true,
        },
      },
      {
        path: 'choose-password',
        loadComponent: () =>
          import('./pages/auth/choose-password/choose-password.page').then(
            (m) => m.ChoosePasswordPage
          ),
      },
      {
        path: 'basic-info',
        loadComponent: () =>
          import('./pages/auth/basic-info/basic-info.page').then(
            (m) => m.BasicInfoPage
          ),
      },
      {
        path: 'confirm-phone',
        loadComponent: () =>
          import('./pages/auth/confirm-phone/confirm-phone.page').then(
            (m) => m.ConfirmPhonePage
          ),
      },
      {
        path: 'enter-password',
        loadComponent: () =>
          import('./pages/auth/enter-password/enter-password.page').then(
            (m) => m.EnterPasswordPage
          ),
      },
      {
        path: 'email-login',
        loadComponent: () =>
          import('./pages/auth/email-login/email-login.page').then(
            (m) => m.EmailLoginPage
          ),
      },
      {
        path: 'privacy-page',
        loadComponent: () =>
          import(
            './pages/auth/permission-site-track/permission-site-track.page'
          ).then((m) => m.PermissionSiteTrackPage),
      },
      {
        path: 'notifications',
        loadComponent: () =>
          import(
            './pages/auth/permission-push-notification/permission-push-notification.page'
          ).then((m) => m.PermissionPushNotificationPage),
      },
      {
        path: 'admin-user/:customToken',
        loadComponent: () =>
          import('./pages/auth/admin-user/admin-user.page').then(
            (m) => m.AdminUserPage
          ),
      },
    ],
  },
  {
    path: 'laundry',
    component: TabLayoutComponent,
    ...canActivate(redirectUnauthorizedToLogin),
    children: [
      {
        path: '',
        loadComponent: () =>
          import('./pages/laundry/laundry.page').then((m) => m.LaundryPage),
        data: {
          preload: true,
          animation: true,
        },
      },
    ],
  },
  {
    path: 'past-orders',
    component: TabLayoutComponent,
    ...canActivate(redirectUnauthorizedToLogin),
    children: [
      {
        path: '',
        loadComponent: () =>
          import('./pages/past-orders/past-orders.page').then(
            (m) => m.PastOrdersPage
          ),
      },
    ],
  },
  {
    path: 'order-details',
    component: BasicLayoutComponent,
    ...canActivate(redirectUnauthorizedToLogin),
    children: [
      {
        path: ':id',
        loadComponent: () =>
          import('./pages/order-details/order-details.page').then(
            (m) => m.OrderDetailsPage
          ),
      },
      {
        path: 'order-receipt/:orderId',
        loadComponent: () =>
          import('./pages/order-receipt/order-receipt.page').then(
            (m) => m.OrderReceiptPage
          ),
      },
    ],
  },
  {
    path: 'account',
    ...canActivate(redirectUnauthorizedToLogin),
    children: [
      {
        path: '',
        component: TabLayoutComponent,
        children: [
          {
            path: '',
            loadComponent: () =>
              import('./pages/account/account.page').then((m) => m.AccountPage),
          },
        ],
      },
    ],
  },
  {
    path: 'account-details',
    ...canActivate(redirectUnauthorizedToLogin),
    loadComponent: () =>
      import('./pages/account/account-details/account-details.page').then(
        (m) => m.AccountDetailsPage
      ),
  },
  {
    path: 'preferred-laundry-pros',
    ...canActivate(redirectUnauthorizedToLogin),
    loadComponent: () =>
      import(
        './pages/account/preferred-laundry-pros/preferred-laundry-pros.page'
      ).then((m) => m.PreferredLaundryProsPage),
  },
  {
    path: 'profiles',
    ...canActivate(redirectUnauthorizedToLogin),
    children: [
      {
        path: '',
        loadComponent: () =>
          import('./pages/account/profiles/profiles.page').then(
            (m) => m.ProfilesPage
          ),
      },
      {
        path: 'view-profile',
        loadComponent: () =>
          import(
            './pages/account/profiles/view-profile/view-profile.page'
          ).then((m) => m.ViewProfilePage),
      },
      {
        path: 'new-profile',
        loadComponent: () =>
          import('./pages/account/profiles/new-profile/new-profile.page').then(
            (m) => m.NewProfilePage
          ),
      },
      {
        path: 'pickup-location',
        loadComponent: () =>
          import(
            './pages/account/profiles/pickup-location/pickup-location.page'
          ).then((m) => m.PickupLocationPage),
      },
      {
        path: 'laundry-preferences',
        loadComponent: () =>
          import(
            './pages/account/profiles/laundry-preferences-page/laundry-preferences.page'
          ).then((m) => m.LaundryPreferencesPage),
      },
      {
        path: 'address',
        loadComponent: () =>
          import('./pages/account/profiles/address/address.page').then(
            (m) => m.AddressPage
          ),
      },
    ],
  },
  {
    path: 'referrals',
    ...canActivate(redirectUnauthorizedToLogin),
    children: [
      {
        path: 'select-type',
        component: TabLayoutComponent,
        children: [
          {
            path: '',
            loadComponent: () =>
              import('./pages/referrals/referrals.page').then(
                (m) => m.ReferralsPage
              ),
          },
        ],
      },
      {
        path: '',
        component: BasicLayoutComponent,
        children: [
          {
            path: 'include-laundry-pro',
            loadComponent: () =>
              import('./pages/referrals/include-lp/include-lp.page').then(
                (m) => m.IncludeLpPage
              ),
          },
          {
            path: 'share-referral',
            loadComponent: () =>
              import(
                './pages/referrals/share-referral/share-referral.page'
              ).then((m) => m.ShareReferralPage),
          },
        ],
      },
    ],
  },
  {
    path: 'address',
    pathMatch: 'full',
    ...canActivate(redirectUnauthorizedToLogin),
    loadComponent: () =>
      import('./pages/new-orders/address/address.page').then(
        (m) => m.AddressPage
      ),
  },
  {
    path: 'delivery-speed',
    ...canActivate(redirectUnauthorizedToLogin),
    loadComponent: () =>
      import('./pages/new-orders/delivery-speed/delivery-speed.page').then(
        (m) => m.DeliverySpeedPage
      ),
  },
  {
    path: 'laundry-preferences',
    ...canActivate(redirectUnauthorizedToLogin),
    loadComponent: () =>
      import(
        './pages/new-orders/laundry-preferences/laundry-preferences.page'
      ).then((m) => m.LaundryPreferencesPage),
  },
  {
    path: 'bag-count',
    ...canActivate(redirectUnauthorizedToLogin),
    loadComponent: () =>
      import('./pages/new-orders/bag-count/bag-count.page').then(
        (m) => m.BagCountPage
      ),
  },
  {
    path: 'oversized-items',
    ...canActivate(redirectUnauthorizedToLogin),
    loadComponent: () =>
      import('./pages/new-orders/oversized-items/oversized-items.page').then(
        (m) => m.OversizedItemsPage
      ),
  },
  {
    path: 'pickup-location',
    ...canActivate(redirectUnauthorizedToLogin),
    loadComponent: () =>
      import('./pages/new-orders/pickup-location/pickup-location.page').then(
        (m) => m.PickupLocationPage
      ),
  },
  {
    path: 'coverage',
    ...canActivate(redirectUnauthorizedToLogin),
    loadComponent: () =>
      import('./pages/new-orders/coverages/coverage.page').then(
        (m) => m.CoveragePage
      ),
  },
  {
    path: 'review-order',
    ...canActivate(redirectUnauthorizedToLogin),
    loadComponent: () =>
      import('./pages/new-orders/review-order/review-order.page').then(
        (m) => m.ReviewOrderPage
      ),
  },
  {
    path: 'rate-tip/:orderId/:rating',
    ...canActivate(redirectUnauthorizedToLogin),
    loadComponent: () =>
      import('./pages/new-orders/rate-tip/rate-tip.page').then(
        (m) => m.RateTipPage
      ),
  },
  {
    path: 'choose-profile',
    ...canActivate(redirectUnauthorizedToLogin),
    loadComponent: () =>
      import('./pages/new-orders/choose-profile/choose-profile.page').then(
        (m) => m.ChooseProfilePage
      ),
  },
  {
    path: 'choose-preferred-laundry-pro',
    ...canActivate(redirectUnauthorizedToLogin),
    loadComponent: () =>
      import(
        './pages/new-orders/choose-preferred-laundry-pro/choose-preferred-laundry-pro.page'
      ).then((m) => m.ChoosePreferredLaundryProPage),
  },

  {
    path: 'help',
    component: BasicLayoutComponent,
    children: [
      {
        path: 'faq',
        loadComponent: () =>
          import('./pages/help/faq/faq.page').then((m) => m.FaqPage),
        data: {
          preload: true,
        },
      },
      {
        path: 'process',
        loadComponent: () =>
          import('./pages/help/process/process.page').then(
            (m) => m.ProcessPage
          ),
      },
      {
        path: 'pricing',
        loadComponent: () =>
          import('./pages/help/pricing-faq/pricing-faq.page').then(
            (m) => m.PricingFaqPage
          ),
      },
      {
        path: 'protection-plan',
        loadComponent: () =>
          import('./pages/help/protection-plan/protection-plan.page').then(
            (m) => m.ProtectionPlanPage
          ),
      },
    ],
  },

  {
    path: 'messaging',
    component: BasicLayoutComponent,
    ...canActivate(redirectUnauthorizedToLogin),
    children: [
      {
        path: ':orderId',
        loadComponent: () =>
          import('./pages/messaging/messaging.page').then(
            (m) => m.MessagingPage
          ),
      },
    ],
  },
  {
    path: '**',
    pathMatch: 'full',
    loadComponent: () =>
      import('./pages/not-found/not-found.page').then((m) => m.NotFoundPage),
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: SelectiveLoadingStrategy,
    }),
  ],
  exports: [RouterModule],
  providers: [
    DatePipe,
    SelectiveLoadingStrategy,
    { provide: LocationStrategy, useClass: PathLocationStrategy },
  ],
})
export class AppRoutingModule {}
