import {
  HttpClient,
  HttpContext,
  HttpErrorResponse,
  HttpHeaders,
} from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { environment } from '@environments/environment';
import { HttpBusinessError, SET_LOADING_MSG, SHOW_LOADING } from '@utils';
import { Observable, catchError, map, retry, take, throwError } from 'rxjs';
import {
  BaseError,
  ForbiddenError,
  NetWorkIssueError,
  UnknownError,
  UnprocessableError,
  UnstableConnectionError,
} from '../error-handling/base-error';
import { StoreService } from '../store/store.service';

interface RawResponse<T> {
  ok: boolean;
  status: number;
  body: T;
}

class ApiResponse<T> {
  success: boolean;
  statusCode: number;
  data: T;

  constructor(success: boolean, statusCode: number, data: T) {
    this.success = success;
    this.statusCode = statusCode;
    this.data = data;
  }
}

export interface ApiRequestOptions {
  showLoading?: boolean;
  customLoadingText?: string;
  httpParams?: Record<string, any>;
  handleErrorResponse?: boolean;
}

const DEFAULT_LOADING_MSG = 'Loading';

const API_REQUEST_OPTIONS_DEFAULTS: Partial<ApiRequestOptions> = {
  showLoading: true,
  customLoadingText: DEFAULT_LOADING_MSG,
  handleErrorResponse: true,
};

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  private _http = inject(HttpClient);
  private _apiURL = environment.apiPath;
  private _store = inject(StoreService);

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'x-api-key': `${environment.apiToken}`,
    }),
    observe: 'response' as 'body',
  };

  private _defineUri(path: string): string {
    if (typeof this._apiURL === 'string') {
      return this._apiURL;
    }

    if (/customer/gim.test(path)) {
      return this._apiURL['customer'];
    }

    if (/general/gim.test(path)) {
      return this._apiURL['general'];
    }

    return 'unknown';
  }

  post<T = any>(
    path: string,
    body: Record<string, any>,
    apiRequestOptions: ApiRequestOptions
  ): Observable<ApiResponse<T>> {
    const rqOptions = {
      ...API_REQUEST_OPTIONS_DEFAULTS,
      ...apiRequestOptions,
    };
    const httpOptionsWithLoading = {
      ...this.httpOptions,
      context: new HttpContext()
        .set(SHOW_LOADING, rqOptions.showLoading)
        .set(SET_LOADING_MSG, rqOptions.customLoadingText),
      params: rqOptions.httpParams,
    };

    return this._http
      .post<RawResponse<T>>(
        this._defineUri(path) + this.pathSanitizer(path),
        JSON.stringify(body),
        httpOptionsWithLoading
      )
      .pipe(
        take(1),
        map((response) => {
          return new ApiResponse<T>(
            response['ok'],
            response['status'],
            response['body']
          );
        }),
        catchError((err) =>
          rqOptions.handleErrorResponse
            ? this.handleError(err)
            : throwError(() => err)
        )
      );
  }

  put<T = any>(
    path: string,
    body: Record<string, any>,
    showLoading: boolean,
    customLoadingText: string = DEFAULT_LOADING_MSG,
    params?: Record<string, any>
  ): Observable<ApiResponse<T>> {
    const httpOptionsWithLoading = {
      ...this.httpOptions,
      context: new HttpContext()
        .set(SHOW_LOADING, showLoading)
        .set(SET_LOADING_MSG, customLoadingText),
      params,
    };

    return this._http
      .put<RawResponse<T>>(
        this._defineUri(path) + this.pathSanitizer(path),
        JSON.stringify(body),
        httpOptionsWithLoading
      )
      .pipe(
        take(1),
        map((response) => {
          return new ApiResponse(
            response['ok'],
            response['status'],
            response['body']
          );
        }),
        catchError((err) => this.handleError(err))
      );
  }

  patch<T = any>(
    path: string,
    body: Record<string, any>,
    showLoading: boolean,
    customLoadingText: string = DEFAULT_LOADING_MSG,
    params?: Record<string, any>
  ): Observable<ApiResponse<T>> {
    const httpOptionsWithLoading = {
      ...this.httpOptions,
      context: new HttpContext()
        .set(SHOW_LOADING, showLoading)
        .set(SET_LOADING_MSG, customLoadingText),
      params,
    };

    return this._http
      .patch<RawResponse<T>>(
        this._defineUri(path) + this.pathSanitizer(path),
        JSON.stringify(body),
        httpOptionsWithLoading
      )
      .pipe(
        take(1),
        map((response) => {
          return new ApiResponse<T>(
            response['ok'],
            response['status'],
            response['body']
          );
        }),
        catchError((err) => this.handleError(err))
      );
  }

  delete<T = any>(
    path: string,
    body: Record<string, any>,
    showLoading: boolean,
    customLoadingText: string = DEFAULT_LOADING_MSG,
    params?: Record<string, any>
  ): Observable<ApiResponse<T>> {
    const httpOptionsWithLoading = {
      ...this.httpOptions,
      context: new HttpContext()
        .set(SHOW_LOADING, showLoading)
        .set(SET_LOADING_MSG, customLoadingText),
      body: JSON.stringify(body),
      params,
    };
    return this._http
      .delete<RawResponse<T>>(
        this._defineUri(path) + this.pathSanitizer(path),
        httpOptionsWithLoading
      )
      .pipe(
        take(1),
        map((response) => {
          return new ApiResponse<T>(
            response['ok'],
            response['status'],
            response['body']
          );
        }),
        catchError((err) => this.handleError(err))
      );
  }

  get<T = any>(
    path: string,
    showLoading: boolean,
    customLoadingText: string = DEFAULT_LOADING_MSG,
    params?: Record<string, any>
  ): Observable<ApiResponse<T>> {
    const httpOptionsWithLoading = {
      ...this.httpOptions,
      context: new HttpContext()
        .set(SHOW_LOADING, showLoading)
        .set(SET_LOADING_MSG, customLoadingText),
      params,
    };

    return this._http
      .get<RawResponse<T>>(
        this._defineUri(path) + this.pathSanitizer(path),
        httpOptionsWithLoading
      )
      .pipe(
        take(1),
        map((response) => {
          return new ApiResponse<T>(
            response['ok'],
            response['status'],
            response['body']
          );
        }),
        retry({ count: 1, delay: 100 }),
        catchError((err) => this.handleError(err, path))
      );
  }

  private handleError(error: HttpErrorResponse, path?: string) {
    let message = 'An unknown error occurred, please try again later.';
    let errorToThrow: BaseError = new UnknownError(message);

    switch (error.status) {
      case 0:
        errorToThrow = new NetWorkIssueError();
        break;
      case HttpBusinessError.unprocessableSurgeChargeAvailable:
        errorToThrow = new UnprocessableError(
          `Error Code: ${error.status}, Surge: ${error.error.surgePrice}`
        );
        break;
      case HttpBusinessError.authenticationError:
        this._store.logout();
        errorToThrow = new ForbiddenError();
        break;
      case HttpBusinessError.gatewayTimeout:
        errorToThrow = new UnstableConnectionError();
        break;
      default:
        if (!error.status) {
          message = `Error: ${error.message || path}`;
        } else {
          message = `Error Code: ${error.status}, Message: ${
            error.error?.message || message
          }`;
        }

        errorToThrow = new UnknownError(message);
        break;
    }

    return throwError(() => errorToThrow);
  }

  private pathSanitizer(path: string): string {
    return /^(\/)(.)+/gim.test(path) ? path : `/${path}`;
  }
}
