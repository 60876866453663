import {
  ApplicationRef,
  ComponentRef,
  createComponent,
  inject,
  Injectable,
} from '@angular/core';
import { ShowOptions, Toast } from '@capacitor/toast';
import {
  AlertController,
  AlertOptions,
  LoadingController,
  LoadingOptions,
  ModalController,
  ModalOptions,
  PopoverController,
  PopoverOptions,
} from '@ionic/angular/standalone';
import { NotificationComponent } from '@sudshare/custom-design-package';
import type { NotificationContent } from '@utils';
import { RollbarService } from '../rollbar/rollbar.service';

@Injectable({
  providedIn: 'root',
})
export class DialogService {
  private _alertCtrl = inject(AlertController);
  private _appRef = inject(ApplicationRef);
  private _modalCtrl = inject(ModalController);
  private _loadingCtrl = inject(LoadingController);
  private _popoverCtrl = inject(PopoverController);
  private _rollbar = inject(RollbarService);

  private notification?: ComponentRef<NotificationComponent>;

  async showAlert(opts?: AlertOptions): Promise<HTMLIonAlertElement> {
    const alert = await this._alertCtrl.create(opts);
    await alert.present();
    return alert;
  }

  async showErrorAlert(opts?: AlertOptions): Promise<HTMLIonAlertElement> {
    const defaultOpts: AlertOptions = {
      header: 'Error',
      buttons: ['OK'],
    };
    opts = { ...defaultOpts, ...opts };
    this._rollbar.error(opts.message);
    return this.showAlert(opts);
  }

  async showModal(opts: ModalOptions): Promise<HTMLIonModalElement> {
    const modal = await this._modalCtrl.create(opts);
    await modal.present();
    return modal;
  }

  async showPopover(opts: PopoverOptions): Promise<HTMLIonPopoverElement> {
    const popover = await this._popoverCtrl.create(opts);
    await popover.present();
    return popover;
  }

  async showLoading(opts?: LoadingOptions): Promise<HTMLIonLoadingElement> {
    const defaultOpts: LoadingOptions = {
      message: 'Please wait',
      spinner: 'circular',
      cssClass: 'poplin-loading',
      mode: 'ios',
      keyboardClose: true,
    };
    opts = { ...defaultOpts, ...opts };
    const loading = await this._loadingCtrl.create(opts);
    await loading.present();
    return loading;
  }

  async dismissLoading(loading: LoadingController): Promise<boolean> {
    return await loading.dismiss();
  }

  async showToast(opts: ShowOptions): Promise<void> {
    return await Toast.show(opts);
  }

  showCustomToast(opts: NotificationContent, destroyDelay?: number): void {
    const ref = createComponent(NotificationComponent, {
      environmentInjector: this._appRef.injector,
    });

    if (this.notification) {
      this.destroyNotification(this.notification);
    }

    const {
      showHeading = false,
      notificationHeading = '',
      text = '',
      notificationType = 'default',
      showNotificationIcon,
      showButtonCTA = false,
      linkCTAText,
      notificationIcon = 'person_filled',
    } = opts;

    ref.setInput('showHeading', showHeading);
    ref.setInput('notificationHeading', notificationHeading);
    ref.setInput('text', text);
    ref.setInput('color', notificationType);
    ref.setInput('showNotificationIcon', showNotificationIcon);
    ref.setInput('placement', 'toast');
    ref.setInput('showButtonCTA', showButtonCTA);
    ref.setInput('showLinkCTA', linkCTAText);
    ref.setInput('linkCTAText', linkCTAText);
    ref.setInput('notificationIcon', notificationIcon);

    ref.instance.eventEmitter.asObservable().subscribe({
      next: () => {
        this.destroyNotification(ref);
      },
    });

    document.body.appendChild(ref.location.nativeElement);
    this._appRef.attachView(ref.hostView);
    this.notification = ref;

    if (destroyDelay) {
      setTimeout(() => {
        if (this.notification) {
          this.destroyNotification(ref);
        }
      }, destroyDelay);
    }
  }

  private destroyNotification(
    notification: ComponentRef<NotificationComponent>
  ) {
    document.body.removeChild(notification.location.nativeElement);
    this._appRef.detachView(notification.hostView);
    notification.destroy();
    this.notification = undefined;
  }
}
