import {
  HttpEvent,
  HttpHandlerFn,
  HttpInterceptorFn,
  HttpRequest,
} from '@angular/common/http';
import { inject } from '@angular/core';
import { DialogService } from '@services';
import { Observable, finalize, from, switchMap, tap } from 'rxjs';
import { SET_LOADING_MSG, SHOW_LOADING } from './constants';

/**
 * Show loading modal when `SHOW_LOADING` is set to true in API requests
 */
export const LoaderInterceptor: HttpInterceptorFn = (
  req: HttpRequest<any>,
  next: HttpHandlerFn
): Observable<HttpEvent<any>> => {
  const _dialogService = inject(DialogService);

  if (req.context.get(SHOW_LOADING) === false) {
    return next(req);
  }

  let loadingModal: HTMLIonLoadingElement;
  return from(
    _dialogService.showLoading({
      message: req.context.get(SET_LOADING_MSG),
    })
  ).pipe(
    tap((loadingRef) => {
      loadingModal = loadingRef;
    }),
    switchMap(() => next(req)),
    finalize(() => {
      if (loadingModal) {
        loadingModal.dismiss();
      }
    })
  );
};
