import { Action, createReducer, on } from '@ngrx/store';
import {
  CustomerProfileWithId,
  PartialNested,
} from '@sudshare/custom-node-package';
import { StoreStatusOptions, STORE_STATUS } from '@utils';
import {
  ProfileSubscribeFailure,
  ProfileSubscribePending,
  ProfileSubscribeSuccess,
} from './profile.actions';

export interface ProfileState {
  profileData: PartialNested<CustomerProfileWithId>[] | null;
  error: string | null;
  status: STORE_STATUS;
}

const initialState: ProfileState = {
  profileData: null,
  error: null,
  status: StoreStatusOptions.PENDING,
};

const profileReducer = createReducer(
  initialState,

  on(ProfileSubscribeSuccess, (state, { payload }) => ({
    ...state,
    status: StoreStatusOptions.SUCCESS,
    profileData: payload.profileData,
    ...(state.profileData as PartialNested<CustomerProfileWithId[]>),
    error: null,
  })),

  on(ProfileSubscribePending, (state) => ({
    ...state,
    status: StoreStatusOptions.PENDING,
    profileData: null,
    error: null,
  })),

  on(ProfileSubscribeFailure, (state, { payload }) => ({
    ...state,
    status: StoreStatusOptions.ERROR,
    profileData: null,
    error: payload.error,
  }))
);

export function ProfileReducer(
  state: ProfileState | undefined,
  action: Action
): ProfileState {
  return profileReducer(state, action);
}
