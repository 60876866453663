import { Injector } from '@angular/core';
import { GoogleAuthProvider, User } from '@angular/fire/auth';
import { Capacitor } from '@capacitor/core';
import { StatusBarPlugin, Style } from '@capacitor/status-bar';
import {
  ConfirmActionModalComponent,
  DrawerModalComponent,
  HelpModalComponent,
} from '@components';
import { environment } from '@environments/environment';
import { ModalController } from '@ionic/angular/standalone';
import {
  ApiService,
  NavigationService,
  StatsigService,
  StoreService,
} from '@services';
import {
  HeaderClickEvent,
  HeaderClickEventType,
  ReorderEvent,
} from '@sudshare/custom-design-package';
import {
  CustomerAPI,
  OrderBagSizeType,
  OrderCoverageType,
  OrderDeliveryType,
  ThemePreference,
} from '@sudshare/custom-node-package';
import { formatInTimeZone } from 'date-fns-tz';
import * as jose from 'jose';
import { take } from 'rxjs';
import {
  CENTS_TO_DOLLAR,
  CREDITS_ENDPOINT,
  FormatTimestampOptions,
  NEW_STRIPE_ACCOUNT,
  STORE_STATUS,
  StoreStatusOptions,
  breakpoints,
} from './constants';
import { NewOrderState } from './interfaces';

const CAP_STATUS_BAR_PLUGIN_NAME = 'StatusBar';

export const checkDarkModePreference = (): ThemePreference =>
  window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches
    ? ThemePreference.DarkMode
    : ThemePreference.LightMode;

export const setDarkModePreferenceOnBody = (
  theme: string,
  statusBar?: StatusBarPlugin
): void => {
  const mode = theme?.trim();
  switch (mode) {
    case ThemePreference.DarkMode:
    case ThemePreference.LightMode:
      handleThemeClasses(mode, statusBar);
      break;
    case ThemePreference.UseSystemDefault:
      checkDarkModePreference();
      // eslint-disable-next-line no-case-declarations
      const theme =
        window.matchMedia &&
        window.matchMedia('(prefers-color-scheme: dark)').matches
          ? ThemePreference.DarkMode
          : ThemePreference.LightMode;
      handleThemeClasses(theme, statusBar);
      break;
  }
};

export const handleThemeClasses = (
  theme: string,
  statusBar?: StatusBarPlugin
): void => {
  switch (theme) {
    case ThemePreference.DarkMode:
      document.body.classList.add('dark');
      document.body.classList.remove('light');
      break;
    case ThemePreference.LightMode:
      document.body.classList.add('light');
      document.body.classList.remove('dark');
      break;
  }

  const color = window
    .getComputedStyle(document.body)
    .getPropertyValue('--color-background-primary');
  if (
    color &&
    statusBar &&
    Capacitor.isPluginAvailable(CAP_STATUS_BAR_PLUGIN_NAME)
  ) {
    statusBar.setStyle({
      style: theme === ThemePreference.DarkMode ? Style.Dark : Style.Light,
    });
    statusBar.setBackgroundColor({ color: color }).catch(() => {
      // method not implemented in ios error
    });
  }
};

export const calcBreakpoint = (minValue: number): number => {
  const availHeight = window.innerHeight;
  const value = Math.ceil((minValue / availHeight) * 10) / 10;

  if (value >= 1) {
    return 1;
  } else {
    return value;
  }
};

export const isNewGoogleAccount = (user: User): boolean => {
  const isGoogle =
    user.providerData.length === 1 &&
    user.providerData[0].providerId ===
      GoogleAuthProvider.GOOGLE_SIGN_IN_METHOD;

  const creationTimeUnix = user.metadata?.creationTime
    ? new Date(user.metadata.creationTime).valueOf()
    : 0;
  const currentTimeUnix = new Date().valueOf();
  const twoMinsInUnix = 2 * 60 * 1000;

  const timeDifference = Math.abs(creationTimeUnix - currentTimeUnix);

  if (isGoogle && timeDifference <= twoMinsInUnix) {
    return true;
  }

  return false;
};

export const openHelpModal = async (
  modalController: ModalController
): Promise<void> => {
  const modal = await modalController.create({
    component: HelpModalComponent,
    cssClass: 'help-modal',
    breakpoints: breakpoints,
    initialBreakpoint: calcBreakpoint(464),
    handle: false,
  });

  return await modal.present();
};

export const closeModal = async (modal: ModalController): Promise<boolean> => {
  return modal.dismiss();
};

export const closeModalWithOptions = async (
  modal: ModalController,
  obj?: Record<string, boolean>,
  string?: string
): Promise<boolean> => {
  return modal.dismiss(obj, string);
};

export const handleHeaderClick = (
  event: HeaderClickEvent,
  nav?: NavigationService,
  modal?: ModalController | null
): void | Promise<void> => {
  switch (event.eventType) {
    case HeaderClickEventType.Back:
      nav?.back();
      return;
    case HeaderClickEventType.Help:
      if (modal) {
        openHelpModal(modal);
      }
      return;
    case HeaderClickEventType.Close:
      if (modal) {
        closeModal(modal);
      }
      return;
    default:
      return;
  }
};

export const getCurrentPage = (
  totalSteps: number,
  currentStep: STORE_STATUS
): number => {
  if (totalSteps === 6) {
    switch (currentStep) {
      case StoreStatusOptions.CHOOSE_PROFILE:
        return 1;
      case StoreStatusOptions.DELIVERY:
        return 2;
      case StoreStatusOptions.LOAD_SIZE:
        return 3;
      case StoreStatusOptions.OVERSIZED_ITEMS:
        return 4;
      case StoreStatusOptions.PREFERRED_LP:
        return 5;
      case StoreStatusOptions.COVERAGE:
        return 6;
      default:
        return 1;
    }
  } else if (totalSteps === 7) {
    switch (currentStep) {
      case StoreStatusOptions.PICKUP:
        return 1;
      case StoreStatusOptions.DELIVERY:
        return 2;
      case StoreStatusOptions.PREFERENCES:
        return 3;
      case StoreStatusOptions.LOAD_SIZE:
        return 4;
      case StoreStatusOptions.OVERSIZED_ITEMS:
        return 5;
      case StoreStatusOptions.PREFERRED_LP:
        return 6;
      case StoreStatusOptions.COVERAGE:
        return 7;
      default:
        return 1;
    }
  } else {
    switch (currentStep) {
      case StoreStatusOptions.PICKUP:
        return 1;
      case StoreStatusOptions.DELIVERY:
        return 2;
      case StoreStatusOptions.PREFERENCES:
        return 3;
      case StoreStatusOptions.LOAD_SIZE:
        return 4;
      case StoreStatusOptions.OVERSIZED_ITEMS:
        return 5;
      case StoreStatusOptions.PREFERRED_LP:
        return 6;
      case StoreStatusOptions.COVERAGE:
        return 7;
      default:
        return 1;
    }
  }
};

export const getTotalPages = (state: NewOrderState): number => {
  if (state.hasProfile && !state.isNewProfile) {
    return 6;
  } else {
    return 7;
  }
};

export const arrangeLPs = (
  event: ReorderEvent,
  list: CustomerAPI.Data.LaundryPro[]
): CustomerAPI.Data.LaundryPro[] => {
  const from = list[event.from];
  const to = list[event.to];

  const arrangeList = list.map((item) => {
    const moveDown =
      from.order < to.order &&
      item.order > from.order &&
      item.order <= to.order;

    const moveUp =
      from.order > to.order &&
      item.order < from.order &&
      item.order >= to.order;

    if (moveDown) {
      item.order = item.order - 1;
    }
    if (moveUp) {
      item.order = item.order + 1;
    }
    return item;
  });

  if (event.from < event.to) {
    from.order = to.order + 1;
  } else {
    from.order = to.order - 1;
  }

  const sortedList = arrangeList.sort((a, b) => a.order - b.order);

  return sortedList;
};

export const formatPhoneNumberForDisplay = (phoneNumber?: string): string => {
  // Remove non-numeric characters
  const numericPhoneNumber = phoneNumber ? phoneNumber.replace(/\D/g, '') : '';

  // Extract area code, first part, and second part
  const areaCode =
    numericPhoneNumber.length === 11
      ? numericPhoneNumber.slice(1, 4)
      : numericPhoneNumber.slice(0, 3);
  const firstPart =
    numericPhoneNumber.length === 11
      ? numericPhoneNumber.slice(4, 7)
      : numericPhoneNumber.slice(3, 6);
  const secondPart =
    numericPhoneNumber.length === 11
      ? numericPhoneNumber.slice(7)
      : numericPhoneNumber.slice(6);

  // Assemble the formatted phone number
  return `(${areaCode}) ${firstPart}-${secondPart}`;
};

export const formatPhoneNumberForAPI = (phoneNumber?: string): string => {
  // Remove non-numeric characters
  const numericPhoneNumber = phoneNumber ? phoneNumber.replace(/\D/g, '') : '';

  // Check if the phone number is already in the desired format
  if (numericPhoneNumber.length === 11 && numericPhoneNumber.startsWith('1')) {
    return `+${numericPhoneNumber}`;
  }

  // Split the numeric phone number into parts
  const [areaCode, firstPart, secondPart] = [
    numericPhoneNumber.slice(0, 3),
    numericPhoneNumber.slice(3, 6),
    numericPhoneNumber.slice(6, 10),
  ];

  // Assemble the formatted phone number
  return `+1${areaCode}${firstPart}${secondPart}`;
};

export const openPreAuthorizationModal = async (
  _modalCtrl: ModalController
): Promise<{
  data: { confirm: boolean } | undefined;
  role: any;
}> => {
  const breakpoint = calcBreakpoint(620);

  const preAuthorizationModal = await _modalCtrl.create({
    component: DrawerModalComponent,
    mode: 'ios',
    componentProps: {
      title: 'Preauthorized Amount',
      body: `This pre-authorization confirms that the funds are available on your credit/debit card and puts a “hold” on those funds. You are not charged this amount.<br/><br/>After your laundry is washed, dried, folded, packed, and weighed, your credit/debit card (or gift card or Poplin credit) is charged the cost of the service, and the “hold” amount is released.<br/><br/>If you cancel an order the “hold” amount is released.<br/><br/>If the pre-authorized amount is a problem for you, we recommend you use a credit card instead of a debit card. Credit cards hold the amount from your credit line rather than charging and refunding you.<br/><br/>There is a minimum $25 pre-authorized amount on all orders.`,
      buttonLabel: 'Close',
    },
    breakpoints: [breakpoint, ...breakpoints],
    initialBreakpoint: breakpoint,
    handle: false,
  });
  preAuthorizationModal.present();

  const { data, role } = await preAuthorizationModal.onDidDismiss<{
    confirm: boolean;
  }>();
  return { data, role: role as any };
};

export function buildInitializer(initFn: () => void): () => () => void {
  let run = false;

  return (): (() => void) => {
    if (!run) {
      run = true;
      initFn();
    }

    return () => {
      run = false;
    };
  };
}

export function formatTimes(
  timestamp: Date,
  timezone: string,
  formatType: string
): string {
  const options = { timeZone: timezone };

  switch (formatType) {
    case FormatTimestampOptions.DAY_OF_WEEK: {
      const dayOfWeek = timestamp.toLocaleString('en-US', {
        ...options,
        weekday: 'long',
      });
      const hour = timestamp
        .toLocaleString('en-US', {
          ...options,
          hour: 'numeric',
        })
        .toLowerCase()
        .replace(' ', '');
      return `${hour} ${dayOfWeek}`;
    }
    case FormatTimestampOptions.TIME_AGO: {
      const hour = timestamp
        .toLocaleString('en-US', {
          ...options,
          hour: 'numeric',
          minute: 'numeric',
        })
        .toLowerCase()
        .replace(' ', '');
      return hour;
    }
    case FormatTimestampOptions.PLACED: {
      const month = timestamp.toLocaleString('en-US', {
        ...options,
        month: 'short',
        day: 'numeric',
      });
      const time = timestamp
        .toLocaleString('en-US', {
          ...options,
          hour: 'numeric',
          minute: 'numeric',
        })
        .toLowerCase()
        .replace(' ', '');
      return `${month}, ${time}`;
    }
    default:
      return '';
  }
}

export const getDayOfWeek = (): string => {
  const daysOfWeek = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
  ];
  const dayAfterTomorrowIndex = (new Date().getDay() + 2) % 7;
  return daysOfWeek[dayAfterTomorrowIndex];
};

export const getHours = (timezone: string, timestamp: Date): number => {
  const formattedDate = formatInTimeZone(
    new Date(timestamp),
    timezone,
    "yyyy-MM-dd'T'HH:mm:ss.SSS"
  );
  const orderLocalDate = new Date(formattedDate);
  const hours = orderLocalDate.getHours();
  return hours;
};

export const definePlacedDay = (
  type: OrderDeliveryType,
  timezone: string,
  timestamp: Date
): string => {
  const hours = getHours(timezone, timestamp);

  if (type === OrderDeliveryType.Regular && hours >= 15) {
    return '3pm tomorrow';
  }

  if (type === OrderDeliveryType.SameDay) {
    if (hours < 10) {
      return '10am today';
    }
    if (hours >= 15) {
      return '10am tomorrow';
    }
  }

  return '3pm today';
};

export const defineDeliveryDay = (
  type: OrderDeliveryType,
  timezone: string,
  timestamp: Date
): string => {
  const hours = getHours(timezone, timestamp);

  if (type === OrderDeliveryType.Regular && hours >= 15) {
    return `8pm ${getDayOfWeek()}`;
  }

  if (type === OrderDeliveryType.SameDay) {
    if (hours < 10) {
      return '8pm today';
    }
    if (hours >= 10 && hours < 15) {
      return '10am tomorrow';
    }
  }

  return '8pm tomorrow';
};

export const definePickupDay = (
  type: OrderDeliveryType,
  timezone: string,
  timestamp: Date
): string => {
  const hours = getHours(timezone, timestamp);

  if (type === OrderDeliveryType.Regular && hours >= 15) {
    return '8pm tomorrow';
  }

  if (type === OrderDeliveryType.SameDay) {
    if (hours < 10) {
      return '2pm today';
    }
    if (hours >= 15) {
      return '2pm tomorrow';
    }
  }

  return '8pm today';
};

export const feedBackModalLP = async (
  modalCtrl: ModalController,
  lpName: string,
  lpId: string
): Promise<HTMLIonModalElement> => {
  const breakpoint = calcBreakpoint(300);
  return await modalCtrl.create({
    component: DrawerModalComponent,
    mode: 'ios',
    componentProps: {
      title: 'Thanks for your feedback',
      body: `We added ${lpName} to your list of Preferred Laundry Pros. We look forward to serving you again soon!`,
      laundryProId: lpId,
      showButton: false,
      showCard: true,
      cardTitle: `Recommend ${lpName}`,
      cardBody: 'Tap to share this Laundry Pro and spread the Poplin love.',
      cardIcon: 'star_filled',
      cardIconColor: '--pink-core',
      cardRoute: 'preferred-laundry-pros',
      isFeedbackModal: true,
      selectedRating: 5,
      feedBackModalLP: lpName,
      navigation: {
        state: {
          isFromRatePage: true,
        },
      },
    },
    breakpoints: [breakpoint, ...breakpoints],
    initialBreakpoint: breakpoint,
    handle: false,
  });
};

export const ratingModal = async (
  modalCtrl: ModalController,
  lpName: string,
  lpId: string,
  ratingTitle: string,
  rating: number
): Promise<HTMLIonModalElement> => {
  const breakpoint = calcBreakpoint(300);
  return await modalCtrl.create({
    component: DrawerModalComponent,
    mode: 'ios',
    componentProps: {
      title: ratingTitle,
      body: '',
      laundryProId: lpId,
      showButton: false,
      showCard: false,
      isFeedbackModal: true,
      selectedRating: rating,
      feedBackModalLP: lpName,
      navigation: {
        state: {
          isFromRatePage: true,
        },
      },
    },
    breakpoints: [breakpoint, ...breakpoints],
    initialBreakpoint: breakpoint,
    handle: false,
  });
};

const createSuccessModal = async (
  modalCtrl: ModalController
): Promise<HTMLIonModalElement> => {
  const modal = await modalCtrl.create({
    component: ConfirmActionModalComponent,
    mode: 'ios',
    initialBreakpoint: 0.35,
    breakpoints: breakpoints,
    componentProps: {
      title: 'You’re all set!',
      content:
        "Please make sure your laundry is at your designated pickup location. Since we know you'll love Poplin, we saved your order preferences so next time you place an order it'll be really fast and easy.",
      showConfirm: false,
      showCancel: false,
    },
  });
  modal.present();
  return modal;
};

export const presentSuccessModal = (
  storeService: StoreService,
  modalCtrl: ModalController
): void => {
  storeService
    .getAccountDetails()
    .pipe(take(1))
    .subscribe({
      next: (user) => {
        if (!user) {
          return;
        }
        if (user && user.firstOrder) createSuccessModal(modalCtrl);
      },
      complete: () => {
        return;
      },
    });
};

export const loadCredits = (
  _apiService: ApiService,
  _storeService: StoreService,
  _injector: Injector
): void => {
  _apiService.get(CREDITS_ENDPOINT, false).subscribe({
    next: (res) => {
      const credits: number = res.data.amount;
      _storeService.addCredit({ additional: { credits: credits } });
    },
    complete: () => {
      return;
    },
    error: (err: Error) => {
      if (err.message.includes('404')) {
        const apiService: ApiService = _injector.get<ApiService>(ApiService);
        apiService
          .post(NEW_STRIPE_ACCOUNT, {}, { showLoading: false })
          .subscribe({
            complete: () => {
              return;
            },
          });
      }
    },
  });
};

export const getAppVersion = (): string => {
  const platform = Capacitor.getPlatform();
  //If iOS device
  return platform === 'ios'
    ? environment.iosVersion
    : // If Android device
    platform === 'android'
    ? environment.androidVersion
    : // Web App
      environment.version;
};

export const getPlatformType = (): string => {
  const platform = Capacitor.getPlatform();
  return platform === 'ios'
    ? 'iOS'
    : platform === 'android'
    ? 'Android'
    : 'Web';
};

export const getTotalFees = (fees: number[]): number => {
  return fees.reduce((a, b) => a + b) / CENTS_TO_DOLLAR;
};

export const addOrderDataToStore = async (
  order: Record<string, any>,
  _storeService: StoreService,
  _statsigService: StatsigService
): Promise<void> => {
  const addressIsString = (order: Record<string, any>): boolean => {
    return typeof order['Address'] === 'string';
  };
  const profileData = {
    order: {
      orderId: '',
      orderProfileId: order['ProfileId'],
      orderProfileName: order['ProfileName'],
    },
  };

  const address = {
    pickup: {
      pickupAddress: {
        full: addressIsString(order) ? order['Address'] : order['Address'].Full,
        line1: addressIsString(order)
          ? order['Address'].split(',')[0]
          : order['Address'].Full,
        line2: order['Address'].Line2 || order['AddressLine2'] || '',
        city: order['Address'].City || order['City'] || '',
        state: order['Address'].State || order['State'] || '',
        zipCode: order['Address'].ZipCode || order['Zipcode'] || '',
        country: order['Address'].Country || 'US',
      },
    },
  };
  const pickupLocation = {
    pickup: {
      pickupLocation:
        order['PickupSpot'].PickupPlace || order['PickupSpot'].SimpleSpot || '',
      pickupInstructions: order['PickupSpot'].Instructions,
      pickupTimezone: order['Timezone'],
      pickupGeo: {
        lat: order['GeoLocation'].latitude,
        long: order['GeoLocation'].longitude,
      },
    },
  };
  const deliveryType = {
    delivery: {
      deliveryType: order['SameDayService']
        ? OrderDeliveryType.SameDay
        : OrderDeliveryType.Regular,
    },
  };

  const laundryPreferences = {
    laundry: {
      laundryDetergent: order['Preferences'].Detergent || '',
      laundryOptions: {
        delicate: order['Preferences'].Delicates || false,
        hangDry: order['Preferences'].HangDry || false,
        useHanger: order['Preferences'].Hangers || false,
        additionalInstructions: order['Preferences'].Instructions || '',
      },
    },
  };
  const bags = {
    small: order['BagSize'] === OrderBagSizeType.Small ? order['OrderSize'] : 0,
    regular:
      order['BagSize'] === OrderBagSizeType.Regular ? order['OrderSize'] : 0,
    large:
      order['BagSize'] === OrderBagSizeType.Oversized ? order['OrderSize'] : 0,
  };

  const oversizedItems = order['LargeItems'].Count || 0;

  const preferredLps = {
    preferredLp: order['PreferredSudsters'] || [],
    preferredLpNames: order['PreferredLpNames'] || [],
    preferredPickup: order['PreferredPickup'] || [],
  };

  const coverage = {
    basic: order['Coverage'].Plan === OrderCoverageType.Basic,
    premium: order['Coverage'].Plan === OrderCoverageType.Premium,
    premiumPlus: order['Coverage'].Plan === OrderCoverageType.PremiumPlus,
  };

  _storeService.addProfileData(profileData);
  _storeService.addAddress(address);
  _storeService.addPickupLocation(pickupLocation);
  _storeService.addDeliverySpeed(deliveryType);
  _storeService.addPreferences(laundryPreferences);
  _storeService.addBagSize({ bags });
  _storeService.addOversizedItems({
    additional: { oversizedItems: oversizedItems },
  });
  _storeService.addPreferredLps(preferredLps);
  _storeService.addCoverage({ coverage });
  _storeService.addRepeatOrder({ additional: { repeatOrder: true } });

  await _statsigService.updateUser({
    userID: order['UserID'],
    custom: {
      zipCode: order['Address'].ZipCode || order['Zipcode'],
      state: order['Address'].State || order['State'],
    },
  });
};

export const createExpandedAddress = (
  place: google.maps.places.PlaceResult,
  line2?: string
): {
  line1: string;
  line2: string;
  city: string;
  state: string;
  zipCode: string;
  country: string;
} => {
  const expandedAddress = {
    line1: '',
    line2: line2 || '',
    city: '',
    state: '',
    zipCode: '',
    country: 'US',
    fullAddress: '',
  };
  let streetNumber = '';
  let route = '';
  for (const component of place.address_components as google.maps.GeocoderAddressComponent[]) {
    const componentType = component.types[0];

    switch (componentType) {
      case 'street_number': {
        streetNumber = component.long_name;
        break;
      }
      case 'route': {
        route = component.short_name;
        break;
      }
      case 'postal_code': {
        expandedAddress.zipCode = `${component.long_name}`;
        break;
      }
      case 'locality':
      case 'sublocality_level_1':
      case 'neighborhood':
      case 'administrative_area_level_3': {
        expandedAddress.city = component.long_name;
        break;
      }
      case 'administrative_area_level_1': {
        expandedAddress.state = component.short_name;
        break;
      }
      default:
        break;
    }
  }

  expandedAddress.line1 = `${streetNumber} ${route}, ${expandedAddress.city}, ${expandedAddress.state}`;
  expandedAddress.fullAddress = `${expandedAddress.line1} ${expandedAddress.zipCode}, ${expandedAddress.country}`;

  return expandedAddress;
};

export const getClaimsFromJtw = async (
  jwt: string,
  publicKey: string,
  options: {
    alg: string;
  } = {
    alg: 'RS256',
  }
): Promise<Record<string, any> | null> => {
  try {
    const key = await jose.importSPKI(publicKey, options.alg);

    const { payload } = await jose.jwtVerify(jwt, key);

    return payload;
  } catch (e) {
    console.error('Invalid jwt information', e);
    return null;
  }
};
