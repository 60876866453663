import { isEmpty } from 'lodash';
import { QueryParams } from './interfaces';

const DOMAIN = 'poplin.co';

const utmParamsArray: (keyof QueryParams)[] = [
  'utm_source',
  'utm_term',
  'utm_campaign',
  'utm_content',
  'utm_medium',
  'utm_irclickid',
  'utm_click_id',
  'referralId',
  'abId',
  'gclid',
  'gbraid',
  'wbraid',
  'FirebaseAppInstanceID',
  'fbclid',
  'fbc',
  'fbp',
];

function generateFacebookCookies(fbclid: string) {
  // Generate timestamp
  const timestamp = Math.floor(Date.now() / 1000);
  // Generate a random number for fbp
  const randomForFbp = Math.floor(Math.random() * 100000000000000);
  // Construct fbp value: "fb.1.timestamp.random"
  const fbp = `fb.1.${timestamp}.${randomForFbp}`;
  // Construct fbc value using fbclid: "fb.1.timestamp.fbclid"
  const fbc = `fb.1.${timestamp}.${fbclid}`;
  return { fbp, fbc };
}
//This function is called in app.component.ts as part of initialization
export function storeUTMCodesInCookie(url: string): void {
  // Function to set cookie with domain
  function setCookie(
    name: string | number,
    value: string,
    domain: string,
    days: number
  ): void {
    let expires = '';
    if (days) {
      const date = new Date();
      date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
      expires = '; expires=' + date.toUTCString();
    }
    document.cookie =
      name + '=' + (value || '') + expires + '; path=/' + '; domain=' + domain;
    console.log(
      '[storeUTMCodesInCookie]:  name, value, days',
      name,
      value,
      days
    );
  }

  // Function to get URL parameters
  function getURLParameter(name: string | number): string | null {
    try {
      const searchParams = new URLSearchParams(location.search);
      return (
        decodeURIComponent(
          (searchParams.get(name.toString()) || '').replace(/\+/g, '%20')
        ) || null
      );
    } catch (error) {
      // silent fail so that it doesn't try to set cookies on mobile
      return null;
    }
  }

  // Define the domain for the cookie

  // Check for UTM parameters and store them in cookies
  utmParamsArray.forEach(function (utmCode) {
    try {
      const utmValue = getURLParameter(utmCode);
      if (utmValue !== null) {
        setCookie(utmCode, utmValue, DOMAIN, 30); // Store for 10 days
      }

      if (utmCode == 'fbclid' && utmValue !== null) {
        const { fbp, fbc } = generateFacebookCookies(utmValue);
        setCookie('fbp', fbp, DOMAIN, 30);
        setCookie('fbc', fbc, DOMAIN, 30);
      }
      console.log('[storeUTMCodesInCookie] Set');
    } catch (error) {
      // silent fail so that it doesn't try to set cookies on mobile
      console.log('storeUTMCodesInCookie] error');
    }
  });
  if (url) setCookie('OriginalLink', url, DOMAIN, 30);
}

// This function is called in start.page.ts on init to enable passing UTM codes for login and signup
export function getUTMValuesFromCookie(): any {
  // Function to get a cookie by name
  function getCookie(name: string | number): string | null {
    const nameEQ = name + '=';
    const ca = document.cookie.split(';');
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == ' ') c = c.substring(1, c.length);
      if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
  }

  // Object to store UTM values
  const utmValues: QueryParams = {};

  // Retrieve UTM values from cookies
  utmParamsArray.forEach((utmCode) => {
    try {
      const utmValue = getCookie(utmCode);
      if (utmValue !== null) {
        utmValues[utmCode] = utmValue;
      }
    } catch (error) {
      // siltent fail for mobile
    }
  });

  console.log('[getUTMValuesFromCookie] utm values:', utmValues);
  // Check if utmValues is empty
  if (isEmpty(utmValues)) {
    return {
      utmValues: {},
      OriginalLink: false,
    };
  }
  // Rest of the code...
  return {
    utmValues: utmValues || {},
    OriginalLink: getCookie('OriginalLink') || '',
  };
}

export function deleteUTMValuesCookie(): void {
  console.log('[deleteUTMValuesCookie]');
  function deleteCookie(name: string | number, domain: string): void {
    document.cookie =
      name +
      '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:00 GMT; domain=' +
      domain;
  }

  utmParamsArray.forEach((key) => {
    try {
      deleteCookie(key, DOMAIN);
      return;
    } catch (error) {
      // silent fail so that it doesn't try to set cookies on mobile
      return null;
    }
  });
}

export function resolveUserId(): string {
  try {
    const loggedInUserId =
      document.cookie
        ?.split('; ')
        .find((row) => row.startsWith('ajs_user_id'))
        ?.split('=')[1] ?? '';
    return loggedInUserId;
  } catch (error) {
    return '';
  }
}

// This function is called in app.component.ts to send GA4 page view
// we do this just to associate a user_id with channel attribution behaviour
// for SEO

export function trySendGA4PageView(event: any): void {
  const userId = resolveUserId();
  try {
    const GA4_Payload = {
      page_title: event?.url.replace(/\//g, ''),
      page_location: event?.url,
      user_id: userId,
    };
    // gtag should be accessible globlly only on web
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    gtag('page_view', GA4_Payload);
    console.log('[trySendGA4PageView] Sent GA4 Page View', GA4_Payload);
  } catch (error) {
    // silent fail on movile
  }
}
