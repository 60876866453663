import { createAction, props } from '@ngrx/store';
import { DeviceState } from './device.reducer';

export enum DeviceActionTypes {
  DeviceInfo = '[Device] Info Default',
  AddDeviceInfo = '[Device] Info Added',
  DeviceInfoFailure = '[Device] Info Failure',
}

export const DeviceInfo = createAction(DeviceActionTypes.DeviceInfo);

export const DeviceInfoSuccess = createAction(
  DeviceActionTypes.AddDeviceInfo,
  props<{ payload: DeviceState }>()
);
export const DeviceInfoFailure = createAction(
  DeviceActionTypes.DeviceInfoFailure,
  props<{ payload: DeviceState }>()
);
