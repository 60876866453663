import { createSelector } from '@ngrx/store';
import { StoreState } from '../store.state';
import { NewProfileState } from './new-profiles.reducer';

const newProfileState = (state: StoreState) => state.newProfile;

export const getNewProfileData = createSelector(
  newProfileState,
  (state: NewProfileState) => structuredClone(state.profileData)
);
