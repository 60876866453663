import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { DatabaseService } from '@services';
import { Observable, exhaustMap, map, takeUntil } from 'rxjs';
import { AuthActionTypes } from '../auth';
import { UserActionTypes } from '../user/user.actions';
import {
  ActiveOrdersSubscribePending,
  ActiveOrdersSubscribeSuccess,
} from './active-orders.actions';

@Injectable()
export class ActiveOrdersEffects {
  private _actions = inject(Actions);
  private _database = inject(DatabaseService);

  subscribeToOrders$ = createEffect((): Observable<Action> => {
    return this._actions.pipe(
      ofType(UserActionTypes.SubscribeSuccess),
      exhaustMap(({ payload: { docId } }) => {
        const subscription = this._database.subscribeToUserOrders(docId).pipe(
          map((data) =>
            data.length === 0
              ? ActiveOrdersSubscribePending({
                  payload: {
                    status: 'pending',
                    orderData: null,
                    error: 'No active orders',
                  },
                })
              : ActiveOrdersSubscribeSuccess({
                  payload: {
                    status: 'success',
                    orderData: data,
                    error: null,
                  },
                })
          )
        );

        subscription
          .pipe(takeUntil(this._actions.pipe(ofType(AuthActionTypes.Logout))))
          .subscribe();

        return subscription;
      })
    );
  });
}
