import { inject, Injectable, NgZone } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { ApiService, NavigationService, StoreService } from '@services';
import {
  CustomerProfileWithId,
  PartialNested,
} from '@sudshare/custom-node-package';
import { DELETE_PROFILE_ENDPOINT, PROFILE_ENDPOINT } from '@utils';
import { map, Observable, switchMap, withLatestFrom } from 'rxjs';
import { ProfilesStateActionTypes } from './new-profiles.actions';

@Injectable()
export class NewProfilesEffects {
  private _actions = inject(Actions);
  private _storeService = inject(StoreService);
  private _apiService = inject(ApiService);
  private _zone = inject(NgZone);
  private _navService = inject(NavigationService);

  private profileName = '';

  createNewProfile$ = createEffect(() =>
    this._actions.pipe(
      ofType(ProfilesStateActionTypes.CreateProfile),
      withLatestFrom(this._storeService.getNewProfileData()),
      switchMap(([, profileRequest]) => {
        this.profileName = profileRequest.ProfileName || '';
        this.createProfile(profileRequest);

        return new Observable<Action>();
      })
    )
  );

  updateProfile$ = createEffect(() =>
    this._actions.pipe(
      ofType(ProfilesStateActionTypes.ProfileUpdated),
      withLatestFrom(this._storeService.getNewProfileData()),
      switchMap(([, profileRequest]) => {
        (this.profileName = profileRequest.ProfileName || ''),
          this.updateProfile(profileRequest);
        return new Observable<Action>();
      })
    )
  );

  duplicateProfile$ = createEffect(() =>
    this._actions.pipe(
      ofType(ProfilesStateActionTypes.ProfileDuplicated),
      withLatestFrom(this._storeService.getNewProfileData()),
      switchMap(([, profileRequest]) => {
        this.profileName = profileRequest.ProfileName || '';
        this.duplicateProfile(profileRequest);

        return new Observable<Action>();
      })
    )
  );

  deleteProfile$ = createEffect(() =>
    this._actions.pipe(
      ofType(ProfilesStateActionTypes.ProfileDeleted),
      withLatestFrom(this._storeService.getNewProfileData()),
      switchMap(([, profileRequest]) => {
        this.deleteProfile(profileRequest);

        return new Observable<Action>();
      })
    )
  );

  private createProfile(req: PartialNested<CustomerProfileWithId>) {
    delete req.Id;
    this._apiService
      .post(PROFILE_ENDPOINT, req, { customLoadingText: 'Creating profile' })
      .pipe(map((res) => res.statusCode))
      .subscribe({
        next: (x) => {
          if (x === 204) {
            this._zone.run(() => {
              this._navService.navRoot('/profiles', {
                showNotification: true,
                newProfileName: this.profileName,
              });
            });
          }
        },
        complete: () => {
          this._storeService.resetNewProfile();
          return;
        },
      });
  }
  private duplicateProfile(req: PartialNested<CustomerProfileWithId>) {
    delete req.Id;
    this._apiService
      .post(PROFILE_ENDPOINT, req, {
        showLoading: true,
        customLoadingText: 'Duplicating profile',
      })
      .pipe(map((res) => res.statusCode))
      .subscribe({
        next: (x) => {
          if (x === 204) {
            this._navService.forwardWithOptions(`profiles/view-profile`, {
              profileName: req.ProfileName,
              duplicate: true,
            });
            this._storeService.getUserProfiles().subscribe({
              next: (profiles) => {
                const newProfile = profiles?.filter(
                  (profile) => profile.ProfileName === req.ProfileName
                )[0];
                if (newProfile && newProfile.Id) {
                  this._storeService.addProfileId({ Id: newProfile.Id });
                }
              },
            });
          }
        },
        complete: () => {
          return;
        },
      });
  }

  private updateProfile(req: PartialNested<CustomerProfileWithId>) {
    this._apiService
      .patch(PROFILE_ENDPOINT, req, true, 'Updating profile')
      .pipe(map((res) => res.statusCode))
      .subscribe({
        next: (x) => {
          if (x === 204) {
            this._zone.run(() => {
              this._navService.back();
            });
          }
        },
        complete: () => {
          this._storeService.resetNewProfile();
          return;
        },
      });
  }

  private deleteProfile(profile: PartialNested<CustomerProfileWithId>) {
    this._apiService
      .delete(
        `${DELETE_PROFILE_ENDPOINT}${profile.Id}`,
        {},
        true,
        'Deleting profile'
      )
      .pipe(map((res) => res.statusCode))
      .subscribe({
        complete: () => {
          this._storeService.resetNewProfile();
          return;
        },
      });
  }
}
