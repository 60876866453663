import {
  CUSTOM_ELEMENTS_SCHEMA,
  Component,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  ReactiveFormsModule,
  UntypedFormBuilder,
  Validators,
} from '@angular/forms';

import {
  StripeElementsOptions,
  StripePaymentElementOptions,
} from '@stripe/stripe-js';
import {
  StripeElementsDirective,
  StripePaymentElementComponent,
  injectStripe,
} from 'ngx-stripe';

import { CommonModule } from '@angular/common';
import { environment } from '@environments/environment';
import { IonCol, IonContent, IonRow } from '@ionic/angular/standalone';
import { UntilDestroy } from '@ngneat/until-destroy';
import { ButtonModule } from '@sudshare/custom-design-package';
import { CustomerAPI } from '@sudshare/custom-node-package';
import { StripeWebService } from 'app/services/stripe/stripe.webservice';

@UntilDestroy()
@Component({
  selector: 'app-web-checkout',
  templateUrl: './stripe-web-modal.component.html',
  styleUrls: ['stripe-web-modal.component.scss'],
  standalone: true,
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    StripeElementsDirective,
    StripePaymentElementComponent,
    ButtonModule,
    IonContent,
    IonRow,
    IonCol,
  ],
})
export class StripeWebModalComponent implements OnInit {
  @ViewChild(StripePaymentElementComponent)
  paymentElement!: StripePaymentElementComponent;

  constructor(
    private _fb: UntypedFormBuilder,
    private _stripeWebService: StripeWebService
  ) {}

  @Input() orderId = '';
  @Input() internalOrderId = '';
  @Input() clientSecret = '';
  @Input() paymentIntentClientSecret = '';
  @Input() customerId = '';
  @Input() amount = 0;
  @Input() preAuth = false;
  @Input() outstandingOrder = {} as CustomerAPI.Request.OutstandingOrder;
  @Input() GREY_800 = '#4B4B4B';
  buttonLabel = '';

  paymentElementForm = this._fb.group({
    amount: [this.amount, [Validators.required, Validators.pattern('\\d+')]],
  });

  elementsOptions: StripeElementsOptions = {
    locale: 'en',
    appearance: {
      theme: 'flat',
      variables: {
        colorText: this.GREY_800,
      },
    },
    clientSecret: this.paymentIntentClientSecret,
  };

  paymentElementOptions: StripePaymentElementOptions = {
    layout: {
      type: 'tabs',
      defaultCollapsed: false,
      radios: false,
      spacedAccordionItems: false,
    },
  };

  stripe = injectStripe(environment.stripe.publicKey);

  ngOnInit(): void {
    this.updatePaymentDetails();
  }

  updatePaymentDetails(): void {
    this.elementsOptions.clientSecret = this.paymentIntentClientSecret;
    this.buttonLabel = this.preAuth
      ? `Pay $${this.formatWithDecimals(this.amount / 100)}`
      : `Tip $${this.formatWithDecimals(this.amount)}`;
    const isDecimal = this.amount % 1 === 0;
    const decimalFormat = isDecimal ? this.amount : this.amount * 100;
    this.paymentElementForm.patchValue({ amount: decimalFormat });
  }

  private formatWithDecimals(value: number, decimals = 2): string {
    return Number(
      Math.round(Number(value + 'e' + decimals)) + 'e-' + decimals
    ).toFixed(decimals);
  }

  pay(): void {
    if (this.paymentElementForm.valid) {
      this._stripeWebService.processPayment(
        this.stripe,
        this.paymentElement,
        this.preAuth,
        this.outstandingOrder
      );
    }
  }
}
