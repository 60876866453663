import { ActionReducer } from '@ngrx/store';

const logColor = 'color: #FF6289';

/**
 * Logger used for debugging to log state changes in console and with store-dev tools
 * @param reducer
 * @returns
 */
export function logger(reducer: ActionReducer<any>): ActionReducer<any> {
  return (state: any, action: any): any => {
    const result = reducer(state, action);
    console.groupCollapsed(`%c${action.type}`, logColor);
    console.log('%cPrev State', logColor, state);
    console.log('%cAction', logColor, action);
    console.log('%cNext State', logColor, result);
    console.groupEnd();
    return result;
  };
}
