import { Injectable, inject } from '@angular/core';
import { AdvertisingId } from '@capacitor-community/advertising-id';
import { Capacitor } from '@capacitor/core';
import { environment } from '@environments/environment';
import { getPlatformType } from '@utils';
import {
  AFAndroidInAppPurchase,
  AFEvent,
  AFInit,
  AFIosInAppPurchase,
  AFRes,
  AppsFlyer,
} from 'appsflyer-capacitor-plugin';
import { LoggingService } from '../logging/logging.service';
const LOG_TAG = '[AppsFlyerService]';

@Injectable({
  providedIn: 'root',
})
export class AppsFlyerService {
  private _logger = inject(LoggingService);

  async init(): Promise<void> {
    let appId: string;
    switch (Capacitor.getPlatform()) {
      case 'android':
        appId = environment.appsFlyer.andAppId;
        break;
      case 'ios':
        appId = environment.appsFlyer.iosAppId;
        break;
      default:
        return;
    }

    const config = {
      devKey: environment.appsFlyer.key,
      isDebug: !environment.production,
      appID: appId,
      waitForATTUserAuthorization: 20,
      minTimeBetweenSessions: 10,
      registerOnDeepLink: true,
      registerConversionListener: true,
      registerOnAppOpenAttribution: true,
    } as AFInit;

    // next configs are meant to be done before sdk init
    AppsFlyer.setResolveDeepLinkURLs({
      urls: [environment.appsFlyer.emailLinkWrapper],
    });

    AppsFlyer.setOneLinkCustomDomain({
      domains: [environment.appsFlyer.oneLink],
    });

    await AppsFlyer.initSDK(config).catch((e) =>
      this._logger.logError(LOG_TAG, 'AppsFlyer Exception: ', e)
    );
  }

  async setCustomerId(userId: string): Promise<void> {
    try {
      await AppsFlyer.setCustomerUserId({ cuid: userId });
    } catch (error) {
      this._logger.logError(
        LOG_TAG,
        'AppsFlyer Exception setCustomerId: ',
        error
      );
    }
  }

  async getAppsFlyerData(): Promise<any> {
    try {
      const advertisingId = (await AdvertisingId.getAdvertisingId()).id || '';
      const platform = getPlatformType();
      const appsFlyerId = (await AppsFlyer.getAppsFlyerUID()).uid;
      const appsFlyerData = {
        PlatformType: platform,
        AppsFlyerId: appsFlyerId,
        AdvertisingId: advertisingId,
        CreatedAt: new Date().toISOString(),
      };

      return appsFlyerData;
    } catch (error) {
      this._logger.logError(LOG_TAG, 'Error fetching AppsFlyer data:', error);
      //silent fail in app so it doesnt crash
      return {};
    }
  }

  async logEvent(payload: AFEvent): Promise<AFRes> {
    return await AppsFlyer.logEvent(payload);
  }

  async logPurchase(
    payload: AFAndroidInAppPurchase | AFIosInAppPurchase
  ): Promise<AFRes | undefined> {
    switch (Capacitor.getPlatform()) {
      case 'android':
        return await AppsFlyer.validateAndLogInAppPurchaseAndroid({
          ...payload,
          currency: 'usd',
        } as AFAndroidInAppPurchase);
      case 'ios':
        return await AppsFlyer.validateAndLogInAppPurchaseIos({
          ...payload,
          currency: 'usd',
        } as AFIosInAppPurchase);
      default:
        return;
    }
  }
}
