import { CommonModule } from '@angular/common';
import {
  CUSTOM_ELEMENTS_SCHEMA,
  Component,
  Input,
  inject,
} from '@angular/core';
import {
  IonCol,
  IonGrid,
  IonRow,
  ModalController,
  NavController,
} from '@ionic/angular/standalone';
import { ButtonModule } from '@sudshare/custom-design-package';
import { closeModalWithOptions } from '@utils';

@Component({
  selector: 'app-referral-redemption-modal',
  templateUrl: './referral-redemption-modal.component.html',
  styleUrls: ['./referral-redemption-modal.component.scss'],
  standalone: true,
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [ButtonModule, CommonModule, IonGrid, IonRow, IonCol],
})
export class ReferralRedemptionModalComponent {
  private _modalController = inject(ModalController);
  private _navController = inject(NavController);

  @Input() content!: string;
  @Input() path!: string;
  @Input() buttonActionName!: string;
  @Input() modalAction!: string;
  @Input() headerOne?: string;
  @Input() headerTwo?: string;

  handleDismiss(): void {
    closeModalWithOptions(this._modalController, { confirm: false }, 'cancel');
  }

  handleActionClick(): void {
    if (this.modalAction === 'navigate') {
      this.handleDismiss();
      this._navController.navigateForward(this.path);
    } else {
      closeModalWithOptions(this._modalController, { confirm: true }, 'cancel');
    }
  }
}
