import { Injectable } from '@angular/core';
import { AdvertisingId } from '@capacitor-community/advertising-id';
import { Capacitor } from '@capacitor/core';

@Injectable({
  providedIn: 'root',
})
export class AdvertisingService {
  private permissionGranted = false;
  private canRequest = false;

  constructor() {
    AdvertisingId.getAdvertisingStatus()
      .then((res) => {
        if (res.status === 'Authorized') {
          this.permissionGranted = true;
        }

        if (res.status === 'Not Determined') {
          this.canRequest = true;
        }
      })
      .catch(() => {
        // web
      });
  }

  shouldShowPermissionPage = (): boolean => {
    return (
      Capacitor.getPlatform() === 'ios' &&
      !this.permissionGranted &&
      this.canRequest
    );
  };
}
