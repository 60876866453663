import { createAction, props } from '@ngrx/store';
import { PastOrdersState } from './past-orders.reducer';

export enum PastOrderActionTypes {
  GetPastOrders = '[Past Orders] Getting Past Orders',
  RateAndTip = '[Past Orders] Rate and Tip',
}

export const GetPastOrders = createAction(
  PastOrderActionTypes.GetPastOrders,
  props<{ payload: PastOrdersState }>()
);

export const RateAndTipOrder = createAction(PastOrderActionTypes.RateAndTip);
