import { createSelector } from '@ngrx/store';
import { StoreState } from '../store.state';
import { PastOrdersState } from './past-orders.reducer';

const pastOrdersState = (state: StoreState) => state.pastOrders;

export const getPastOrders = createSelector(
  pastOrdersState,
  (state: PastOrdersState) => state
);
