import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import {
  IonCol,
  IonContent,
  IonGrid,
  IonRow,
  ModalController,
} from '@ionic/angular/standalone';
import { HeaderComponent } from '@sudshare/custom-design-package';
import { closeModalWithOptions } from '@utils';

@Component({
  selector: 'app-laundry-pro-info',
  standalone: true,
  imports: [CommonModule, IonContent, IonGrid, IonRow, IonCol, HeaderComponent],
  templateUrl: './laundry-pro-info.component.html',
  styleUrls: ['./laundry-pro-info.component.scss'],
})
export class LaundryProInfoComponent {
  private _modalCtrl = inject(ModalController);

  public laundryProInfoTitle = 'Preferred Laundry Pros';

  onClose(): void {
    closeModalWithOptions(this._modalCtrl, { confirm: false }, 'close');
  }
}
