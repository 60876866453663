import { Action, createReducer, on } from '@ngrx/store';
import {
  OutstandingOrdersWithId,
  PartialNested,
} from '@sudshare/custom-node-package';
import { STORE_STATUS, StoreStatusOptions } from '@utils';
import {
  ActiveOrdersSubscribePending,
  ActiveOrdersSubscribeSuccess,
} from './active-orders.actions';

export interface ActiveOrdersState {
  orderData: PartialNested<OutstandingOrdersWithId>[] | null;
  error: string | null;
  status: STORE_STATUS;
}

const initialState: ActiveOrdersState = {
  orderData: null,
  error: null,
  status: StoreStatusOptions.PENDING,
};

const activeOrdersReducer = createReducer(
  initialState,

  on(ActiveOrdersSubscribeSuccess, (state, { payload }) => ({
    ...state,
    status: StoreStatusOptions.SUCCESS,
    orderData: payload.orderData,
    error: payload.error,
  })),

  on(ActiveOrdersSubscribePending, (state, { payload }) => ({
    ...state,
    status: StoreStatusOptions.PENDING,
    orderData: payload.orderData,
    error: payload.error,
  }))
);

export function ActiveOrdersReducer(
  state: ActiveOrdersState | undefined,
  action: Action
): ActiveOrdersState {
  return activeOrdersReducer(state, action);
}
