<!--Pickup Form-->
<form
  id="pickup-location-form"
  class="pickup-location-form basic-layout-frame small"
  [formGroup]="pickupLocationForm"
>
  <!--Title and Subtitle-->
  <ion-row class="intro-text">
    <ion-col size="12">
      <h2>Pickup Location</h2>
      <p>Where would you like your laundry picked up?</p>
    </ion-col>
  </ion-row>

  <!--Address Input / Textarea-->
  <ion-row
    class="address-input"
    *ngIf="!address"
    (click)="handleNavigationClick()"
  >
    <ion-col size="12">
      <poplin-input
        id="pickup-location"
        ngDefaultControl
        label="Select an Address"
        placeholder=""
        [isReadOnly]="true"
        [parentForm]="pickupLocationForm"
        formControlName="address"
        [actionButton]="true"
        [actionButtonIcon]="'plus_custom'"
        [error]="attemptedContinue ? 'Specify an address' : ''"
        [border]="attemptedContinue ? 'error' : 'default'"
      ></poplin-input>
    </ion-col>
  </ion-row>
  <ion-row *ngIf="address" (click)="handleNavigationClick()">
    <ion-col size="12">
      <poplin-textarea
        ngDefaultControl
        label="Address"
        [isReadOnly]="true"
        [parentForm]="pickupLocationForm"
        formControlName="address"
        [actionButton]="true"
        [actionButtonIcon]="'edit_filled'"
        [value]="address"
      ></poplin-textarea>
    </ion-col>
  </ion-row>

  <!--Pickup Location Select-->
  <ion-row class="pickup-location-select-row">
    <ion-col size="12">
      <poplin-select
        id="pickup-location-select"
        class="pickup-location-select"
        [alertHeader]="'Header'"
        [subHeader]="'Subheader'"
        [options]="['Front Door', 'Back Door', 'Front Desk', 'Custom']"
        label="Select Pickup Spot"
        [hasLabel]="true"
        (eventEmitter)="handleSelect($event)"
        [selected]="pickupLocation"
        [border]="
          attemptedContinue && pickupLocation === '' ? 'error' : 'default'
        "
        [showError]="attemptedContinue && pickupLocation === ''"
        [error]="
          attemptedContinue && pickupLocation === ''
            ? 'Select a pickup location'
            : ''
        "
      ></poplin-select>
    </ion-col>
  </ion-row>

  <!--Pickup Instructions Checkbox-->
  <ion-row class="opening-checkbox" aria-describedby="pickup-instructions">
    <ion-col size="12">
      <div class="guided-checkbox">
        <poplin-checkbox
          aria-label="instructions"
          ngDefaultControl
          id="pickup-instructions-checkbox"
          [parentForm]="pickupLocationForm"
          class="pickup-form-checkbox"
          [size]="'default'"
          [label]="'Add pickup instructions'"
          (eventEmitter)="handleChecked($event)"
          [parentForm]="pickupLocationForm"
          formControlName="instructionsCheckbox"
        ></poplin-checkbox>
        <poplin-button
          ngDefaultControl
          id="help-button"
          [showIcon]="true"
          [iconSlot]="'icon-only'"
          [size]="'small'"
          [color]="'monochrome'"
          [fill]="'clear'"
          [shape]="'round'"
          [icon]="'help_circle_custom'"
        ></poplin-button>
      </div>
      <div class="expanding-area" *ngIf="hasInstructions">
        <poplin-textarea
          ngDefaultControl
          id="pickup-instructions"
          ariaLabelledBy="pickup-instructions-checkbox"
          size="small"
          label=""
          placeholder="Additional instructions or information"
          [parentForm]="pickupLocationForm"
          [value]="instructions"
          [isDisabled]="false"
          formControlName="instructions"
          (eventEmitter)="handleTextAreaEvent($event)"
          [maxLength]="250"
        >
        </poplin-textarea>
      </div>
    </ion-col>
  </ion-row>
</form>

<!--Pickup Instructions Modal-->
<ion-modal
  #modal
  class="instructions-modal"
  trigger="help-button"
  animated="true"
  [initialBreakpoint]="0.25"
  [breakpoints]="[0, 0.25, 0.5]"
>
  <ng-template>
    <ion-grid>
      <ion-row class="modal-title-row">
        <ion-col size="10">
          <span>Pickup Instructions</span>
        </ion-col>
        <ion-col size="2" class="close-icon">
          <poplin-icon name="close" (click)="closeModal()"></poplin-icon>
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col size="12" class="modal-text">
          <span
            >Pickup instructions can include things like building access, gate
            codes, or any other information your Laundry Pro may need to pick up
            your laundry.</span
          >
        </ion-col>
      </ion-row>
    </ion-grid>
  </ng-template>
</ion-modal>
