import { Injectable, Injector, inject } from '@angular/core';
import { Network } from '@capacitor/network';
import { AlertOptions } from '@ionic/angular/standalone';
import { DialogService } from '../dialog/dialog.service';

@Injectable({
  providedIn: 'root',
})
export class NetworkService {
  private _injector = inject(Injector);

  init(): void {
    Network.addListener('networkStatusChange', (status) => {
      if (!status.connected) {
        this.showAlert({
          mode: 'ios',
          keyboardClose: true,
          backdropDismiss: false,
          id: 'network-change-alert',
          header: 'You’re offline',
          message:
            'Please check your network connection status and try again. Once you’re back online, you can pick up right where you left off.',
          buttons: [
            {
              id: 'network-permission-ok',
              text: 'Ok',
              role: 'ok',
            },
          ],
        });
      }
    });
  }

  private async showAlert(options: AlertOptions): Promise<HTMLIonAlertElement> {
    const dialogService: DialogService =
      this._injector.get<DialogService>(DialogService);
    return await dialogService.showAlert(options);
  }
}
