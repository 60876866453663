import { CommonModule } from '@angular/common';
import { Component, inject, Input } from '@angular/core';
import {
  IonCol,
  IonContent,
  IonGrid,
  IonRow,
  ModalController,
} from '@ionic/angular/standalone';
import {
  ButtonComponent,
  HeaderComponent,
} from '@sudshare/custom-design-package';
import { closeModalWithOptions } from '@utils';

@Component({
  selector: 'app-confirm-action-modal',
  standalone: true,
  imports: [
    CommonModule,
    IonContent,
    IonGrid,
    IonRow,
    IonCol,
    HeaderComponent,
    ButtonComponent,
  ],
  templateUrl: './confirm-action-modal.component.html',
  styleUrls: ['./confirm-action-modal.component.scss'],
})
export class ConfirmActionModalComponent {
  private _modalCtrl = inject(ModalController);

  @Input() title!: string;
  @Input() content!: string;
  @Input() confirmButtonLabel!: string;
  @Input() cancelButtonLabel!: string;
  @Input() showConfirm = true;
  @Input() showCancel = true;

  public onConfirm(): void {
    closeModalWithOptions(this._modalCtrl, { confirm: true }, 'confirm');
  }

  public onCancel(): void {
    closeModalWithOptions(this._modalCtrl, { confirm: false }, 'cancel');
  }
}
