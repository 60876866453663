import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { DatabaseService } from '@services';
import {
  OutstandingOrdersWithId,
  PartialNested,
} from '@sudshare/custom-node-package';
import {
  map,
  merge,
  mergeMap,
  Observable,
  of,
  switchMap,
  takeLast,
  takeUntil,
} from 'rxjs';
import { ActiveOrderActionTypes } from '../active-orders/active-orders.actions';
import { AuthActionTypes } from '../auth';
import {
  MessagesSubscribePending,
  MessagesSubscribeSuccess,
} from './messages.actions';
import { MessageData } from './messages.reducer';

@Injectable()
export class MessagesEffects {
  private _actions = inject(Actions);
  private _database = inject(DatabaseService);

  subscribeToOrderMessages$ = createEffect((): Observable<any> => {
    return this._actions.pipe(
      ofType(ActiveOrderActionTypes.ActiveOrdersSubscribeSuccess),
      switchMap(({ payload: { orderData } }) => {
        const activeOrders =
          orderData as PartialNested<OutstandingOrdersWithId>[];
        let messagesCollection: MessageData[] = [];

        const messagesRequests = activeOrders.map((order) => {
          const orderId = order?.Id ? order.Id : '';

          const subscription = this._database
            .subscribeToOrderMessages(orderId)
            .pipe(
              takeUntil(
                activeOrders.length === messagesCollection.length
                  ? of(true)
                  : this._actions.pipe(
                      ofType(AuthActionTypes.Logout),
                      mergeMap(() => of(true))
                    )
              ),
              map((data) => {
                if (data !== null) {
                  messagesCollection = [
                    ...messagesCollection,
                    { orderId, orderMessages: data },
                  ];
                  return MessagesSubscribeSuccess({
                    payload: {
                      status: 'success',
                      messageData: messagesCollection,
                      error: null,
                    },
                  });
                } else {
                  return MessagesSubscribePending({
                    payload: {
                      status: 'pending',
                      messageData: null,
                      error: null,
                    },
                  });
                }
              })
            );

          subscription.pipe(takeLast(1)).subscribe();

          return subscription;
        });

        return merge(...messagesRequests);
      })
    );
  });
}
