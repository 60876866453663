import { inject, Injectable } from '@angular/core';
import { FirebaseError } from '@angular/fire/app';
import {
  collection,
  collectionSnapshots,
  docSnapshots,
  Firestore,
} from '@angular/fire/firestore';
import { environment } from '@environments/environment';
import { LoggingService } from '@services';
import {
  CustomerDocument,
  CustomerProfileWithId,
  FirestoreCollections,
  FirestoreSubCollections,
  MessagesDocumentWithId,
  OrderDocument,
  OutstandingOrdersWithId,
  PartialNested,
} from '@sudshare/custom-node-package';
import {
  doc,
  DocumentData,
  DocumentReference,
  getDoc,
  setLogLevel,
} from 'firebase/firestore';
import { catchError, map, Observable, of, Subject, takeUntil } from 'rxjs';

const LOG_TAG = '[DatabaseService]';

@Injectable({
  providedIn: 'root',
})
export class DatabaseService {
  private _firebaseFirestore = inject(Firestore);
  private _logger = inject(LoggingService);

  private destroySubscriptions: Subject<void> = new Subject();

  private USER_COLLECTION = FirestoreCollections.users;
  private USER_PROFILES = FirestoreSubCollections.usersProfiles;
  private USER_ORDER_COLLECTION =
    FirestoreSubCollections.usersOutstandingOrders;
  private ORDER_COLLECTION = FirestoreCollections.orders;
  private ORDER_MESSAGES_COLLECTION = FirestoreSubCollections.ordersMessages;

  constructor() {
    environment.production ? setLogLevel('silent') : setLogLevel('error');
  }

  unsubscribeAll(): Promise<void[]> {
    return Promise.resolve([
      this.destroySubscriptions.next(),
      this.destroySubscriptions.complete(),
    ]);
  }

  getDocRef(path: string): DocumentReference<DocumentData> {
    return doc(this._firebaseFirestore, path);
  }

  getDoc(path: string): Promise<DocumentData> {
    return getDoc(this.getDocRef(path));
  }

  subscribeToUserDoc(userId: string): Observable<DocumentData | null> {
    const docRef = this.getDocRef(`${this.USER_COLLECTION}/${userId}`);
    return docSnapshots(docRef).pipe(
      takeUntil(this.destroySubscriptions),
      map((doc) => {
        if (doc.exists()) {
          return doc.data() as CustomerDocument;
        } else {
          return null;
        }
      }),
      catchError((e: FirebaseError) => {
        if (e.code !== 'permission-denied') {
          this._logger.logError(LOG_TAG, 'Firebase Error: ', e.code);
        }
        return of();
      })
    );
  }

  subscribeToOrderDoc(orderId: string): Observable<OrderDocument | null> {
    const docRef = this.getDocRef(`${this.ORDER_COLLECTION}/${orderId}`);
    return docSnapshots(docRef).pipe(
      takeUntil(this.destroySubscriptions),
      map((doc) => {
        if (doc.exists()) {
          return doc.data() as OrderDocument;
        } else {
          return null;
        }
      }),
      catchError((e: FirebaseError) => {
        if (e.code !== 'permission-denied') {
          this._logger.logError(LOG_TAG, 'Firebase Error: ', e.code);
        }
        return of();
      })
    );
  }

  subscribeToUserOrders(
    userId: string
  ): Observable<PartialNested<OutstandingOrdersWithId>[]> {
    const collectionRef = collection(
      this._firebaseFirestore,
      `${this.USER_COLLECTION}/${userId}/${this.USER_ORDER_COLLECTION}`
    );
    return collectionSnapshots(collectionRef).pipe(
      takeUntil(this.destroySubscriptions),
      map((docs) =>
        docs.map((data) => {
          const orderInfo =
            data.data() as PartialNested<OutstandingOrdersWithId>;
          orderInfo.Id = data.id;
          return orderInfo;
        })
      ),
      catchError((e: FirebaseError) => {
        if (e.code !== 'permission-denied') {
          this._logger.logError(LOG_TAG, 'Firebase Error: ', e.code);
        }
        return of();
      })
    );
  }

  subscribeToUserProfiles(
    userId: string
  ): Observable<PartialNested<CustomerProfileWithId>[]> {
    const collectionRef = collection(
      this._firebaseFirestore,
      `${this.USER_COLLECTION}/${userId}/${this.USER_PROFILES}`
    );
    return collectionSnapshots(collectionRef).pipe(
      takeUntil(this.destroySubscriptions),
      map((docs) =>
        docs.map((data) => {
          const profileInfo =
            data.data() as PartialNested<CustomerProfileWithId>;
          profileInfo.Id = data.id;
          return profileInfo;
        })
      ),
      catchError((e: FirebaseError) => {
        if (e.code !== 'permission-denied') {
          this._logger.logError(LOG_TAG, 'Firebase Error: ', e.code);
        }
        return of();
      })
    );
  }

  subscribeToOrderMessages(
    orderId: string
  ): Observable<Partial<MessagesDocumentWithId>[]> {
    const collectionRef = collection(
      this._firebaseFirestore,
      `${this.ORDER_COLLECTION}/${orderId}/${this.ORDER_MESSAGES_COLLECTION}`
    );
    return collectionSnapshots(collectionRef).pipe(
      takeUntil(this.destroySubscriptions),
      map((docs) =>
        docs.map((data) => {
          const messageInfo = data.data() as Partial<MessagesDocumentWithId>;
          messageInfo.Id = data.id;
          return messageInfo;
        })
      ),
      catchError((e: FirebaseError) => {
        if (e.code !== 'permission-denied') {
          this._logger.logError(LOG_TAG, 'Firebase Error: ', e.code);
        }
        return of();
      })
    );
  }
}
