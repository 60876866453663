import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { ApiService } from '@services';
import { CustomerAPI } from '@sudshare/custom-node-package';
import { ORDER_HISTORY_PATH, StoreStatusOptions } from '@utils';
import { exhaustMap, mergeMap, Observable, of } from 'rxjs';
import { UserActionTypes } from '../user';
import { GetPastOrders, PastOrderActionTypes } from './past-orders.actions';

@Injectable()
export class PastOrdersEffects {
  private _actions = inject(Actions);
  private _apiService = inject(ApiService);

  getPastOrders$ = createEffect((): Observable<Action> => {
    return this._actions.pipe(
      ofType(UserActionTypes.SubscribeSuccess, PastOrderActionTypes.RateAndTip),
      exhaustMap(() => {
        return this._apiService
          .get<CustomerAPI.Response.SimpleOrderHistory[]>(
            ORDER_HISTORY_PATH,
            false,
            '',
            {
              limit: 10,
            }
          )
          .pipe(
            mergeMap(({ data }) => {
              return of(
                GetPastOrders({
                  payload: {
                    pastOrders: data,
                    error: null,
                    status: StoreStatusOptions.SUCCESS,
                  },
                })
              );
            })
          );
      })
    );
  });
}
